import React from 'react';
import {textProcessor} from "./helper-functions";
import Select from "react-select";

const GroupCardsModal = ({
                             active, setActive, message,
                             action, value, setValue, errorMessage, cardOptions, cardsFilterOptions,
                             buttonText

                         }) => {
    return (
        <div className={active === "active"
            ?
            "modal active"
            :
            active === "progress" ? "modal" : "none"}
             onClick={() => {
                 setActive("progress")
                 setTimeout(() => {
                     // setTimerRelease(false)
                     // setTimerSeconds(seconds ?? 10)
                     setActive("no")
                 }, 300)
             }}
        >
            <div className={active === "active"
                ?
                "modal-content active"
                :
                active === "progress" ? "modal-content" : "none"}
                 onClick={(e) => e.stopPropagation()}>

                <div className={"modal-data-wrapper"}>

                    <h3 style={{"margin": "auto", "marginBottom": "20px"}}>Информация группы</h3>


                    <span className={"modal-message"}>{textProcessor(message)}</span>


                    <div className={"modal-input-container"}>
                        <input

                            className={"modal-input"}
                            placeholder={"Название"}
                            value={value.name}
                            onChange={(e) => setValue(prev => {
                                return {
                                    ...prev,
                                    name: e.target.value
                                }
                            })}
                        />

                        <select
                            value={value.type || ""}
                            onChange={(e) => {
                                setValue(prev => ({
                                    ...prev,
                                    type: e.target.value,
                                    cards: []
                                }))

                            }}
                        >
                            <option value={""} disabled>Тип группировки</option>
                            <option value={"farm"}>Ферма (разные банки)</option>
                            <option value={"bank"}>Мультипарсинг (один банк)</option>
                        </select>

                        <Select
                            isMulti={true}
                            closeMenuOnSelect={false}
                            className={"card-group-selector"}
                            placeholder={"Карты"}
                            value={
                                value.cards?.length
                                    ? value.cards.map(cardValue => ({
                                        value: cardValue,
                                        label: cardsFilterOptions.find(option => option.value === cardValue)?.label || 'Неизвестный мерчант'
                                    }))
                                    : [] // Пустой массив для пустого значения
                            }
                            options={cardOptions}
                            onChange={(selected) => {
                                console.log(selected); // Убедись, что `selected` содержит правильный объект
                                setValue(prev => ({
                                    ...prev,
                                    cards: selected.map(option => option.value), // Берем только значения, а не объекты
                                }));
                            }}
                        />



                        <h3 className={`settings-error-message ${errorMessage.color === "green" ? "green-text" : "red-text"}`}>{errorMessage.message}</h3>

                    </div>

                </div>
                <div className={"decision-btn-container"}>

                    <button

                        className={`gradient-orange-button decision-btn`}
                        onClick={() => {


                            action()

                            setActive("progress")
                            setTimeout(() => {
                                // setTimerRelease(false)
                                // setTimerSeconds(seconds ?? 10)
                                setActive("no")
                            }, 300)


                            // if (errorMessage.target !== "bank") {
                            //     setActive("progress")
                            //     setTimeout(() => {
                            //         setActive("no")
                            //         // setTimerSeconds(seconds ?? 10)
                            //         // setTimerRelease(false)
                            //     }, 300)
                            // }


                        }}>{buttonText}
                    </button>


                    <button

                        className={`gradient-orange-button decision-btn`}
                        onClick={() => {


                            setActive("progress")
                            setTimeout(() => {
                                setActive("no")
                                // setTimerSeconds(seconds ?? 10)
                                // setTimerRelease(false)
                            }, 300)


                        }}>{"Отмена"}
                    </button>
                </div>

            </div>


        </div>
    );
};

export default GroupCardsModal;