import React, {useState} from 'react';
import $api from "../http";
import "../css/main-admin.css"
import {addDevidersToString} from "./helpers/helper-functions";
import ModalDialog from "./ModalDialog";

const MainAdminTools = ({merchants, traders}) => {



    const [isProcessing, setIsProcessing] = useState(false)
    const [wrongAccountedOrders, setWrongAccountedOrders] = useState([])
    const [sumOfWrongs, setSumOfWrongs] = useState(0)

    const [stateOfResult, setStateOfResult] = useState("Waiting")


    const [currentMerchantId, setCurrentMerchantId] = useState()
    const [currentMerchantProfit, setCurrentMerchantProfit] = useState(0)

    const [trader, setTrader] = useState("")
    const [expectedBalance, setExpectedBalance] = useState(0)
    const [traderFinanceData, setTraderFinanceData] = useState("")


    const [orderIdToCancel, setOrderIdToCancel] = useState()
    const [activeCancelModal, setActiveCancelModal] = useState("no")
    const [modalMessage, setModalMessage] = useState("")
    const [cancelledInfo, setCancelledInfo] = useState("")


    const cancelOrderPhaseOne = async (orderId) => {


        try {

            const response = await $api.get(`/get_info_for_cancel?orderId=${orderId}`)

            if(response.data?.result === "OK") {

                const orderData = response.data.orderData

                setModalMessage(
                    `Отменяем заказ #${orderData.number}?\nТрейдер: ${orderData.trader}\nМерчант: ${orderData.merchant}\nНа сумму: ${orderData.amount}`
                )

                setActiveCancelModal("progress")
                setTimeout(() => {
                    setActiveCancelModal("active")
                }, 300)

            } else {

                setActiveCancelModal("progress")
                setTimeout(() => {
                    setActiveCancelModal("active")
                }, 300)

                setModalMessage(response.data.message)

            }

        } catch (e) {
            setActiveCancelModal("progress")
            setTimeout(() => {
                setActiveCancelModal("active")
            }, 300)

            setModalMessage(e.message)
        }




    }

    const cancelOrderPhaseTwo = async (orderId) => {

        try {

            const response = await $api.get(`/rollback_order?orderId=${orderId}`)


            console.log(response.data)

            if(response.data?.result === "OK") {


                setOrderIdToCancel("")

                const orderData = response.data.cancelledData

                setCancelledInfo(orderData)


            } else {

                setActiveCancelModal("progress")
                setTimeout(() => {
                    setActiveCancelModal("active")
                }, 300)

                setModalMessage(response.data.message)

            }

        } catch (e) {
            setActiveCancelModal("progress")
            setTimeout(() => {
                setActiveCancelModal("active")
            }, 300)

            setModalMessage(e.message)
        }

    }



    const checkTraderBalance = async (traderId) => {

        if(!traderId) return

        const response = await $api.get(`/check_trader_balance?traderId=${traderId}`)
        console.log(response.data)

        const traderData = response.data

        setTraderFinanceData(traderData)

        const expectations = traderData.topUpsTotalSum - (traderData.insurance + traderData.ordersTotalAmountSum)
        setExpectedBalance(expectations)

    }



    const fixWrongOrders = async (wrongAccountedOrders) => {

        setIsProcessing(true)
        setStateOfResult("Processing")

        const arrayOfId = wrongAccountedOrders.map(order => order._id.toString())

        try {

            const response = await $api.post('/fix_wrong_orders', {arrayOfId})

            if(response.data === "OK") {
                setStateOfResult("OK")
                setWrongAccountedOrders([])
                setSumOfWrongs(0)
                setTimeout(() => {
                    setStateOfResult("Waiting...")
                }, 3000)


            }

        } catch (e) {
            setStateOfResult("ERROR")
        }


        setIsProcessing(false)

    }


    const getWrongAccountedOrders = async () =>  {

        setIsProcessing(true)
        const response = await $api.get('/get_wrong_accounted_orders')
        console.log(response.data)

        const arrayOfWrongs = response.data


        if(!arrayOfWrongs.length) {
            setStateOfResult("Нет недостач")

            setTimeout(() => {
                setStateOfResult("Waiting...")
            }, 3000)

            return
        }


        setWrongAccountedOrders(arrayOfWrongs)
        const sumCalculation = arrayOfWrongs.reduce((acc, order) => {

            acc = order.amount + acc
            return acc

        }, 0)


        setSumOfWrongs(sumCalculation)
        setIsProcessing(false)
        setStateOfResult("Есть недостачи")


    }



    return (
        <div className={"main-admin-wrapper"}>

            <div></div>

            <div className={"wrong-accounted-orders-wrapper"}>

                <h5 className={"main-admin-settings-label"}>Недорассчитанные заявки</h5>
                <h4 className={"main-admin-data-h"}>{stateOfResult}</h4>



                <button
                    onClick={() => getWrongAccountedOrders()}
                    className={"gradient-orange-button"}
                >
                    Проверить
                </button>

                <h4 className={"main-admin-data-h"}>{wrongAccountedOrders.length ? `${wrongAccountedOrders.length} недостач` : null}</h4>
                <h4 className={"main-admin-data-h"}>{wrongAccountedOrders.length ? `На сумму ${sumOfWrongs}` : null}</h4>
                {wrongAccountedOrders.length
                    ?
                    <button
                        onClick={() => fixWrongOrders(wrongAccountedOrders)}
                        className={"gradient-green-button"}
                    >
                        Пофиксить
                    </button>
                    :
                    null
                }

            </div>

            <div className={"wrong-accounted-orders-wrapper"}>
                <h5 className={"main-admin-settings-label"}>Сверка баланса</h5>
                <h4 className={"main-admin-data-h"}>{stateOfResult}</h4>

                <div className={"admin-div-flex-row-container"}>
                    <div className={"admin-div-flex-column-container"}>
                        <select
                            value={trader}
                            onChange={(e) => {

                                setTrader(e.target.value)

                            }}
                        >
                            <option value={""}>Выбрать...</option>
                            {Object.values(traders)
                                .sort((a, b) => a.login.localeCompare(b.login))
                                .map(trader => (
                                    <option key={trader.id} value={trader.id}>
                                        {trader.login}
                                    </option>
                                ))}

                        </select>

                        <button
                            onClick={() => checkTraderBalance(trader)}
                            className={"gradient-orange-button"}
                        >
                            Проверить
                        </button>
                    </div>

                    <div className={"admin-div-flex-column-container"}>
                        {traderFinanceData
                            ?
                            <>
                                <h5 className={"h-reset"}>{`Пополнения: ${addDevidersToString(traderFinanceData.topUpsTotalSum?.toFixed(2))} р.`}</h5>
                                <h5 className={"h-reset"}>{`Количество ордеров: ${traderFinanceData.ordersCount}`}</h5>
                                <h5 className={"h-reset"}>{`Сумма ордеров: ${addDevidersToString(traderFinanceData.ordersTotalAmountSum?.toFixed(2))} р.`}</h5>
                                <h5 className={"h-reset"}>{`Страховка: ${addDevidersToString(traderFinanceData.insurance?.toFixed(2))} р.`}</h5>
                                <h5 className={"h-reset"}>{`Фактический баланс: ${addDevidersToString(traderFinanceData.balance?.toFixed(2))} р.`}</h5>
                                <hr/>
                                <h5 className={"h-reset"}>{`Ожидаемый баланс: ${addDevidersToString(expectedBalance?.toFixed(2))} р.`}</h5>
                                <h5 className={"h-reset"}>{`Разбег: ${addDevidersToString((traderFinanceData.balance - expectedBalance)?.toFixed(2))} р.`}</h5>
                            </>
                            :
                            null
                        }

                    </div>

                </div>


            </div>

            <div className={"wrong-accounted-orders-wrapper"}>
                <h5 className={"main-admin-settings-label"}>Отмена заявки</h5>


                <div className={"admin-div-flex-row-container"}>
                    <div className={"admin-div-flex-column-container"}>
                        <input
                            type={"text"}
                            value={orderIdToCancel}
                            onChange={(e) => setOrderIdToCancel(e.target.value)}
                        />
                        <button
                            onClick={() => cancelOrderPhaseOne(orderIdToCancel)}
                            className={"gradient-orange-button"}
                        >
                            Отменить
                        </button>
                    </div>

                    <div className={"admin-div-flex-column-container"}>
                        {cancelledInfo
                            ?
                            <>
                                <h5 className={"h-reset"}>Заказ #{cancelledInfo.number} отменён</h5>
                                <h5 className={"h-reset"}>Мерчант: -{cancelledInfo.merchantProfit?.toFixed(2)}$</h5>
                                <h5 className={"h-reset"}>Трейдер профит: -{cancelledInfo.traderProfit?.toFixed(2)}$</h5>
                                <h5 className={"h-reset"}>Трейдер баланс: +{cancelledInfo.amount?.toFixed(2)}р.</h5>
                                <h5 className={"h-reset"}>Тимлид трейдера: -{cancelledInfo.teamleadTraderProfit?.toFixed(2)}$</h5>
                                <h5 className={"h-reset"}>Тимлид мерчанта: -{cancelledInfo.teamleadMechantProfit?.toFixed(2)}$</h5>
                                <h5 className={"h-reset"}>Платформа: -{cancelledInfo.platformProfit?.toFixed(2)}$</h5>
                            </>
                            :
                            null
                        }

                    </div>

                </div>


                <ModalDialog
                    active={activeCancelModal}
                    setActive={setActiveCancelModal}
                    purpose={"choice"}
                    message={modalMessage}
                    action={() => cancelOrderPhaseTwo(orderIdToCancel)}
                />

            </div>


        </div>
    );
};

export default MainAdminTools;