import React from 'react';
import "../css/modal-dialog.css"
import {processMessageText, textProcessor} from "./helpers/helper-functions";
import {QRCodeSVG} from 'qrcode.react';
import Select from "react-select";
import {useState} from "react";


const ModalDialog = ({
                         active, setActive, message,
                         action, action2, action3, purpose, dataToShow, buttonText1, buttonText2, qrcode,
                         inputPlaceHolder, inputValue, setInputValue, inputType, setWinner, setModalMessage,
                         appealToClose, inputPlaceHolder2, inputValue2, setInputValue2, input2, inputType2,
                         merchantOptions, workState, setWorkState
}) => {

    // Храним выбранные файлы в массиве
    const [attachedFiles, setAttachedFiles] = useState([]);

    const handleFilesChange = (e) => {
        // Преобразуем FileList в массив
        const newFiles = Array.from(e.target.files);
        // Добавляем новые файлы к уже выбранным, ограничивая общее число до 10
        setInputValue2((prevFiles) => {
            const combined = [...prevFiles, ...newFiles];
            return combined.slice(0, 10);
        });
        // Чтобы можно было выбрать один и тот же файл снова, сбрасываем значение input
        e.target.value = null;
    };

    const handleRemoveFile = (index) => {
        setInputValue2((prevFiles) =>
            prevFiles.filter((_, i) => i !== index)
        );
    };

    return (
        <div className={active === "active"
            ?
            "modal active"
            :
            active === "progress" ? "modal" : "none"}
             onClick={() => {
                 setActive("progress")
                 setTimeout(() => {
                     // setTimerRelease(false)
                     // setTimerSeconds(seconds ?? 10)
                     setActive("no")
                 }, 300)
             }}
        >
            <div className={active === "active"
                ?
                "modal-content active"
                :
                active === "progress" ? "modal-content" : "none"}
                 onClick={(e) => e.stopPropagation()}>

                <div className={"modal-data-wrapper"}>
                    {qrcode
                        ?
                        <div className={"qr-token-wrapper"}>
                            <QRCodeSVG className={"qr-token-elemetn"} value={qrcode}/>
                        </div>
                        :
                        null
                    }
                    <span className={"modal-message"}>{textProcessor(message)}</span>
                    {dataToShow
                        ?
                        dataToShow
                        :
                        null
                    }
                    {purpose === "input" || purpose ==="appeal"
                        ?
                        <div className={"modal-input-container"}>
                            <input
                                type={inputType}
                                className={"modal-input"}
                                placeholder={inputPlaceHolder}
                                value={inputValue}
                                onChange={(e) => setInputValue(e.target.value)}
                            />

                            {input2
                                ?
                                <input
                                    type={inputType2}
                                    className={"modal-input"}
                                    placeholder={inputPlaceHolder2}
                                    value={inputValue2}
                                    onChange={(e) => setInputValue2(e.target.value)}
                                />
                                :
                                null
                            }
                        </div>
                        :
                        null
                    }

                    {purpose === "appeal"
                        ?
                        <div className={"appeal-input-n-btn-container"}>
                            <input
                                type="file"
                                multiple
                                name="files"
                                id="receipt-input"
                                style={{display: "none"}}
                                onChange={handleFilesChange}
                            />
                            {/*{inputValue2*/}
                            {/*    ?*/}
                            {/*    <div>*/}
                            {/*        <div style={{display: "flex", flexDirection: "column"}}>*/}
                            {/*            <h4 style={{margin: 0}}>Файл чека:</h4>*/}
                            {/*            <h4 style={{margin: 0}}>{inputValue2.name}</h4>*/}
                            {/*            <button*/}
                            {/*                className={"gradient-red-button add-check-btn"}*/}
                            {/*                onClick={() => setInputValue2()}*/}
                            {/*            >*/}
                            {/*                Удолить*/}
                            {/*            </button>*/}
                            {/*        </div>*/}

                            {/*    </div>*/}
                            {/*    :*/}
                            {/*    <button*/}
                            {/*        className={"gradient-green-button add-check-btn"}*/}
                            {/*        onClick={(e) => {*/}
                            {/*            e.preventDefault()*/}
                            {/*            document.getElementById("appeal-files-input").click()*/}
                            {/*        }}>Прикрепить чек*/}
                            {/*        /!*{attachedFiles.length ? <span>Добавить <img src={SvgPinRed} width={"20px"}/></span> :*!/*/}
                            {/*        /!*    <span>Прикрепить <img src={SvgPinRed} width={"20px"}/></span>}*!/*/}
                            {/*    </button>*/}
                            {/*}*/}
                            {/* Вывод списка выбранных файлов */}
                            {inputValue2.length > 0 && (
                                <div>
                                    {inputValue2.map((file, index) => (
                                        <div key={index} style={{ marginBottom: "10px" }}>
                                          <span>
                                            Файл {inputValue2.length > 1 ? index + 1 : ""}: {file.name}
                                          </span>
                                            <button
                                                className={"gradient-red-button add-check-btn"}
                                                onClick={() => handleRemoveFile(index)}
                                                style={{ marginLeft: "10px" }}
                                            >
                                                Удалить
                                            </button>
                                        </div>
                                    ))}
                                </div>
                            )}

                            {/* Кнопка для выбора файлов */}
                            {inputValue2.length < 10 && (
                                <button
                                    className={"gradient-green-button add-check-btn"}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        document.getElementById("receipt-input").click();
                                    }}
                                >
                                    Прикрепить чек
                                </button>
                            )}

                        </div>
                        :
                        null
                    }

                    {purpose === "merchant_batch"
                        ?
                        <Select
                            options={merchantOptions}
                            isMulti={true}
                            closeMenuOnSelect={false}
                            placeholder="Мерчанты"
                            value={merchantOptions.filter(option => workState.includes(option.value))}
                            onChange={(selected) => {
                                setWorkState(selected.map(option => option.value)); // Теперь сохраняем только выбранные
                            }}
                        />

                        :
                        null
                    }


                </div>
                <div className={"decision-btn-container"}>
                    {purpose === "choice" || purpose === "input" || purpose === "appeal" || purpose === "winner" || purpose === "pay_choice"
                        ?
                        <button
                            disabled={
                                (purpose === "input" || purpose === "appeal") && !inputValue
                            }
                            className={`${purpose === "winner" ? "gradient-orange-button" : "gradient-red-button"} decision-btn`}
                            onClick={() => {



                                if(purpose === "winner") {
                                    // if(appealToClose.actualAmount) {
                                        setModalMessage(`Заявка будет закрыта и рассчитана с суммой ${appealToClose.amount} р. в пользу КЛИЕНТА, вы уверены?`)
                                        action2()
                                        setWinner("merchant")
                                    // } else {
                                    //     action("merchant")
                                    // }


                                } else {
                                    action()
                                }
                                setActive("progress")
                                setTimeout(() => {
                                    setActive("no")
                                    // setTimerSeconds(seconds ?? 10)
                                    // setTimerRelease(false)
                                }, 300)
                            }}>{buttonText1 ? buttonText1 : "Да"}
                        </button>
                        :
                        null
                    }
                    {purpose === "no_pay_choice"
                        ?
                        <div className={"modal-no-pay-choice-btn-container"}>

                            <button
                                className={"gradient-red-button"}
                                onClick={() => {

                                    action()
                                    setActive("progress")
                                    setTimeout(() => {
                                        setActive("no")
                                        // setTimerSeconds(seconds ?? 10)
                                        // setTimerRelease(false)
                                    }, 300)
                                }}
                            >Платёж не найден</button>
                            <button
                                className={"gradient-orange-button"}
                                onClick={() => action2()}
                            >Сумма в заявке отличается от суммы в чеке</button>
                            <button
                                className={"gradient-dark-button"}
                                onClick={() => {
                                    setActive("progress")
                                    setTimeout(() => {
                                        setActive("no")
                                        // setTimerSeconds(seconds ?? 10)
                                        // setTimerRelease(false)
                                    }, 300)
                                    action3()
                                }}
                            >Скам (фальсификация чека)</button>

                        </div>
                        :
                        null
                    }

                    {purpose === "merchant_batch"
                        ?
                        <div className={"modal-no-pay-choice-btn-container"}>

                            <button
                                className={"gradient-red-button"}
                                onClick={() => {

                                    action(true, workState)
                                    setActive("progress")
                                    setTimeout(() => {
                                        setActive("no")
                                        // setTimerSeconds(seconds ?? 10)
                                        // setTimerRelease(false)
                                    }, 300)
                                }}
                            >Добавить</button>
                            <button
                                className={"gradient-orange-button"}
                                onClick={() => {
                                    action(false, workState)
                                    setActive("progress")
                                    setTimeout(() => {
                                        setActive("no")
                                        // setTimerSeconds(seconds ?? 10)
                                        // setTimerRelease(false)
                                    }, 300)
                                }}
                            >Удалить</button>
                        </div>

                        :
                        null
                    }

                    <button

                        className={`${purpose === "winner" ? "gradient-orange-button" : "gradient-dark-button"} decision-btn`}
                        onClick={() => {

                            if(purpose === "winner") {
                                // if(appealToClose.actualAmount) {
                                    setModalMessage(`Заявка будет закрыта ${
                                        appealToClose.actualAmount
                                            ?
                                            `и рассчитана с суммой ${appealToClose.actualAmount} р. в пользу ТРЕЙДЕРА,`
                                            :
                                            "как неудачная (БЕЗ ПЛАТЕЖА),"
                                    } вы уверены?`)
                                    action2()
                                    setWinner("trader")
                                // } else {
                                //     action("trader")
                                // }


                            }

                            setActive("progress")

                            if(purpose !== "pay_choice") {
                                setTimeout(() => {
                                    setActive("no")
                                    // setTimerSeconds(seconds ?? 10)
                                    // setTimerRelease(false)
                                }, 300)
                            } else {
                                action2()
                            }



                        }}>{buttonText2 ?? "Отмена"}
                    </button>
                </div>

            </div>


        </div>
    );
};

export default ModalDialog;