import React, {useEffect, useState} from 'react';
import {generatePassword} from "./helper-functions";
import Select from "react-select";
import {useNavigate} from "react-router-dom";
import $api from "../../http";
import "../../css/user-creator.css"
import {toJS} from "mobx";

const TeamleadCreationForm = ({store, createError, setCreateError}) => {

    const navigate = useNavigate()


    const [showOk, setShowOk] = useState(false)

    const [teamleadData, setTeamleadData] = useState({})
    const [traders, setTraders] = useState([])
    const [merchants, setMerchants] = useState([])
    const [traderOptions, setTraderOptions] = useState([])
    const [merchantOptions, setMerchantOptions] = useState([])

    const getTraders = async () => {
        // setLoading(true)

        console.log(toJS(store.user.id))

        const response = await $api.get(`/get_users`)
        console.log(response.data)

        setTraders(response.data)
        // setLoading(false)
    }

    const getMerchants = async () => {
        // setLoading(true)

        console.log(toJS(store.user.id))

        const response = await $api.get(`/get_merchants`)
        console.log(response.data)

        setMerchants(response.data)
        // setLoading(false)
    }


    const getTeamleadToEdit = async (teamleadId) => {
        const response = await $api.get(`/get_teamlead_by_id?teamleadId=${teamleadId}`)
        console.log(response.data)
        setTeamleadData(prev => {
            setCreateError({show: false})
            return {
                ...prev,
                id: response.data.id,
                login: response.data.login,
                stake: response.data.stake,
                traders: response.data.traders,
                merchants: response.data.merchants,
                // tgUserName: response.data.tgUserName
            }
        })
    }


    useEffect(() => {

        getTraders()
        getMerchants()

        if(store.teamleadToEditId) {
            getTeamleadToEdit(store.teamleadToEditId)
        }


        return () => {
            store.setTeamleadToEditId("")
        }


    }, []);

    useEffect(() => {

        const optionsToSet = traders.map(trader => ({value: trader.id, label: trader.login}))
        setTraderOptions(optionsToSet)


    }, [traders])

    // useEffect(() => {
    //
    //     const optionsToSet = merchants.map(merchant => ({value: merchant._id, label: merchant.login}))
    //     setMerchantOptions(optionsToSet)
    //
    //
    // }, [merchants])

    const createTeamlead = async (teamleadData) => {

        console.log(teamleadData)


        // Список обязательных полей
        const requiredFields = [
            'login',
            'stake',


        ];

        if(!store.teamleadToEditId) {
            requiredFields.push("password")
        }


        // Проверка на заполнение всех полей
        const allFieldsFilled = requiredFields.every(field => {
            console.log(field, teamleadData[field] !== undefined, teamleadData[field] !== null, teamleadData[field] !== '')
            return teamleadData[field] !== undefined && teamleadData[field] !== null && teamleadData[field] !== '';
        });

        if (allFieldsFilled) {
            // Если все поля заполнены

            console.log(teamleadData)
            console.log("Всё норм создаем юзера")
            try {
                const response = await $api.post('/create_teamlead', {teamleadData: JSON.stringify(teamleadData)})
                console.log(response.data)
                if(response.data === "OK") {
                    setShowOk(true)
                    setTeamleadData({
                        'login': "",
                        'password': "",
                        'stake': "",
                        'merchants': []
                    })
                    setTimeout(() => {
                        setShowOk(false)
                        store.setTeamleadToEditId("")
                        navigate('/teamlead_viewer')
                    }, store.teamleadToEditId ? 2000 : 5000)
                } else {
                    setCreateError({show: true, message: response.data.message})
                }

            } catch (e) {
                console.log(e)
                setCreateError({show: true, message: "Ошибка создания юзера"})
            }



        } else {
            // Если какие-то поля не заполнены, выполняем другое действие
            setCreateError({show: true, message: "Заполнены не все поля"})
        }
    };

    function validateInputStake(input) {

        // Регулярное выражение для чисел от 0.1 до 10, включая дробные
        const validPattern = /^(1(\.0{1,2})?|0\.(0[5-9]|[1-9]\d?))$/;
        const valid = validPattern.test(input);


        if (valid) {
            setTeamleadData(prev => {
                setCreateError({ show: false });
                return {
                    ...prev,
                    stake: input !== "" ? parseFloat(input.toString().replace(",", ".")) : "" // Поле, которое обновляем
                };
            });
        } else {
            setCreateError({ show: true, message: "Введите число от 0.1 до 1" });
        }
    }


    function validateInputLogin(input) {

        const validPattern = /^[A-Za-z0-9]*$/;
        const valid = validPattern.test(input)

        if(valid) {
            setTeamleadData(prev => {
                setCreateError({show: false})
                return {
                    ...prev,
                    login: input
                }
            })
        }
    }

    // function validateTgUserName(input) {
    //
    //     const validPattern = /^[A-Za-z0-9@_]*$/;
    //     const valid = validPattern.test(input)
    //
    //     if(valid) {
    //         setTeamleadData(prev => {
    //             setCreateError({show: false})
    //             return {
    //                 ...prev,
    //                 tgUserName: input
    //             }
    //         })
    //     }
    // }


    // Состояние для новой (еще не сохранённой) записи
    const [newMerchant, setNewMerchant] = useState({ merchantId: null, stake: "" });
    // Флаг, показывающий, что уже нажали «Добавить мерчанта» (для случая, когда список пуст)
    const [isAddingMerchant, setIsAddingMerchant] = useState(false);

    // Проверка, заполнена ли строка (merchantId выбран и ставка введена)
    const isRowComplete = (entry) => {
        return (
            entry &&
            entry.merchantId &&
            entry.stake !== "" &&
            entry.stake !== null &&
            entry.stake !== undefined
        );
    };

    /* ========= Обработчики для заполненных записей (находящихся в teamleadData.merchants) ========= */

    const handleMerchantChange = (index, selectedOption) => {
        setTeamleadData((prev) => {
            const newMerchants = prev.merchants ? [...prev.merchants] : [];
            newMerchants[index] = {
                ...newMerchants[index],
                merchantId: selectedOption.value,
            };
            return { ...prev, merchants: newMerchants };
        });
    };

    const handleStakeChange = (index, event) => {

        setCreateError({ show: false});

        const input = event.target.value;

        const validPattern = /^(?:(?:0\.(?:[1-9]\d?))|(?:[1-9](?:\.\d{1,2})?)|(?:10(?:\.0{1,2})?))?$/;


        const valid = validPattern.test(input)

        if(valid) {
            setTeamleadData((prev) => {
                const newMerchants = prev.merchants ? [...prev.merchants] : [];
                newMerchants[index] = {
                    ...newMerchants[index],
                    stake: input !== "" ? parseFloat(input.toString().replace(",", ".")) : "",
                };
                return { ...prev, merchants: newMerchants };
            });
        } else {
            setCreateError({ show: true, message: "Введите число от 0.1 до 10" });
        }


    };

    /* ========= Обработчики для новой (еще не сохранённой) строки ========= */

    const handleNewMerchantChange = (selectedOption) => {
        setNewMerchant((prev) => ({ ...prev, merchantId: selectedOption.value }));
    };

    const handleNewStakeChange = (event) => {

        setCreateError({ show: false});

        const input = event.target.value;

        const validPattern = /^(?:(?:0\.(?:[1-9]\d?))|(?:[1-9](?:\.\d{1,2})?)|(?:10(?:\.0{1,2})?))?$/;


        const valid = validPattern.test(input)


        if (valid) {
            setNewMerchant((prev) => ({
                ...prev,
                stake: input !== "" ? parseFloat(input.toString().replace(",", ".")) : "",
            }));
        } else {
            setCreateError({ show: true, message: "Введите число от 0.1 до 10" });
        }


    };

    // Когда пользователь нажимает «+» в строке нового мерчанта:
    // Если строка заполнена, добавляем её в teamleadData.merchants и сбрасываем newMerchant
    const handleAddNewMerchant = () => {
        if (!isRowComplete(newMerchant)) return;
        setTeamleadData((prev) => {
            const newMerchants = prev.merchants ? [...prev.merchants] : [];
            // Добавляем новую запись в начало массива (аналог unshift)
            return { ...prev, merchants: [newMerchant, ...newMerchants] };
        });
        // Сбрасываем строку нового мерчанта (она остаётся на месте для дальнейшего ввода)
        setNewMerchant({ merchantId: null, stake: "" });
    };

    const removeMerchantEntry = (index) => {
        setTeamleadData((prev) => {
            const newMerchants = prev.merchants ? [...prev.merchants] : [];
            newMerchants.splice(index, 1);
            return { ...prev, merchants: newMerchants };
        });
    };

    // Очистка всех записей – сбрасываем массив, а также состояние новой записи
    const clearAllMerchants = () => {
        setTeamleadData((prev) => ({ ...prev, merchants: [] }));
        setIsAddingMerchant(false);
        setNewMerchant({ merchantId: null, stake: "" });
    };

    /* ========= Функции для вычисления доступных опций (исключая уже выбранных мерчантов) ========= */

    // Для новой строки: исключаем мерчанты, выбранные в заполненных записях
    const getAvailableOptionsForNewMerchant = () => {
        const selectedMerchantIds = (teamleadData.merchants || []).reduce((acc, entry) => {
            if (entry.merchantId) {
                acc.push(entry.merchantId);
            }
            return acc;
        }, []);
        return merchantOptions.filter((option) => !selectedMerchantIds.includes(option.value));
    };

    // Для заполненных строк: исключаем мерчанты, выбранные в остальных заполненных записях
    const getAvailableOptions = (currentIndex) => {
        const selectedMerchantIds = (teamleadData.merchants || []).reduce((acc, entry, idx) => {
            if (idx !== currentIndex && entry.merchantId) {
                acc.push(entry.merchantId);
            }
            return acc;
        }, []);
        return merchantOptions.filter((option) => !selectedMerchantIds.includes(option.value));
    };


    useEffect(() => {
        const optionsToSet = merchants.map(merchant => ({
            value: merchant._id,
            label: merchant.login
        }));
        setMerchantOptions(optionsToSet);
    }, [merchants]);

    return (
        <div className={"support-creator-wrapper"}>
            <h1 className={"component-title"}>{store.teamleadToEditId ? "Редактируем" : "Создаём"} тимлида</h1>

            <input
                className={"user-creator-input"}
                type={"text"}
                pattern="[A-Za-z0-9]+"
                placeholder={"Имя (login)"}
                value={teamleadData.login}
                onChange={(e) => {
                    validateInputLogin(e.target.value)

                }}
            />

            <div className={"user-creator-password-input-n-generator-container"}>
                <input
                    readOnly={true}
                    className={"user-creator-input password-input"}
                    type={"text"}
                    placeholder={"password"}
                    value={teamleadData.password}

                />
                <button
                    className={"gradient-dark-button pass-gen-btn"}
                    onClick={() => {
                        const freshPass = generatePassword()
                        setTeamleadData(prev => {
                            setCreateError({show: false})
                            return {
                                ...prev,
                                password: freshPass
                            }
                        })
                    }}
                >{store.supportToEditId ? "New Password" : "Generate"}
                </button>
            </div>

            <label
                className={"password-warning red-for-blue"}
            >{teamleadData.password
                ?
                `Скопируйте пароль, после ${store.teamleadToEditId ? 'сохранения' : 'создания'} тимлида его больше нельзя будет увидеть`
                :
                null
            }
            </label>

            <input
                className={"user-creator-input"}
                type={"number"}
                placeholder={"Ставка по трейдерам (%)"}
                value={teamleadData.stake}
                onWheel={(e) => {
                    e.target.blur(); // убираем фокус, чтобы scroll wheel не меняло значение
                }}
                onChange={(e) => {
                    validateInputStake(e.target.value)
                }}

            />

            <Select
                placeholder={"Трейдеры"}
                className={"card-creator-selector"}

                value={teamleadData.traders?.map(traderId => ({
                    value: traderId,
                    label: traderOptions.find(option => option.value === traderId)?.label,
                })) || []}
                options={
                    traderOptions
                }
                isMulti={true}
                onChange={(selected) => {

                    const selectedValues = selected.map(option => option.value);

                    setTeamleadData(prev => {
                        setCreateError({show: false});
                        return {
                            ...prev,
                            traders: selectedValues, // Сохраняем массив значений
                        };
                    });
                }}
            />


            <div className="teamlead-merchants-input-container">
                {((teamleadData.merchants && teamleadData.merchants.length > 0) || isAddingMerchant) ? (
                    <>
                        {/* Строка нового мерчанта (отображается всегда, когда уже начали добавление или имеются заполненные записи) */}
                        <div className="merchant-entry-wrapper" style={{display: "flex", marginBottom: "8px"}}>
                            <Select
                                placeholder="Мерчант"
                                className="teamlead-creator-inner-selector"
                                value={
                                    newMerchant && newMerchant.merchantId
                                        ? merchantOptions.find((option) => option.value === newMerchant.merchantId)
                                        : null
                                }
                                options={getAvailableOptionsForNewMerchant()}
                                onChange={handleNewMerchantChange}
                            />
                            <input
                                type="number"
                                step="0.1"
                                placeholder="Ставка"
                                value={newMerchant && newMerchant.stake !== undefined ? newMerchant.stake : ""}
                                onChange={handleNewStakeChange}
                                style={{marginLeft: "8px", width: "100px"}}
                            />
                            <button
                                onClick={handleAddNewMerchant}
                                className={"gradient-orange-button add-teamlead-merchant-btn"}
                                disabled={!isRowComplete(newMerchant)}
                            >
                                Соединить
                            </button>
                        </div>
                        {/* Список уже добавленных мерчантов */}
                        {teamleadData.merchants &&
                            teamleadData.merchants.map((entry, index) => {

                                console.log(entry)

                                return (<div
                                    key={index}
                                    className="merchant-entry-wrapper"
                                    style={{display: "flex", marginBottom: "8px"}}
                                >
                                    <Select
                                        placeholder="Мерчант"
                                        className="teamlead-creator-inner-selector"
                                        value={
                                            entry && entry.merchantId
                                                ? merchantOptions.find((option) => option.value === entry.merchantId)
                                                : null
                                        }
                                        options={getAvailableOptions(index)}
                                        onChange={(selectedOption) => handleMerchantChange(index, selectedOption)}
                                    />
                                    <input
                                        type="number"
                                        step="0.1"
                                        placeholder="Ставка"
                                        value={entry && entry.stake !== undefined ? entry.stake : ""}
                                        onChange={(event) => handleStakeChange(index, event)}
                                        style={{marginLeft: "8px", width: "100px"}}
                                    />
                                    <button
                                        className={"gradient-red-button add-teamlead-merchant-btn"}
                                        onClick={() => removeMerchantEntry(index)}
                                    >
                                        Удалить
                                    </button>
                                </div>)
                            })}
                    </>
                ) : (
                    // Если записей ещё нет и пользователь не начал добавление – показываем кнопку «Добавить мерчанта»
                    <div>
                        <button
                            className={"gradient-green-button"}
                            onClick={() => setIsAddingMerchant(true)}>Добавить мерчанта</button>
                    </div>
                )}
                {teamleadData.merchants && teamleadData.merchants.length > 0 && (
                    <button
                        className={"gradient-red-button"}
                        onClick={clearAllMerchants}
                    >
                        Очистить мерчантов
                    </button>
                )}
            </div>


            {/*<Select*/}
            {/*    placeholder={"Мерчанты"}*/}
            {/*    className={"card-creator-selector"}*/}

            {/*    value={teamleadData.merchants?.map(merchantId => ({*/}
            {/*        value: merchantId,*/}
            {/*        label: merchantOptions.find(option => option.value === merchantId)?.label,*/}
            {/*    })) || []}*/}
            {/*    options={*/}
            {/*        merchantOptions*/}
            {/*    }*/}
            {/*    isMulti={true}*/}
            {/*    onChange={(selected) => {*/}


            {/*        console.log(selected)*/}

            {/*        const selectedValues = selected.map(option => option.value);*/}


            {/*        setTeamleadData(prev => {*/}
            {/*            setCreateError({ show: false });*/}
            {/*            return {*/}
            {/*                ...prev,*/}
            {/*                merchants: selectedValues, // Сохраняем массив значений*/}
            {/*            };*/}
            {/*        });*/}
            {/*    }}*/}
            {/*/>*/}


            {/*<input*/}
            {/*    className={"user-creator-input"}*/}
            {/*    type={"text"}*/}
            {/*    pattern="[A-Za-z0-9@_]+"*/}
            {/*    placeholder={"Ник в телеграме"}*/}
            {/*    value={teamleadData.tgUserName}*/}
            {/*    onChange={(e) => {*/}
            {/*        validateTgUserName(e.target.value)*/}

            {/*    }}*/}
            {/*/>*/}


            <div className={"create-user-btn-container"}>
                {createError.show
                    ?
                    <h3 className={"create-user-error-message red-for-blue"}>{createError.message}</h3>
                    :
                    null
                }
                {showOk && !createError.show
                    ?
                    <h3 className={"create-user-success-message"}>Пользователь {store.teamleadToEditId ? "сохранён" : "добавлен"} успешно!</h3>
                    :
                    null
                }
                <button
                    className={"gradient-dark-button create-user-btn"}
                    onClick={() => createTeamlead(teamleadData)}
                >{store.teamleadToEditId ? "Сохранить" : "Создать"}
                </button>
            </div>


        </div>
    );
};

export default TeamleadCreationForm;