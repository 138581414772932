import React from 'react';
import {addDevidersToString} from "./helper-functions";

const OrderDailyValueTableAdmin = ({valueData}) => {
    return (


        <table className="transaction-table">
            <thead>
            <tr>
                <th>Дата</th>
                <th>Количество</th>
                <th>Оборот [₽]</th>
                <th>Профит [USDT]</th>


            </tr>
            </thead>
            <tbody>
            {valueData.map((item, index) => {
                return (
                    <tr className={
                        index % 2 === 0
                            ?
                            'even-row'
                            :
                            'odd-row'
                    }>
                        <td>{
                            item.stopDate
                                ?
                                new Date(item.startDate).toLocaleString([], {
                                    year: "numeric",
                                    month: "numeric",
                                    day: "numeric",
                                }) + " - " +

                                new Date(item.stopDate).toLocaleString([], {
                                    year: "numeric",
                                    month: "numeric",
                                    day: "numeric",
                                })
                                :
                                new Date(item.startDate).toLocaleString([], {
                                    year: "numeric",
                                    month: "numeric",
                                    day: "numeric",
                                    // hour: '2-digit',
                                    // minute: '2-digit'
                                })}
                        </td>
                        <td>{item.ordersCount}</td>
                        <td>{addDevidersToString(item.totalAmount?.toFixed(2))}</td>
                        <td>{addDevidersToString(item.totalTraderProfit?.toFixed(2))}</td>
                    </tr>

                )
            })
            }

            </tbody>
        </table>


    );
};

export default OrderDailyValueTableAdmin;