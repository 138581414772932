import React from 'react';
import {addDevidersToString} from "./helper-functions";

const OrderDailyValueTableTeamlead = ({valueData}) => {
    return (
        <table className="transaction-table">
            <thead>
            <tr>
                <th>Дата</th>
                <th>Реферал</th>
                <th>Заявки</th>
                <th>Оборот [₽]</th>
                <th>Профит [USDT]</th>


            </tr>
            </thead>
            <tbody>
            {valueData
                .sort((a, b) => a.login.localeCompare(b.login))
                .map((merchant, index) => {


                    return (
                        <tr
                            // key={profit._id}`
                            className={
                                index % 2 === 0
                                    ?
                                    'even-row'
                                    :
                                    'odd-row'
                            }
                        >

                            <td>{
                                merchant.stopDate
                                    ?
                                    new Date(merchant.startDate).toLocaleString([], {
                                        year: "numeric",
                                        month: "numeric",
                                        day: "numeric",
                                    }) + " - " +

                                    new Date(merchant.stopDate).toLocaleString([], {
                                        year: "numeric",
                                        month: "numeric",
                                        day: "numeric",
                                    })
                                    :
                                    new Date(merchant.startDate).toLocaleString([], {
                                        year: "numeric",
                                        month: "numeric",
                                        day: "numeric",
                                        // hour: '2-digit',
                                        // minute: '2-digit'
                                    })}</td>
                            <td>{merchant.login}</td>
                            <td>{merchant.ordersCount}</td>
                            <td>{addDevidersToString(merchant.amountCount.toFixed(2))}</td>
                            <td>{addDevidersToString(merchant.teamleadProfitCount?.toFixed(2))}</td>


                        </tr>
                    );
                })}

            {valueData.length > 1
                ?
                <tr className={"red-row"}>
                    <td>{
                        valueData[0]?.stopDate
                            ?
                            new Date(valueData[0].startDate).toLocaleString([], {
                                year: "numeric",
                                month: "numeric",
                                day: "numeric",
                            }) + " - " +

                            new Date(valueData[0].stopDate).toLocaleString([], {
                                year: "numeric",
                                month: "numeric",
                                day: "numeric",
                            })
                            :
                            new Date(valueData[0].startDate).toLocaleString([], {
                                year: "numeric",
                                month: "numeric",
                                day: "numeric",
                                // hour: '2-digit',
                                // minute: '2-digit'
                            })}
                    </td>
                    <td>TOTAL</td>
                    <td>{valueData.reduce((sum, merchant) => sum + merchant.ordersCount, 0)}</td>
                    <td>{addDevidersToString(valueData.reduce((sum, merchant) => sum + merchant.amountCount, 0).toFixed(2))}</td>
                    <td>{addDevidersToString(valueData.reduce((sum, merchant) => sum + merchant.teamleadProfitCount, 0).toFixed(2))}</td>

                </tr>
                :
                null
            }

            </tbody>
        </table>
    );
};

export default OrderDailyValueTableTeamlead;