import React from 'react';

const MerchantOrderTable = ({orders, processing, filter}) => {
    return (
        orders.length
            ?
            <table className="transaction-table">
                <thead>
                <tr>
                    <th>#</th>
                    <th>Дата</th>
                    <th>Сумма (руб)</th>
                    {/*<th>Сумма (usdt)</th>*/}
                    <th>Статус</th>
                    <th className={"fixed-table-width"}>Профит (usdt)</th>

                </tr>
                </thead>
                <tbody>
                {orders?.map((order, index) => {
                    // const formattedDate = new Date(card.block_timestamp)
                    //     .toLocaleString('ru-RU', options);
                    console.log(order)

                    if(filter.number && parseFloat(filter.number) !== order.number && filter.number !== order.id && filter.number.toString() !== order.external_id) {
                        return
                    }
                    if(filter.status && filter.status !== order.status) return
                    if(filter.amount && filter.amount !== order.amount) return

                    return (
                        <tr
                            key={order._id}
                            className={
                                order.status === "failed"
                                    ?
                                    "red-row"
                                    :
                                    order.status === "opened"
                                        ?
                                        "green-row"
                                        :
                                        order.status === "appealing"
                                            ?
                                            "orange-row"
                                            :
                                            index % 2 === 0
                                                ?
                                                'even-row'
                                                :
                                                'odd-row'
                            }
                        >
                            <td>{order.number}</td>
                            <td>{new Date(order.openDate).toLocaleString([], {
                                year: "numeric",
                                month: "numeric",
                                day: "numeric",
                                hour: '2-digit',
                                minute: '2-digit'
                            })}</td>
                            <td>{order.amount}</td>
                            {/*<td>{order.usdtAmountBybit?.toFixed(2)}</td>*/}
                            <td>{order.status}</td>
                            <td>{order.merchantProfit?.toFixed(2)}</td>

                        </tr>
                    );
                })}
                </tbody>
            </table>
            :
            processing
                ?
                <img src={require('../../img/loading.gif')} height={"40px"}
                     className={"margin-left-20"}/>
                :
                <h2>Нет ничего соответствующего вашему запросу</h2>

    );
};

export default MerchantOrderTable;