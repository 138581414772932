import React, {useEffect, useRef, useState} from 'react';
import $api, {API_URL} from "../http";
import "../css/personal-data.css"

const UnhandledViewer = ({store, merchants}) => {



    const [filter, setFilter] = useState({})
    const [requests, setRequests] = useState([])
    const [skipCounter, setSkipCounter] = useState(0)
    const [fetching, setFetching] = useState(true)
    const [stopFetching, setStopFetching] = useState(false)

    const [processing, setProcessing] = useState(true)
    const [loading, setLoading] = useState(true)

    const startSearchTimer = useRef()


    const getUnhandledRequests = async (filter, skip) => {

        setFetching(false)
        setLoading(true)


        const response = await $api.get(`/get_unhandled_requests?filter=${encodeURIComponent( JSON.stringify(filter) )}&skip=${skip}`)
        console.log(response.data)
        setProcessing(false)
        if(response.data.stopFetching) {
            setStopFetching(true)
            setSkipCounter(0)
        }
        setSkipCounter(prev => prev + response.data.requests.length)
        setRequests(prev => [...prev, ...response.data.requests])
        setLoading(false)

    }


    useEffect(() => {
        store.setPageTitle("Невыдачи")



        if(fetching && !stopFetching) {

            clearTimeout(startSearchTimer.current)

            startSearchTimer.current = setTimeout(() => {
                getUnhandledRequests(filter, skipCounter)
            }, 1500)
        }


    }, [fetching, skipCounter, stopFetching]);




    return (
        <div className={"card-viewer-wrapper"}>
            <div className={"order-viewer-merchant-sort-wrapper"}>
            <select
                value={filter.merchant}
                onChange={(e) => {
                    setProcessing(true)
                    setFilter(prev => ({
                        ...prev,
                        merchant: e.target.value
                    }))

                    setRequests([])

                    setSkipCounter(0)

                    setTimeout(() => {
                        setStopFetching(false)
                        setFetching(true)
                    }, 1000)
                }}
            >
                <option value={""}>Все</option>
                {merchants.map(merchant => <option id={merchant._id}
                                                   value={merchant._id}>{merchant.login}</option>)}
            </select>

                {loading
                ?
                <img src={require('../img/loading.gif')} height={"30px"}
                     className={"margin-left-20"}/>
                :
                null}

            </div>


            <table className="transaction-table">
                <thead>
                <tr>
                    <th>Дата</th>
                    <th>Мерчант</th>
                    <th>Сумма</th>
                    <th>Тим трафика</th>
                    <th>Банк</th>
                    {/*<th>Актуальная сумма</th>*/}
                    {/*<th>Чек</th>*/}

                    {/*<th className={"fixed-table-width"}>Управление</th>*/}

                </tr>
                </thead>
                <tbody>
                {requests.length
                    ?
                    requests?.map((request, index) => {
                        // const formattedDate = new Date(card.block_timestamp)
                        //     .toLocaleString('ru-RU', options);

                        return (
                            <tr
                                key={request._id}
                                className={
                                    index % 2 === 0
                                        ?
                                        'even-row'
                                        :
                                        'odd-row'
                                }
                            >
                                <td>{new Date(request.openDate).toLocaleString([], {
                                    year: "numeric",
                                    month: "numeric",
                                    day: "numeric",
                                    hour: '2-digit',
                                    minute: '2-digit'
                                })}</td>
                                <td>{request.merchantLogin}</td>
                                <td>{request.amount}</td>
                                {/*<td>{request.merchant?.login}</td>*/}
                                {/*<td>{request.trader?.login}</td>*/}
                                <td>{request.trafficType}</td>
                                <td>{request.bank}</td>

                            </tr>
                        );
                    })
                    :
                    null
                }
                </tbody>


            </table>

        </div>
    );
};

export default UnhandledViewer;