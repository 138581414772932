import React, {useState} from 'react';
import ModalDialog from "../ModalDialog";

const GroupCardTable = ({
                            groups, cards, setNewGroupData, activateCreationModal,
                            setButtonText, changeGroupStatus, setProcessing, processing,
                            unbindGroup, deleteGroup
}) => {

    const [activeModalQr, setActiveModalQr] = useState("no")
    const [activeUnbindModal, setActiveUnbindModal] = useState("no")
    const [qrData, setQrData] = useState("")
    const [qrName, setQrName] = useState("")
    const [groupToAction, setGroupToAction] = useState()
    const [groupAction, setGroupAction] = useState()


    const [modalMessage, setModalMessage] = useState("")

    return (
        groups.length
            ?
            <table className="transaction-table">
                <thead>
                <tr>

                    <th>Имя</th>
                    <th>Токен</th>
                    <th>Карты</th>
                    {/*<th>Сумма (usdt)</th>*/}
                    <th>Устройство</th>
                    <th>Тип</th>
                    <th className={"fixed-table-width"}>Управление</th>

                </tr>
                </thead>
                <tbody>
                {groups?.map((group, index) => {
                    // const formattedDate = new Date(card.block_timestamp)
                    //     .toLocaleString('ru-RU', options);
                    console.log(group)


                    return (
                        <tr
                            key={group._id}
                            className={
                                group.status === "failed"
                                    ?
                                    "red-row"
                                    :
                                    group.status === "opened"
                                        ?
                                        "green-row"
                                        :
                                        index % 2 === 0
                                            ?
                                            'even-row'
                                            :
                                            'odd-row'
                            }
                        >
                            <td>{group.name}</td>


                                <td>
                                    {group.token
                                        ?
                                        <button
                                            className="gradient-orange-button qr-token-btn"
                                            onClick={() => {
                                                setQrData(group.token?.token)
                                                setQrName(group.name + "\n" + group.token?.token)
                                                setActiveModalQr("progress")
                                                setTimeout(() => {
                                                    setActiveModalQr("active")
                                                }, 100)
                                            }}
                                        >
                                            Токен
                                        </button>
                                        :
                                        null
                                    }
                                    {/*<span>{card.token?.token}</span>*/}

                                </td>

                            <td>
                                {group.cards.map((item, index) => {
                                    const cardObj = cards.find(card => String(card._id) === String(item));
                                    return <h4 key={index}>{cardObj ? `${cardObj.cardholder} [${cardObj.bank}] [*${cardObj.number.slice(-4)}]` : item}</h4>;
                                })}
                            </td>
                            {/*<td>{group.usdtAmountBybit?.toFixed(2)}</td>*/}
                            <td>{
                                group.device
                                    ?
                                    group.device.online
                                        ?
                                        <div className={"green-bold"}>OnLine</div>
                                        :
                                        <div className={"red-bold"}>OffLine</div>
                                    :
                                    <div className={"red-bold"}>Не привязано</div>
                            }</td>
                            <td>{group.type === "farm" ? "Ферма" : "Мультипарсинг"}</td>
                            <td>
                                {group.device
                                    ?
                                    <div className={"flex-table-cell-column"}>
                                        <div className={"flex-table-cell"}>
                                            <button
                                                className={"gradient-orange-button"}
                                                onClick={() => {
                                                    setButtonText("Сохранить")
                                                    setNewGroupData(group)
                                                    activateCreationModal()

                                                }}
                                            >Изменить
                                            </button>
                                            {group.active
                                                ?
                                                <button
                                                    className={`gradient-red-button ${processing.now && processing.index === index && processing.button === "switch" ? "animated" : null}`}
                                                    onClick={() => {
                                                        setProcessing({now: true, button: "switch", index})
                                                        changeGroupStatus(group._id, group.active)
                                                    }}
                                                >Отключить
                                                </button>
                                                :
                                                <button
                                                    className={`gradient-green-button ${processing.now && processing.index === index && processing.button === "switch" ? "animated" : null}`}
                                                    onClick={() => {
                                                        setProcessing({now: true, button: "switch", index})
                                                        changeGroupStatus(group._id, group.active)
                                                    }}
                                                >Включить
                                                </button>
                                            }

                                        </div>
                                        <div className={"flex-table-cell"}>
                                            <button
                                                className={"gradient-orange-button"}
                                                onClick={() => {

                                                    setGroupAction("unbind")
                                                    setModalMessage(`Вы действительно хотите отвязать группу ${group.name} от устройства?`)

                                                    setGroupToAction(group._id)
                                                    setActiveUnbindModal("progress")
                                                    setTimeout(() => {
                                                        setActiveUnbindModal("active")
                                                    }, 100)
                                                }}
                                            >Отвязать
                                            </button>
                                            <button
                                                className={"gradient-dark-button"}
                                                onClick={() => {

                                                    setGroupAction("delete")
                                                    setModalMessage(`Вы действительно хотите удалить группу ${group.name}?\nКарты при этом не удаляются`)

                                                    setGroupToAction(group._id)
                                                    setActiveUnbindModal("progress")
                                                    setTimeout(() => {
                                                        setActiveUnbindModal("active")
                                                    }, 100)
                                                }}
                                            >Удалить
                                            </button>
                                        </div>
                                    </div>
                                    :
                                    <div className={"flex-table-cell"}>
                                        <button
                                            className={"gradient-orange-button"}
                                            onClick={() => {
                                                setButtonText("Сохранить")
                                                setNewGroupData(group)
                                                activateCreationModal()

                                            }}
                                        >Изменить
                                        </button>
                                        <button
                                            className={"gradient-dark-button"}
                                            onClick={() => {

                                                setGroupAction("delete")
                                                setModalMessage(`Вы действительно хотите удалить группу ${group.name}?\nКарты при этом не удаляются`)

                                                setGroupToAction(group._id)
                                                setActiveUnbindModal("progress")
                                                setTimeout(() => {
                                                    setActiveUnbindModal("active")
                                                }, 100)
                                            }}
                                        >Удалить
                                        </button>
                                    </div>
                                }

                            </td>

                        </tr>
                    );
                })}
                </tbody>
                <ModalDialog
                    active={activeModalQr}
                    setActive={setActiveModalQr}
                    qrcode={qrData}
                    message={qrName}
                    purpose={"info"}
                />

                <ModalDialog
                    active={activeUnbindModal}
                    setActive={setActiveUnbindModal}

                    message={modalMessage}
                    purpose={"choice"}
                    action={
                        groupAction === "unbind"
                            ?
                            () => unbindGroup(groupToAction)
                            :
                            groupAction === "delete"
                                ?
                                () => deleteGroup(groupToAction)
                                :
                                () => {}
                }
                />
                {/*<ModalDialog*/}
                {/*    active={activeUnbindConfirmModal}*/}
                {/*    setActive={setActiveUnbindCoinfitmModal}*/}

                {/*    message={modalMessage}*/}
                {/*    purpose={"choice"}*/}
                {/*    action={() => unbindGroup(groupToAction)}*/}
                {/*/>*/}

            </table>
            :
            <h3>У вас пока нет групп реквизитов</h3>

    )
        ;
};

export default GroupCardTable;