import React, {useEffect, useState} from 'react';
import "../css/stats.css"
import Plate from "./Plate";
import Chart from "./LineChart";
import CrowdImg from "../img/crowd.svg"
import RubleImg from "../img/ruble.svg"
import DollarImg from "../img/dollar.svg"
import $api from "../http";
import axios from "axios";
import {isSameDay, isToday} from "./helpers/helper-functions";

const Statistics = ({store, freshTraderStatistics, freshMerchantStatistics}) => {

    const [ordersData, setOrdersData] = useState([])
    const [rubBalance, setRubBalance] = useState(0)
    const [usdtBalance, setUsdtBalance] = useState(0)

    const [todaysOrdersCounter, setTodaysOrdersCounter] = useState(0)
    // const [todaysTurnoverRub, setTodaysTurnOverRub] = useState(0)
    const [todaysTurnoverRub, setTodaysTurnoverRub] = useState(0)
    const [todaysProfitUsdt, setTodaysProfitUsdt] = useState(0)
    const [tableData, setTableData] = useState([])
    const [actualUsdtPrice, setActualUsdtPrice] = useState(0)


    const startingTurnOverSwitch = localStorage.getItem("startingTurnOverSwitch") === "true"
    const startingBalanceSwitch = localStorage.getItem("startingBalanceSwitch") === "true"
    const startingProfitSwitch = localStorage.getItem("startingProfitSwitch") === "true"



    const pageOpenDate = new Date()


    console.log(startingTurnOverSwitch)
    console.log(typeof startingTurnOverSwitch)

    function randomAmount(signs) {
        return Math.floor(Math.random() * 1000 * signs)
    }


    function countRequestsByHour(data) {
        // Создаем массив для хранения количества запросов по каждому часу
        const hoursCount = Array.from({ length: 24 }, (_, i) => ({
            hour: `${i.toString().padStart(2, '0')}:00`,
            requests: 0,
        }));

        // Проходим по каждому объекту и увеличиваем счетчик для соответствующего часа
        data.forEach(order => {
            const openDate = new Date(order.openDate);
            // Преобразуем в московское время (UTC+3)
            const moscowTime = new Date(openDate.setHours(openDate.getUTCHours() + 3));
            const hour = moscowTime.getHours(); // Получаем московский час

            hoursCount[hour].requests += 1;
        });

        return hoursCount;
    }


    const getBalance = async () => {
        const response = await $api.get(`/get_balance_from_db`)
        console.log(response.data)



        if(store.user.roles.includes('trader')) {

            setOrdersData(response.data.todaysOrders)
            setTodaysOrdersCounter(response.data.todaysOrders.length)

            setRubBalance(response.data.usdtBalance.toFixed(2))

            // const usdtTraderProfitValue = response.data.todaysOrders.reduce((acc, current) => {
            //
            //     acc = acc + current.traderProfit
            //     return acc
            //
            // }, 0)

            setTodaysProfitUsdt(response.data.profitBalance)

            setTodaysOrdersCounter(response.data.todaysOrders.length)


            const usdtTraderTurnoverValue = response.data.todaysOrders.reduce((acc, current) => {

                acc = acc + current.amount
                return acc

            }, 0)


            setTodaysTurnoverRub(usdtTraderTurnoverValue)



        }

        if(store.user.roles.includes('merchant')) {

            setOrdersData(response.data.todaysOrders)
            setTodaysOrdersCounter(response.data.todaysOrders.length)

            const usdtMerchantProfitValue = response.data.todaysOrders.reduce((acc, current) => {

                acc = acc + current.merchantProfit
                return acc

            }, 0)

            setTodaysProfitUsdt(usdtMerchantProfitValue)

            setUsdtBalance(response.data.balance)




            const usdtMerchantTurnoverValue = response.data.todaysOrders.reduce((acc, current) => {

                acc = acc + current.amount
                return acc

            }, 0)


            setTodaysTurnoverRub(usdtMerchantTurnoverValue)


        }

        if(store.user.roles.includes('teamlead')) {
            setUsdtBalance(response.data.balance)
        }


    }



    // const getMerchantBalance = async () => {
    //     const response = await $api.get(`/get_balance_from_db?userId=${store.user.id}`)
    //     console.log(response.data)
    //
    //     setOrdersData(response.data.todaysOrders)
    //
    //     setUsdtBalance(response.data.usdtBalance.toFixed(2))
    //
    //
    //
    //     const usdtTraderProfitValue = response.data.todaysOrders.reduce((acc, current) => {
    //
    //         acc = acc + current.traderProfit
    //         return acc
    //
    //     }, 0)
    //
    //     setTodaysProfitUsdt(usdtTraderProfitValue)
    //
    //     setTodaysOrdersCounter(response.data.todaysOrders.length)
    //
    //
    //     const usdtTraderTurnoverValue = response.data.todaysOrders.reduce((acc, current) => {
    //
    //         acc = acc + current.usdtAmount
    //         return acc
    //
    //     }, 0)
    //
    //
    //     setTodaysTurnOverUsdt(usdtTraderTurnoverValue)
    //
    //
    //
    // }




    const getActualUsdtPrice = async (user) => {
        // const response = await axios.get('https://api.binance.com/api/v3/ticker/price?symbol=USDTRUB')

        // setActualUsdtPrice(response.data.price)

        if(user?.roles?.includes('trader')) {
            const response = await $api.get('/get_ustd_price_trader')

            console.log(response.data)
            console.log(parseFloat(response.data.price))

            setActualUsdtPrice(response.data)
        }

        if(user?.roles?.includes('merchant')) {
            const response = await $api.get('/get_ustd_price_merchant')

            console.log(response.data)
            console.log(parseFloat(response.data.price))

            setActualUsdtPrice(response.data)
        }


    }


    useEffect(() => {
        store.setPageTitle(`Статистика за ${new Date().toLocaleDateString()}`)
        // if(store.user.roles.includes('trader')) {
            getBalance()
        // }
        getActualUsdtPrice(store.user)
    }, [store.user])


    useEffect(() => {

        if(freshTraderStatistics && Object.keys(freshTraderStatistics).length) {


            console.log(freshTraderStatistics)


            if(isSameDay(pageOpenDate, freshTraderStatistics.date)) {

                if(freshTraderStatistics.freshOrderData) {
                    setOrdersData(prev => [...prev, freshTraderStatistics.freshOrderData])
                }

                if(freshTraderStatistics.balance) {
                    setRubBalance(freshTraderStatistics.balance)
                }
                if(freshTraderStatistics.profit) {
                    setTodaysProfitUsdt(prev => {
                        console.log("SettingProfir to - ", prev + freshTraderStatistics.profit)
                        return prev + freshTraderStatistics.profit
                    })
                }
                if(freshTraderStatistics.currentOrderAmount) {
                    setTodaysTurnoverRub(prev => prev + freshTraderStatistics.currentOrderAmount)
                }
                if(freshTraderStatistics.currentOrderAmount) {
                    setTodaysOrdersCounter(prev => prev + 1)
                }

            } else {
                getBalance()
                getActualUsdtPrice()

            }



        }


    }, [freshTraderStatistics])


    useEffect(() => {

        if(freshMerchantStatistics && Object.keys(freshMerchantStatistics).length) {


            console.log(freshMerchantStatistics)


            if(isSameDay(pageOpenDate, freshMerchantStatistics.date)) {

                if(freshMerchantStatistics.freshOrderData) {
                    setOrdersData(prev => [...prev, freshMerchantStatistics.freshOrderData])
                }

                if(freshMerchantStatistics.profit) {

                    setUsdtBalance(prev => prev + freshMerchantStatistics.profit)

                    setTodaysProfitUsdt(prev => {
                        console.log("SettingProfir to - ", prev + freshMerchantStatistics.profit)
                        return prev + freshMerchantStatistics.profit
                    })
                }
                if(freshMerchantStatistics.currentOrderAmount) {
                    setTodaysTurnoverRub(prev => prev + freshMerchantStatistics.currentOrderAmount)
                }
                if(freshMerchantStatistics.currentOrderAmount) {
                    setTodaysOrdersCounter(prev => prev + 1)
                }

            } else {
                getBalance()
                getActualUsdtPrice()

            }



        }


    }, [freshMerchantStatistics])




    useEffect(() => {

        setTableData(countRequestsByHour(ordersData))


    }, [ordersData])


    return (
        <div className={"stats-wrapper"}>

            {store.user.roles.includes('trader') || store.user.roles.includes('merchant')
                ?
                <Plate
                    plateTitle={"Заявки"}
                    plateImage={CrowdImg}
                    plateAmount={todaysOrdersCounter}
                />
                :
                null
            }


            {store.user.roles.includes('trader')
                ?
                <Plate
                    plateTitle={store.user.roles.includes("trader") ? "Профит-баланс" : "Профит"}
                    plateImage={RubleImg}
                    plateImageAlt={DollarImg}
                    plateAmount={todaysProfitUsdt}
                    actualUstdPrice={actualUsdtPrice}
                    // switcher={true}
                    switcherPosition={startingProfitSwitch}
                    localStorageItem={"startingProfitSwitch"}
                    dollar={true}
                />
                :
                null
            }


            {store.user.roles.includes('trader') || store.user.roles.includes('merchant')
                ?
                <Plate
                    plateTitle={"Оборот"}
                    plateImage={RubleImg}
                    plateImageAlt={DollarImg}
                    plateAmount={Math.trunc(todaysTurnoverRub * 100) / 100}
                    actualUstdPrice={actualUsdtPrice}
                    // switcher={true}
                    switcherPosition={startingTurnOverSwitch}
                    localStorageItem={"startingTurnOverSwitch"}
                />
                :
                null
            }


            <Plate
                plateTitle={"Баланс"}
                plateImage={RubleImg}
                plateImageAlt={DollarImg}
                actualUstdPrice={actualUsdtPrice}
                plateAmount={store.user?.roles?.includes('trader') ? rubBalance : usdtBalance}
                // switcher={true}
                switcherPosition={startingBalanceSwitch}
                localStorageItem={"startingBalanceSwitch"}
                dollar={!store.user?.roles?.includes('trader')}
            />


            {store.user.roles.includes('trader') || store.user.roles.includes('merchant')
                ?
                <Chart data={tableData}/>
                :
                null
            }



        </div>
    );
};

export default Statistics;