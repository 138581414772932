import React from 'react';

const SmsTable = ({store, smsStore, processing}) => {
    return (
        smsStore.length
                ?
                <table className="transaction-table">
                    <thead>
                    <tr>
                        <th>Дата</th>
                        <th>Тип</th>
                        <th>Отправитель</th>
                        {store.user.roles.includes('support')
                            ?
                            <th>Трейдер</th>
                            :
                            null
                        }
                        <th>Текст</th>
                        <th>Сумма</th>
                        <th className={"fixed-table-width"}>Заявка</th>

                    </tr>
                    </thead>
                    <tbody>
                    {smsStore?.map((sms, index) => {
                        // const formattedDate = new Date(card.block_timestamp)
                        //     .toLocaleString('ru-RU', options);

                        return (
                            <tr
                                key={sms._id}
                                className={
                                    !sms.order
                                        ?
                                        "red-row"
                                        :
                                        index % 2 === 0
                                            ?
                                            'even-row'
                                            :
                                            'odd-row'
                                }
                            >
                                <td>{new Date(sms.date).toLocaleString([], {
                                    year: "numeric",
                                    month: "numeric",
                                    day: "numeric",
                                    hour: '2-digit',
                                    minute: '2-digit'
                                })}</td>
                                <td>{sms.type}</td>
                                <td>{sms.sender || sms.app}</td>
                                {store.user.roles.includes('support')
                                    ?
                                    <td>{sms.user?.login}</td>
                                    :
                                    null
                                }
                                <td>{sms.text}</td>
                                <td>{sms.amount}</td>
                                <td>{sms.order ? "#" + sms.order?.number : "Не привязана"}</td>

                            </tr>
                        );
                    })}
                    </tbody>
                </table>
                :
                processing
                    ?
                    <img src={require('../../img/loading.gif')} height={"40px"}
                         className={"margin-left-20"}/>
                    :
                    <h2>Нет ничего соответствующего вашему запросу</h2>


    );
};

export default SmsTable;