import React, { useState, useEffect, useRef } from 'react';
import {Link} from "react-router-dom";
import '../css/SidebarMenu.css';
import '../css/animations.css'
import ArrowMenu from "../img/arrow-menu.svg"
import ModalDialog from "./ModalDialog";
import $api from "../http";

const SideMenu = ({isMenuOpen, setIsMenuOpen, store, platformProfit, getPlatformProfit}) => {


    const [activePayoffModal, setActivePayoffModal] = useState("no")
    const [activePayoffConfirmModal, setActivePayoffConfirmModal] = useState("no")
    const [activeInfoModal, setActiveInfoModal] = useState("no")
    const [transactionHash, setTransactionHash] = useState()
    const [modalMessage, setModalMessage] = useState("")


    const [isCardsOpen, setIsCardsOpen] = useState(false);
    // const [isDevicesOpen, setIsDevicesOpen] = useState(false);
    const [isAppealsOpen, setIsAppealsOpen] = useState(false);
    const [isRequestsOpen, setIsRequestsOpen] = useState(false);
    const [isSmsOpen, setIsSmsOpen] = useState(false);
    const [isCabinetOpen, setIsCabinetOpen] = useState(false);
    const [isAdminOpen, setIsAdminOpen] = useState(false);


    const cardsRef = useRef(null);
    // const deviceRef = useRef(null);
    const appealsRef = useRef(null);
    const requestsRef = useRef(null);
    const smsRef = useRef(null);
    const cabinetRef = useRef(null);
    const adminRef = useRef(null);


    const makePayOff = async (transactionHash) => {

        try {

            console.log(transactionHash)

            const response = await $api.post('/make_payoff', {
                type: "Platform",
                transactionHash,
                // agentId: teamleadId

            })

            if(response.data.result === "OK") {

                getPlatformProfit()

                setModalMessage(`Вывод средств на сумму ${response.data.amount} USDT зафиксирован`)
                setActiveInfoModal("progress")
                setTimeout(() => {
                    setActiveInfoModal("active")
                }, 100)

            } else {
                setModalMessage(response.data.message)
                setActiveInfoModal("progress")
                setTimeout(() => {
                    setActiveInfoModal("active")
                }, 100)
            }

            console.log(response.data)

        } catch (e) {
            console.log(e)

            setModalMessage(e.message)
            setActiveInfoModal("progress")
            setTimeout(() => {
                setActiveInfoModal("active")
            }, 100)

        }



    }



    useEffect(() => {
        if (cardsRef.current) {
            cardsRef.current.style.height = isCardsOpen ? `${cardsRef.current.scrollHeight}px` : '0px';
        }
        // if (deviceRef.current) {
        //     deviceRef.current.style.height = isDevicesOpen ? `${deviceRef.current.scrollHeight}px` : '0px';
        // }
        if (appealsRef.current) {
            appealsRef.current.style.height = isAppealsOpen ? `${appealsRef.current.scrollHeight}px` : '0px';
        }
        if (requestsRef.current) {
            requestsRef.current.style.height = isRequestsOpen ? `${requestsRef.current.scrollHeight}px` : '0px';
        }
        if (smsRef.current) {
            smsRef.current.style.height = isSmsOpen ? `${smsRef.current.scrollHeight}px` : '0px';
        }
        if (adminRef.current) {
            adminRef.current.style.height = isAdminOpen ? `${adminRef.current.scrollHeight}px` : '0px';
        }
        if (cabinetRef.current) {
            cabinetRef.current.style.height = isCabinetOpen ? `${cabinetRef.current.scrollHeight}px` : '0px';
        }
    }, [isCardsOpen, isAppealsOpen, isRequestsOpen, isAdminOpen, isCabinetOpen, isSmsOpen]);
    // }, [isCardsOpen, isDevicesOpen, isAppealsOpen, isRequestsOpen, isAdminOpen, isCabinetOpen, isSmsOpen]);


    return (
        <>

            <div className={`sidebar-menu ${isMenuOpen ? 'open' : 'closed'}`}>



                {store.user.admin
                    ?
                    <div
                        className={"platform-profit-monitor"}
                    >
                        <div className="menu-item ">
                            {platformProfit} $

                        </div>
                        <button
                            onClick={() => {
                                setActivePayoffModal("progress")
                                setTimeout(() => {
                                    setActivePayoffModal("active")
                                }, 100)
                                setModalMessage(`Вывод прибыли с баланса площадки`)

                            }}
                            className={"gradient-orange-button"}
                        >Вывод
                        </button>
                    </div>
                    :
                    null
                }
                {store.user.admin
                    ?
                    <div
                        className="menu-item adminka"
                        onClick={() => {
                            setIsAdminOpen(!isAdminOpen)
                            setIsSmsOpen(false)
                            setIsRequestsOpen(false)
                            setIsCardsOpen(false)
                            setIsAppealsOpen(false)
                            // setIsDevicesOpen(false)
                            setIsCabinetOpen(false)

                        }}
                    >

                    <div>Админка</div>
                        <img className={`${isAdminOpen ? "rotate-180" : "rotate-0"}`} src={ArrowMenu}
                             width={"20px"}/>
                    </div>
                    :
                    null
                }
                {store.user.admin
                    ?
                    <div ref={adminRef} className={`submenu ${isAdminOpen ? 'open' : ''}`}>
                        <Link to={"/create_user"} className="submenu-item">➕ Создать пользователя</Link>
                        <Link to={"/user_viewer"} className="submenu-item">💳 Трейдеры</Link>
                        <Link to={"/merchant_viewer"} className="submenu-item">💰 Мерчанты</Link>
                        <Link to={"/support_viewer"} className="submenu-item">🎧 Саппорты</Link>
                        <Link to={"/teamlead_viewer"} className="submenu-item">🔗 Тимлиды</Link>
                        {/*<Link to={"/test_send"} className="submenu-item">Отправка</Link>*/}
                        <Link to={"/stats"} className="submenu-item">📈 Статистика</Link>
                        <Link to={"/payoffs"} className="submenu-item">💵 Выплаты</Link>
                        <Link to={"/top_ups"} className="submenu-item">🏧 Пополнения</Link>
                        <Link to={"/unhandled"} className="submenu-item">❌ Невыдачи</Link>
                        <Link to={"/settings"} className="submenu-item">🛠️ Управление</Link>
                        <Link to={"/broadcast"} className="submenu-item">✉️ Рассылка</Link>
                    </div>
                    :
                    null
                }


                {store.user.roles.includes('support')
                    ?
                    <div>
                        <div
                            className="menu-item"
                            onClick={() => {
                                setIsAppealsOpen(!isAppealsOpen)
                                setIsCardsOpen(false)
                                setIsSmsOpen(false)
                                // setIsDevicesOpen(false)
                                setIsRequestsOpen(false)
                                setIsCabinetOpen(false)
                                setIsAdminOpen(false)
                            }}
                        >
                            <div>Саппорт</div>
                            <img className={`${isAppealsOpen ? "rotate-180" : "rotate-0"}`} src={ArrowMenu}
                                 width={"20px"}/>
                        </div>

                        <div ref={appealsRef} className={`submenu ${isAppealsOpen ? 'open' : ''}`}>
                            <Link to={"/appeals"} className="submenu-item">❓ Апелляции</Link>
                            <Link to={"card_viewer"} className="submenu-item">💳 Карты</Link>
                            <Link to={"/browse_orders"} className="submenu-item">🧾 Заявки</Link>
                            <Link to={"/sms_viewer"} className="submenu-item">📲 SMS/Push</Link>

                        </div>



                    </div>
                    :
                    null
                }

                {store.user.roles.includes('trader')
                    ?
                    <div>
                        <Link to={"/"} className="menu-item">Мониторинг</Link>
                        <div
                            className="menu-item"
                            onClick={() => {
                                setIsCardsOpen(!isCardsOpen)
                                setIsSmsOpen(false)
                                // setIsDevicesOpen(false)
                                setIsRequestsOpen(false)
                                setIsCabinetOpen(false)
                                setIsAdminOpen(false)
                            }}
                        >
                            <div>Карты</div>
                            <img className={`${isCardsOpen ? "rotate-180" : "rotate-0"}`} src={ArrowMenu}
                                 width={"20px"}/>
                        </div>

                        <div ref={cardsRef} className={`submenu ${isCardsOpen ? 'open' : ''}`}>
                            <Link to={"/create_card"} className="submenu-item">Создать</Link>
                            <Link to={"/card_viewer"} className="submenu-item">Все карты</Link>
                            <Link to={"/card_groups"} className="submenu-item">Группы</Link>

                        </div>

                        {/*<div*/}
                        {/*    className="menu-item"*/}
                        {/*    onClick={() => {*/}
                        {/*        setIsDevicesOpen(!isDevicesOpen)*/}
                        {/*        setIsSmsOpen(false)*/}
                        {/*        setIsCardsOpen(false)*/}
                        {/*        setIsRequestsOpen(false)*/}
                        {/*        setIsCabinetOpen(false)*/}
                        {/*        setIsAdminOpen(false)*/}
                        {/*    }}*/}
                        {/*>*/}
                        {/*    <div>Устройства</div>*/}
                        {/*    <img className={`${isDevicesOpen ? "rotate-180" : "rotate-0"}`} src={ArrowMenu}*/}
                        {/*         width={"20px"}/>*/}
                        {/*</div>*/}

                        {/*<div ref={deviceRef} className={`submenu ${isDevicesOpen ? 'open' : ''}`}>*/}
                        {/*    <Link to={"/device_viewer"} className="submenu-item">Обзор</Link>*/}


                        {/*</div>*/}


                        <div
                            className="menu-item"
                            onClick={() => {
                                setIsRequestsOpen(!isRequestsOpen)
                                setIsSmsOpen(false)
                                setIsCardsOpen(false)
                                setIsAppealsOpen(false)
                                // setIsDevicesOpen(false)
                                setIsCabinetOpen(false)
                                setIsAdminOpen(false)
                            }}
                        >
                            <div>Заявки</div>
                            <img className={`${isRequestsOpen ? "rotate-180" : "rotate-0"}`} src={ArrowMenu}
                                 width={"20px"}/>
                        </div>

                        <div ref={requestsRef} className={`submenu ${isRequestsOpen ? 'open' : ''}`}>
                            <Link to={"/browse_orders"} className="submenu-item">Обзор</Link>

                        </div>


                        <div
                            className="menu-item"
                            onClick={() => {
                                setIsAppealsOpen(!isAppealsOpen)
                                setIsCardsOpen(false)
                                setIsSmsOpen(false)
                                // setIsDevicesOpen(false)
                                setIsRequestsOpen(false)
                                setIsCabinetOpen(false)
                                setIsAdminOpen(false)
                            }}
                        >
                            <div>Апелляции</div>
                            <img className={`${isAppealsOpen ? "rotate-180" : "rotate-0"}`} src={ArrowMenu}
                                 width={"20px"}/>
                        </div>

                        <div ref={appealsRef} className={`submenu ${isAppealsOpen ? 'open' : ''}`}>
                            <Link to={"/appeals"} className="submenu-item">Обзор</Link>
                        </div>


                        <div
                            className="menu-item"
                            onClick={() => {
                                setIsSmsOpen(!isSmsOpen)
                                setIsRequestsOpen(false)
                                setIsCardsOpen(false)
                                setIsAppealsOpen(false)
                                // setIsDevicesOpen(false)
                                setIsCabinetOpen(false)
                                setIsAdminOpen(false)
                            }}
                        >
                            <div>SMS/Push</div>
                            <img className={`${isSmsOpen ? "rotate-180" : "rotate-0"}`} src={ArrowMenu}
                                 width={"20px"}/>
                        </div>

                        <div ref={smsRef} className={`submenu ${isSmsOpen ? 'open' : ''}`}>
                            <Link to={"/sms_viewer"} className="submenu-item">Обзор</Link>

                        </div>


                        <div
                            className="menu-item"
                            onClick={() => {
                                setIsCabinetOpen(!isCabinetOpen)
                                setIsSmsOpen(false)
                                setIsRequestsOpen(false)
                                setIsCardsOpen(false)
                                setIsAppealsOpen(false)
                                // setIsDevicesOpen(false)
                                setIsAdminOpen(false)
                            }}
                        >
                            <div>Кабинет</div>
                            <img className={`${isCabinetOpen ? "rotate-180" : "rotate-0"}`} src={ArrowMenu}
                                 width={"20px"}/>
                        </div>

                        <div ref={cabinetRef} className={`submenu ${isCabinetOpen ? 'open' : ''}`}>
                            <Link to={"/personal_data"} className="submenu-item">Мои данные</Link>
                            <Link to={"/top_ups"} className="submenu-item">Пополнения</Link>
                            <Link to={"/payoffs"} className="submenu-item">Выплаты</Link>
                            <Link to={"/stats"} className="submenu-item">Статистика</Link>


                        </div>
                    </div>
                    :
                    null
                }
                {store.user.roles.includes("merchant")
                    ?
                    <div>
                        <Link to={"/"} className="menu-item">Мониторинг</Link>

                        <div
                            className="menu-item"
                            onClick={() => {
                                setIsCabinetOpen(!isCabinetOpen)
                                setIsRequestsOpen(false)
                                setIsSmsOpen(false)
                                setIsCardsOpen(false)
                                setIsAppealsOpen(false)
                                // setIsDevicesOpen(false)
                                setIsAdminOpen(false)
                            }}
                        >
                            <div>Кабинет</div>
                            <img className={`${isCabinetOpen ? "rotate-180" : "rotate-0"}`} src={ArrowMenu}
                                 width={"20px"}/>
                        </div>

                        <div ref={cabinetRef} className={`submenu ${isCabinetOpen ? 'open' : ''}`}>
                            <Link to={"/payoffs"} className="submenu-item">Выплаты</Link>
                            <Link to={"/browse_orders"} className="submenu-item">Заявки</Link>
                            <Link to={"/api_keys"} className="submenu-item">API-keys</Link>




                        </div>
                    </div>
                    :
                    null
                }

                {store.user.roles.includes("teamlead")
                    ?
                    <div>
                        <Link to={"/"} className="menu-item">Мониторинг</Link>

                        <div
                            className="menu-item"
                            onClick={() => {
                                setIsCabinetOpen(!isCabinetOpen)
                                setIsRequestsOpen(false)
                                setIsSmsOpen(false)
                                setIsCardsOpen(false)
                                setIsAppealsOpen(false)
                                // setIsDevicesOpen(false)
                                setIsAdminOpen(false)
                            }}
                        >
                            <div>Кабинет</div>
                            <img className={`${isCabinetOpen ? "rotate-180" : "rotate-0"}`} src={ArrowMenu}
                                 width={"20px"}/>
                        </div>

                        <div ref={cabinetRef} className={`submenu ${isCabinetOpen ? 'open' : ''}`}>
                            <Link to={"/payoffs"} className="submenu-item">Выплаты</Link>
                            <Link to={"/stats"} className="submenu-item">Статистика</Link>


                        </div>
                    </div>
                    :
                    null
                }


            </div>
            {isMenuOpen && <div className="overlay" onClick={() => setIsMenuOpen(false)}></div>}


            {store.user.admin
                ?
                <>
                    <ModalDialog
                        active={activePayoffModal}
                        setActive={setActivePayoffModal}
                        purpose={"input"}
                        inputPlaceHolder={"Хэш транзакции"}
                        inputValue={transactionHash}
                        setInputValue={setTransactionHash}

                        message={modalMessage}
                        action={() => {
                            setActivePayoffConfirmModal("progress")
                            setTimeout(() => {
                                setActivePayoffConfirmModal("active")
                            }, 100)
                            setModalMessage(`Подтверждаете вывод средств?`)
                        }}

                    />
                    <ModalDialog
                        active={activePayoffConfirmModal}
                        setActive={setActivePayoffConfirmModal}
                        purpose={"choice"}
                        message={modalMessage}
                        action={() => {
                            makePayOff(transactionHash)
                        }}
                    />

                    <ModalDialog
                        purpose={"info"}
                        message={modalMessage}
                        active={activeInfoModal}
                        setActive={setActiveInfoModal}
                        buttonText2={"OK"}
                    />
                </>
                :
                null
            }


        </>
    );
};

export default SideMenu;
