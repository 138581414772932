import React, {useEffect, useRef, useState} from 'react';
import $api from "../http";
import "../css/personal-data.css"
import "../css/loading-spinners.css"
import LoadingSpinner from "./LoadingSpinner";
import ModalDialog from "./ModalDialog";

const TopUps = ({store, traders}) => {


    const options = {
        // timeZone: 'Europe/Moscow',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric'
    };

    const fetchLimit = 100

    const [transactions, setTransactions] = useState([])
    const [nextLink, setNextLink] = useState("")

    const [skip, setSkip] = useState(0)
    const [stopFetching, setStopFetching] = useState(false)

    const [isLoading, setIsLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")

    const [activeModal, setActiveModal] = useState("no")
    const [activeProfitTopupModal, setActiveProfitTopupModal] = useState("no")
    const [modalMessage, setModalMessage] = useState("")


    const [profitToTopup, setProfitToTopup] = useState()

    const [inputedHash, setInputedHash] = useState("")




    const [fetching, setFetching] = useState(true)

    const [processing, setProcessing] = useState(true)

    const startSearchTimer = useRef()


    const [filter, setFilter] = useState({})



    // Состояние для отслеживания нажатой кнопки копирования
    const [copiedIndex, setCopiedIndex] = useState(null);

    const handleCopy = (transactionId, index) => {
        navigator.clipboard.writeText(transactionId);
        setCopiedIndex(index);
        // setTimeout(() => setCopiedIndex(null), 2000); // Возвращаем текст обратно через 2 секунды
    };


    const checkTransaction = async (hash, isLoading) => {


        if(isLoading) {
            setModalMessage("Выполняется операция, подождите...")
            return
        }

        setIsLoading(true)

        const response = await $api.post("/check_transaction", {hash})
        console.log(response.data)

        setIsLoading(false)

        if(response.data.result === "OK") {

            const transaction = response.data.transactionData

            setTransactions(prev =>  [transaction, ...prev])
        }

        setModalMessage(response.data.message)
        setActiveModal("progress")
        setTimeout(() => {
            setActiveModal("active")
        }, 100)

    }


    const getTransactions = async (filter, skip) => {

        if (stopFetching) return

        setIsLoading(true)

       // if(link) {
       //     link = encodeURIComponent(link)
       // }


       try {
           const response = await $api.get(`/get_transactions?filter=${encodeURIComponent( JSON.stringify(filter) )}&skip=${skip}&limit=${fetchLimit}`)



           if(response.data.transactions) {

               const transactionsFromBd = response.data.transactions

               if(transactionsFromBd.length < fetchLimit) {
                   setStopFetching(true)
               } else {
                   setSkip(prev => prev + transactionsFromBd.length)
               }


               setTransactions(prev => [...prev, ...transactionsFromBd])

               // if(response.data.next) {
               //     setNextLink(response.data.next)
               // } else {
               //     setNextLink("")
               // }




               console.log(response.data)
           }

           if(response.data.message) {
               setModalMessage("Ошибка запроса транзакций\n" + response.data.message + "\n зовите админа")
               setActiveModal("progress")
               setTimeout(() => {
                   setActiveModal("active")
               }, 100)
           }


       } catch (e) {
           setErrorMessage(e.message)
           setModalMessage("Ошибка смены статуса карты\n" + e.message + "\n зовите админа")
           setActiveModal("progress")
           setTimeout(() => {
               setActiveModal("active")
           }, 100)
       } finally {
           setIsLoading(false)
       }




    }


    const getBalance = async () => {
        const response = await $api.get(`/get_balance_from_db`)
        console.log(response.data)
        setProfitToTopup(parseFloat(response.data.profitBalance?.toFixed(2)))
    }


    const sendProfitToBalance = async (amount) => {

        try {
            const response = await $api.post('/send_profit_to_balance', {amount})



            if(response.data.result === "OK") {
                const transaction = response.data.transactionData

                setTransactions(prev =>  [transaction, ...prev])
            }



            setModalMessage(response.data.message)

            setActiveModal("progress")
            setTimeout(() => {
                setActiveModal("active")
            }, 100)


        } catch (e) {

        }

    }



    useEffect(() => {
        store.setPageTitle("Пополнения")

        getBalance()

    }, [])


    useEffect(() => {



        if(fetching && !stopFetching) {

            clearTimeout(startSearchTimer.current)

            startSearchTimer.current = setTimeout(() => {
                getTransactions(filter, skip)
            }, 1500)
        }


    }, [fetching, skip, stopFetching])








    return (
        <div className={"top-up-wrapper"}>

            {store.user.roles.includes('trader')
                ?
                <div className={"top-up-input-container"}>
                    <input
                        className={"top-up-input"}
                        placeholder={`Вставьте сюда хэш транзакции и нажмите "Добавить"`}
                        onChange={(e) => {
                            setInputedHash(e.target.value)
                        }}
                    />
                    <button
                        className={`${isLoading ? "gradient-light-button" : "gradient-dark-button"} add-transaction-btn`}
                        value={inputedHash}
                        onClick={() => {
                            if (inputedHash) {
                                checkTransaction(inputedHash, isLoading)
                            }

                        }}

                    >Добавить
                        {isLoading
                            ?
                            <img src={require('../img/loading.gif')} height={"15px"} className={"margin-left-20"}/>

                            :
                            null
                        }
                    </button>
                    <button
                        className={"gradient-orange-button profit-topup-btn"}
                        onClick={() => {
                            setActiveProfitTopupModal("progress")
                            setTimeout(() => {
                                setActiveProfitTopupModal("active")
                            }, 100)
                        }}
                    >
                        С профита
                    </button>
                </div>
                :
                <div className={"top-up-input-container"}>
                    <select
                        className={"top-up-user-select"}
                        value={filter.userId}
                        onChange={(e) => {
                            setProcessing(true)
                            setFilter(prev => ({
                                ...prev,
                                userId: e.target.value
                            }))

                            setTransactions([])

                            setSkip(0)

                            setTimeout(() => {
                                setStopFetching(false)
                                setFetching(true)
                            }, 1000)
                        }}
                    >
                        <option value={""}>Все</option>
                        {traders.map(trader => <option id={trader.id} value={trader.id}>{trader.login}</option>)}
                    </select>
                </div>
            }


            <table className="transaction-table">
                <thead>
                <tr>
                    <th>Дата</th>
                    {store.user.admin && !filter.userId
                        ?
                        <th>Трейдер</th>
                        :
                        null
                    }
                    <th>Отправитель</th>
                    <th>Сумма (USDT)</th>
                    <th>Сумма (₽)</th>
                    <th>Курс пополнения</th>
                    <th>Хэш</th>
                </tr>
                </thead>
                <tbody>
                {transactions.map((transaction, index) => {
                    const formattedDate = new Date(transaction.date)
                        .toLocaleString('ru-RU', options);

                    return (
                        <tr key={transaction.transaction_id} className={index % 2 === 0 ? 'even-row' : 'odd-row'}>
                            <td>{formattedDate}</td>
                            {store.user.admin && !filter.userId
                                ?
                                <td>{transaction.user?.login}</td>
                                :
                                null
                            }
                            <td>{transaction.sender}</td>
                            <td>{transaction.amount?.toFixed(2)}</td>
                            <td>{transaction.amountRubles?.toFixed(2)}</td>
                            <td>{transaction.currentUsdtPrice}</td>
                            <td>
                                {transaction.hash
                                    ?
                                    <button
                                        className="copy-button"
                                        onClick={() => handleCopy(transaction.hash, index)}
                                    >
                                    {copiedIndex === index ? 'Copied' : 'Copy hash'}
                                    </button>
                                    :
                                    null
                                }

                            </td>
                        </tr>
                    );
                })}
                </tbody>
            </table>
            {!stopFetching && !isLoading && transactions.length >= fetchLimit
                ?
                <button
                    className={"gradient-dark-button load-transactions-btn"}
                    onClick={() => getTransactions(filter, skip)}
                >Загрузить ещё</button>
                :
                null
            }

            {errorMessage
                ?
                <p>{errorMessage}</p>
                :
                null
            }

            {isLoading
                ?
                <LoadingSpinner/>
                :
                null
            }

            <ModalDialog
                active={activeModal}
                setActive={setActiveModal}
                // action={() => {
                //     setActiveModal("progress")
                //     setTimeout(() => {
                //         setActiveModal("no")
                //     }, 100)
                // }}
                message={modalMessage}
                purpose={"info"}
                buttonText2={"OK"}
            />
            <ModalDialog
                active={activeProfitTopupModal}
                setActive={setActiveProfitTopupModal}
                purpose={"input"}
                inputType={"number"}
                inputValue={profitToTopup}
                setInputValue={setProfitToTopup}
                message={"Перевсти профит на баланс"}
                buttonText1={"Перевести"}
                action={() => {
                    sendProfitToBalance(profitToTopup)
                }}
            />
        </div>
    );
};

export default TopUps;