import React, {useRef, useEffect, useState} from 'react';
import $api from "../http";
import "../css/card-creator.css"
import "../css/order-viewer.css"
import ModalDialog from "./ModalDialog";
import {useNavigate} from "react-router-dom";
import {removeDuplicatesById, throttle} from "./helpers/helper-functions";
import MerchantOrderTable from "./helpers/MerchantOrderTable";
import TraderOrderTable from "./helpers/TraderOrderTable";
import SupportOrderTable from "./helpers/SupportOrderTable";
import LoadingSpinner from "./LoadingSpinner";

const OrderViewer = ({store, completedOrder = {}, setCompletedOrder, openedTraderOrder = {}, setOpenedTraderOrder, traders, merchants}) => {

    // const navigate = useNavigate()




    const [orders, setOrders] = useState([])
    const [skipCounter, setSkipCounter] = useState(0)
    const [fetching, setFetching] = useState(true)
    const [stopFetching, setStopFetching] = useState(false)

    const [processing, setProcessing] = useState(true)

    const startSearchTimer = useRef()


    const [filter, setFilter] = useState({})

    const [activeModal, setActiveModal] = useState("no")
    const [modalMessage, setModalMessage] = useState("")

    const [loading, setLoading] = useState(false)
    const [loadingStats, setLoadingStats] = useState(false)


    const makeOrderDownload = async (filter) => {
        try {


            console.log(!Object.keys(filter).length)

            if(!Object.keys(filter).length) {
                setModalMessage("Сначала установите фильтр")
                setActiveModal("progress")
                    setTimeout(() => {
                        setActiveModal("active")
                    }, 100)

                return
            }


            setLoadingStats(true)

            let response

            if(store.user.roles.includes("merchant") || store.user.admin || store.user.roles.includes("trader")) {
                // Отправляем запрос на сервер
                response = await $api.get(`/get_merchant_download_of_orders?filter=${encodeURIComponent( JSON.stringify(filter) )}`, {
                    responseType: 'blob', // Указываем, что ожидаем blob (файл)
                });

            }


            // Создаем Blob из данных ответа
            const blob = new Blob([response.data], { type: response.headers['content-type'] || 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

            // Генерируем ссылку для скачивания файла
            const downloadURL = URL.createObjectURL(blob);

            // Создаем элемент ссылки
            const link = document.createElement('a');
            link.href = downloadURL;



            // Назначаем имя файла (вы можете передавать имя с сервера)
            const filename = response.headers['content-disposition']
                ? response.headers['content-disposition'].split('filename=')[1].replace(/"/g, '')
                : 'file.xlsx';

            link.download = filename;

            // Добавляем ссылку в DOM и кликаем по ней
            document.body.appendChild(link);
            link.click();

            // Удаляем элемент ссылки и освобождаем URL
            document.body.removeChild(link);
            URL.revokeObjectURL(downloadURL);
        } catch (error) {
            console.error('Ошибка при скачивании файла:', error);
        } finally {
            setLoadingStats(false)
        }
    };


    // const fetchOrders = useCallback((filter, currentAmount) => getOrders(filter, currentAmount), [currentAmount])


    const getOrders = async (filter, skip) => {


        console.log(filter)

        setFetching(false)
        setLoading(true)


        const response = await $api.get(`/get_orders?filter=${encodeURIComponent( JSON.stringify(filter) )}&skip=${skip}`)
        console.log(response.data)
        setProcessing(false)
        if(response.data.stopFetching) {
            setStopFetching(true)
            setSkipCounter(0)
        }
        setSkipCounter(prev => prev + response.data.orders.length)
        setOrders(prev => [...prev, ...response.data.orders])
        setLoading(false)

    }





    useEffect(() => {
        store.setPageTitle("Заявки")

        console.log("TRIGGERING", fetching, !stopFetching, skipCounter)

        if(fetching && !stopFetching) {

            clearTimeout(startSearchTimer.current)

            startSearchTimer.current = setTimeout(() => {
                getOrders(filter, skipCounter)
            }, 1500)
        }


    }, [fetching, skipCounter, stopFetching]);


    useEffect(() => {
        // Проверяем, что completedOrder не пустой объект
        if (
            !processing &&
            Object.keys(completedOrder).length > 0 && // Проверка, чтобы избежать пустого объекта
            (!filter.stopDate || completedOrder.date <= filter.stopDate) &&
            (!filter.startDate || completedOrder.date >= filter.startDate) &&
            !filter?.status
        ) {
            setOrders(prev => {
                const newOrderState = prev.map(order => {
                    // Проверка на совпадение _id и замена объекта
                    return order.id !== completedOrder.id ? order : completedOrder;
                });

                const finalOrderState = removeDuplicatesById(newOrderState)

                return finalOrderState;
            });

            // Обнуление состояния завершенного заказа, но только если был установлен
            if (Object.keys(completedOrder).length > 0) {
                console.log("SETTING TO 0 1")
                setCompletedOrder({});
            }
        } else if(
            !processing &&
            completedOrder?.status !== filter?.status && filter?.status &&
            Object.keys(completedOrder).length > 0
        ) {
            setOrders(prev => {
                const newOrderState = prev.map(order => {
                    // Проверка на совпадение _id и замена объекта
                    return completedOrder.id === order.id && completedOrder?.status !== filter?.status ? null : order;
                });

                const preFinalOrderState = newOrderState.filter(order => order !== null)

                const finalOrderState = removeDuplicatesById(preFinalOrderState)

                return finalOrderState;
            });

            // Обнуление состояния завершенного заказа, но только если был установлен
            if (Object.keys(completedOrder).length > 0) {
                console.log("SETTING TO 0 2")
                setCompletedOrder({});
            }
        } else if(
            !processing &&
            completedOrder?.status === filter?.status &&
            Object.keys(completedOrder).length > 0
        ) {
            setOrders(prev => {
                const preFinalOrderState = [completedOrder, ...prev]
                const finalOrderState = removeDuplicatesById(preFinalOrderState)
                return finalOrderState

            })
            if (Object.keys(completedOrder).length > 0) {
                console.log("SETTING TO 0 3")
                setCompletedOrder({});
            }
        } else {

            console.log(completedOrder)

            console.log(!processing)
            console.log(completedOrder?.status === filter?.status)
            console.log(Object.keys(completedOrder).length > 0)
            console.log(completedOrder)
            console.log(filter)
        }


    }, [completedOrder, filter, processing]);




    useEffect(() => {

        console.log(openedTraderOrder)

        if(
            !processing &&
            Object.keys(openedTraderOrder).length &&
            (openedTraderOrder?.status === filter?.status || !filter?.status)
        ) {

            setOrders(prev => {
                const preFinalOrderState = [openedTraderOrder, ...prev]
                const finalOrderState = removeDuplicatesById(preFinalOrderState)
                return finalOrderState

            })
            setOpenedTraderOrder({})
        }

    }, [openedTraderOrder, filter, processing])



    useEffect(() => {

        //слушатель события "scroll" повешенный на весь документ
        document.addEventListener('scroll', throttle(scrollHandler, 300))

        //при размонтировании элемента эта функция так же отключает слушатель события
        return function() {
            console.log("DO REMOVE")
            document.removeEventListener('scroll', scrollHandler)
        }

    }, [])


    function scrollHandler(e) {

        console.log("SCROLLING")

        if (e.target.documentElement.scrollHeight - e.target.documentElement.scrollTop < window.innerHeight + 100) {
            setFetching(true)

        }


        //console.log('Общая высота', e.target.documentElement.scrollHeight)
        //console.log('Текущее положение', e.target.documentElement.scrollTop)
        //console.log('Высота видимой области', window.innerHeight)

    }



    return (
        <div className={"card-viewer-wrapper"}>

            <div className={"order-viewer-merchant-sort-wrapper"}>
                <label>C</label>
                <input
                    type="date"
                    max={filter.stopDate ? new Date(filter.stopDate).toISOString().slice(0, 10) : ""}
                    value={filter.startDate ? new Date(filter.startDate).toISOString().slice(0, 10) : ""}
                    onChange={(e) => {
                        setProcessing(true)
                        const date = e.target.value ? new Date(e.target.value) : null;
                        setFilter(prev => ({
                            ...prev,
                            startDate: date
                        }));

                        setOrders([]);
                        setSkipCounter(0);

                        setTimeout(() => {
                            setStopFetching(false);
                            setFetching(true);
                        }, 1000);
                    }}
                />
                <label>По</label>
                <input
                    className={"break-element"}
                    type="date"
                    min={filter.startDate ? new Date(filter.startDate).toISOString().slice(0, 10) : ""}
                    value={filter.stopDate ? new Date(filter.stopDate).toISOString().slice(0, 10) : ""}
                    onChange={(e) => {
                        setProcessing(true)
                        const date = e.target.value ? new Date(e.target.value) : null;
                        setFilter(prev => ({
                            ...prev,
                            stopDate: date
                        }));

                        setOrders([]);
                        setSkipCounter(0);

                        setTimeout(() => {
                            setStopFetching(false);
                            setFetching(true);
                        }, 1000);
                    }}
                />
            </div>
            <div className={"order-viewer-merchant-sort-wrapper"}>
                <select
                    defaultValue={""}
                    value={filter?.status}
                    onChange={(e) => {
                        setProcessing(true)
                        setFilter(prev => ({
                            ...prev,
                            status: e.target.value
                        }))

                        setOrders([])

                        setSkipCounter(0)

                        setTimeout(() => {
                            setStopFetching(false)
                            setFetching(true)
                        }, 1000)
                    }}
                >
                    <option value={""}>Все</option>
                    <option value={"completed"}>Успешные</option>
                    <option value={"opened"}>Открытые</option>
                    <option value={"failed"}>Неудачные</option>
                    <option value={"appealing"}>На апелляции</option>
                </select>
                {store.user.roles.includes("support")
                    ?
                    <select
                        value={filter.trader}
                        onChange={(e) => {
                            setProcessing(true)
                            setFilter(prev => ({
                                ...prev,
                                trader: e.target.value
                            }))

                            setOrders([])

                            setSkipCounter(0)

                            setTimeout(() => {
                                setStopFetching(false)
                                setFetching(true)
                            }, 1000)
                        }}
                    >
                        <option value={""}>Все</option>
                        {Object.values(traders)
                            .sort((a, b) => a.login.localeCompare(b.login))
                            .map(trader => (
                                <option key={trader.id} value={trader.id}>
                                    {trader.login}
                                </option>
                            ))}

                    </select>
                    :
                    null
                }
                {store.user.admin && merchants
                    ?
                    <select
                        value={filter.merchant}
                        onChange={(e) => {
                            setProcessing(true)
                            setFilter(prev => ({
                                ...prev,
                                merchant: e.target.value
                            }))

                            setOrders([])

                            setSkipCounter(0)

                            setTimeout(() => {
                                setStopFetching(false)
                                setFetching(true)
                            }, 1000)
                        }}
                    >
                        <option value={""}>Все</option>
                        {Object.values(merchants)
                            .sort((a, b) => a.login.localeCompare(b.login))
                            .map(merchant => (
                                <option key={merchant.id} value={merchant._id}>
                                    {merchant.login}
                                </option>
                            ))}

                    </select>
                    :
                    null
                }
                <input
                    // type={"number"}
                    placeholder={"По номеру или ID"}
                    value={filter.number}
                    onChange={(e) => {
                        setProcessing(true)

                        setFilter(prev => {
                            const updatedFilter = {...prev}; // Создаем копию объекта
                            if (e.target.value) {

                                console.log("Мы блять в блоке IF")
                                console.log("Длина таргета - ", e.target.value.length)

                                updatedFilter.number = e.target.value; // Устанавливаем значение
                            } else {
                                delete updatedFilter.number; // Удаляем поле
                            }

                            console.log(updatedFilter)

                            return updatedFilter;
                        });


                        setOrders([])

                        setSkipCounter(0)

                        setTimeout(() => {
                            setStopFetching(false)
                            setFetching(true)
                        }, 1000)
                    }}
                />
                <input
                    type={"number"}
                    placeholder={"По сумме"}
                    value={filter.amount}
                    onChange={(e) => {
                        setProcessing(true)
                        setFilter(prev => {
                            return {
                                ...prev,
                                amount: parseFloat(e.target.value)
                            }
                        })
                        setOrders([])

                        setSkipCounter(0)

                        setTimeout(() => {
                            setStopFetching(false)
                            setFetching(true)
                        }, 1000)
                    }}
                />
                <button
                    className={"gradient-dark-button"}
                    onClick={() => {
                        setProcessing(true)

                        setFilter({number: "", amount: "", status: ""})
                        setTimeout(() => {
                            setFilter({})
                        }, 500)


                        setOrders([])

                        setSkipCounter(0)

                        setTimeout(() => {
                            setStopFetching(false)
                            setFetching(true)
                        }, 1000)
                    }}
                >Сброс
                </button>

                {store.user.roles.includes("merchant") || store.user.admin || store.user.roles.includes("trader")
                    ?
                    <button
                        onClick={() => makeOrderDownload(filter)}
                        className={"gradient-orange-button load-stat-btn"}
                    >
                        Выгрузить {
                        loadingStats
                            ?
                            <img src={require('../img/loading.gif')} height={"15px"}
                                                       className={"margin-left-20"}/>
                            :
                            null
                    }
                    </button>
                    :
                    null
                }


            </div>
            {store.user.roles[0] === "merchant"
                ?
                <MerchantOrderTable orders={orders} processing={processing} filter={filter}/>
                :
                store.user.roles[0] === "trader"
                    ?
                    <TraderOrderTable orders={orders} processing={processing} filter={filter}/>
                    :
                    <SupportOrderTable orders={orders} setOrders={setOrders} processing={processing} store={store} filter={filter}/>
            }
            {fetching && orders.length && !stopFetching
                ?
                <LoadingSpinner/>
                :
                null
            }


            {/*<table className="transaction-table">*/}
            {/*    <thead>*/}
            {/*    <tr>*/}
            {/*        <th>Внутренний номер</th>*/}
            {/*        <th>Дата</th>*/}
            {/*        <th>Сумма (руб)</th>*/}
            {/*        <th>Сумма (usdt)</th>*/}
            {/*        <th>Статус</th>*/}
            {/*        <th className={"fixed-table-width"}>Профит (usdt)</th>*/}

            {/*    </tr>*/}
            {/*    </thead>*/}
            {/*    <tbody>*/}
            {/*    {orders?.map((order, index) => {*/}
            {/*        // const formattedDate = new Date(card.block_timestamp)*/}
            {/*        //     .toLocaleString('ru-RU', options);*/}

            {/*        return (*/}
            {/*            <tr*/}
            {/*                key={order._id}*/}
            {/*                className={*/}
            {/*                    order?.status === "failed"*/}
            {/*                        ?*/}
            {/*                        "red-row"*/}
            {/*                        :*/}
            {/*                        index % 2 === 0*/}
            {/*                            ?*/}
            {/*                            'even-row'*/}
            {/*                            :*/}
            {/*                            'odd-row'*/}
            {/*                }*/}
            {/*            >*/}
            {/*                <td>{order.number}</td>*/}
            {/*                <td>{new Date(order.openDate).toLocaleString([],{year: "numeric", month: "numeric", day: "numeric", hour: '2-digit', minute:'2-digit'})}</td>*/}
            {/*                <td>{order.amount}</td>*/}
            {/*                <td>{order.usdtAmount?.toFixed(2)}</td>*/}
            {/*                <td>{order?.status}</td>*/}
            {/*                <td>{order.merchantProfit?.toFixed(2)}</td>*/}

            {/*            </tr>*/}
            {/*        );*/}
            {/*    })}*/}
            {/*    </tbody>*/}
            {/*</table>*/}


            <ModalDialog
                active={activeModal}
                setActive={setActiveModal}
                // action={() => {
                //     setActiveModal("progress")
                //     setTimeout(() => {
                //         setActiveModal("no")
                //     }, 100)
                // }}
                message={modalMessage}
                purpose={"info"}
            />
        </div>
    );
};

export default OrderViewer;