import React, {useEffect, useState} from 'react';
import $api from "../http";
import OrderDailyValueTableAdmin from "./helpers/OrderDailyValueTableAdmin";
import LoadingSpinner from "./LoadingSpinner";
import {addDevidersToString} from "./helpers/helper-functions";
import OrderDailyValueTableTrader from "./helpers/OrderDailyValueTableTrader";
import OrderDailyValueTableTeamlead from "./helpers/OrderDailyValueTableTeamlead";

const WholeStats = ({store, merchants, traders}) => {



    const [valueData, setValueData] = useState([])
    const [filter, setFilter] = useState({startDate: new Date()})
    const [processing, setProcessing] = useState(false)
    const [goCounter, setGoCounter] = useState(0)


    const getMerchantDailyValue = async  (filter) => {

        const response = await $api.get(`/get_merchant_daily_value?filter=${encodeURIComponent(JSON.stringify(filter))}`)

        setValueData(response.data)

        setProcessing(false)

    }

    const getTraderDailyValue = async  (filter) => {

        const response = await $api.get(`/get_trader_daily_value?filter=${encodeURIComponent(JSON.stringify(filter))}`)


        console.log(response.data)

        setValueData(response.data)

        setProcessing(false)

    }


    const getTeamleadDailyValue = async  (filter) => {

        const response = await $api.get(`/get_teamlead_daily_value?filter=${encodeURIComponent(JSON.stringify(filter))}`)


        console.log(response.data)

        setValueData(response.data)

        setProcessing(false)

    }




    useEffect(() => {

        store.setPageTitle("Сводные данные")

        if(goCounter > 0) {
            setProcessing(true)
            setValueData([])

            if(store.user.admin) {
                getMerchantDailyValue(filter)

            } else if(store.user.roles.includes('trader')) {
                getTraderDailyValue(filter)
            } else if(store.user.roles.includes('teamlead')) {
                getTeamleadDailyValue(filter)
            }

        }



    }, [goCounter])




    return (
        <div className={"card-viewer-wrapper"}>

            <div className={"order-viewer-merchant-sort-wrapper"}>
                <label>C</label>
                <input
                    type="date"
                    max={filter.stopDate ? new Date(filter.stopDate).toISOString().slice(0, 10) : ""}
                    value={filter.startDate ? new Date(filter.startDate).toISOString().slice(0, 10) : ""}
                    onChange={(e) => {
                        // setProcessing(true)
                        const date = e.target.value ? new Date(e.target.value) : null;
                        setFilter(prev => ({
                            ...prev,
                            startDate: date
                        }));

                        // setValueData({});
                        // setSkipCounter(0);
                        //
                        // setTimeout(() => {
                        //     setStopFetching(false);
                        //     setFetching(true);
                        // }, 1000);
                    }}
                />
                <label>По</label>
                <input
                    type="date"
                    min={filter.startDate ? new Date(filter.startDate).toISOString().slice(0, 10) : ""}
                    value={filter.stopDate ? new Date(filter.stopDate).toISOString().slice(0, 10) : ""}
                    onChange={(e) => {
                        // setProcessing(true)
                        const date = e.target.value ? new Date(e.target.value) : null;
                        setFilter(prev => ({
                            ...prev,
                            stopDate: date
                        }));

                        // setValueData({});
                        // setSkipCounter(0);

                        // setTimeout(() => {
                        //     setStopFetching(false);
                        //     setFetching(true);
                        // }, 1000);
                    }}
                />
                {store.user.admin && merchants
                    ?
                    <select
                        value={filter.merchant}
                        onChange={(e) => {
                            // setProcessing(true)
                            setFilter(prev => ({
                                ...prev,
                                merchant: e.target.value
                            }))

                            // setValueData([])

                            // setSkipCounter(0)
                            //
                            // setTimeout(() => {
                            //     setStopFetching(false)
                            //     setFetching(true)
                            // }, 1000)
                        }}
                    >
                        <option value={""}>Все</option>
                        {Object.values(merchants)
                            .sort((a, b) => a.login.localeCompare(b.login))
                            .map(merchant => (
                                <option key={merchant.id} value={merchant._id}>
                                    {merchant.login}
                                </option>
                            ))}

                    </select>
                    :
                    null
                }
                {store.user.admin
                    ?
                    <select
                        value={filter.trader}
                        onChange={(e) => {
                            // setProcessing(true)
                            setFilter(prev => ({
                                ...prev,
                                trader: e.target.value
                            }))

                            // setValueData([])

                            // setSkipCounter(0)

                            // setTimeout(() => {
                            //     setStopFetching(false)
                            //     setFetching(true)
                            // }, 1000)
                        }}
                    >
                        <option value={""}>Все</option>
                        {Object.values(traders)
                            .sort((a, b) => a.login.localeCompare(b.login))
                            .map(trader => (
                                <option key={trader.id} value={trader.id}>
                                    {trader.login}
                                </option>
                            ))}

                    </select>
                    :
                    null
                }

                <button
                    className={"gradient-dark-button"}
                    onClick={() => {
                        // setProcessing(true)
                        setFilter({startDate: new Date(), merchant: "", trader: ""})
                        // setValueData({})

                        // setSkipCounter(0)
                        //
                        // setTimeout(() => {
                        //     setStopFetching(false)
                        //     setFetching(true)
                        // }, 1000)
                    }}
                >Сброс
                </button>
                <button
                    className={"gradient-orange-button"}
                    onClick={() => {
                        // setProcessing(true)
                        // setFilter({startDate: new Date()})
                        // setValueData({})

                        setGoCounter(prev => prev + 1)

                        // setSkipCounter(0)
                        //
                        // setTimeout(() => {
                        //     setStopFetching(false)
                        //     setFetching(true)
                        // }, 1000)
                    }}
                >GO
                </button>
            </div>


            {valueData.length
                ?
                store.user.admin
                    ?
                    <OrderDailyValueTableAdmin valueData={valueData}/>
                    :
                    store.user.roles.includes('trader')
                        ?
                        <OrderDailyValueTableTrader valueData={valueData}/>
                        :
                        <OrderDailyValueTableTeamlead valueData={valueData}/>
                :
                goCounter > 0
                    ?
                    processing
                        ?
                        null
                        :
                        <h3>
                            За {filter.stopDate ? `период с ${new Date(filter.startDate).toLocaleString([], {
                            year: "numeric",
                            month: "numeric",
                            day: "numeric",
                        })} по ${new Date(filter.stopDate).toLocaleString([], {
                            year: "numeric",
                            month: "numeric",
                            day: "numeric",
                        })}` : new Date(filter.startDate).toLocaleString([], {
                            year: "numeric",
                            month: "numeric",
                            day: "numeric",
                        })} данных нет
                        </h3>
                    :
                    <h3>Выставляем фильтр, и GO!</h3>
            }


            {processing
                ?
                <LoadingSpinner/>
                :
                null
            }
        </div>
    );
};

export default WholeStats;