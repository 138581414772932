import React, {useState} from 'react';
import {addDevidersToString} from "./helper-functions";

const PayoffTableShort = ({profits, store}) => {

    // Состояние для отслеживания нажатой кнопки копирования
    const [copiedIndex, setCopiedIndex] = useState(null);

    const handleCopy = (item, index) => {
        navigator.clipboard.writeText(item);
        setCopiedIndex(index);
        // setTimeout(() => setCopiedIndex(null), 2000); // Возвращаем текст обратно через 2 секунды
    };


    return (
        <table className="transaction-table">
            <thead>
            <tr>
                <th>Дата</th>
                <th>Сумма [USDT]</th>
                <th>Хэш</th>


            </tr>
            </thead>
            <tbody>
            {profits?.map((profit, index) => {
                // const formattedDate = new Date(card.block_timestamp)
                //     .toLocaleString('ru-RU', options);

                return (
                    <tr
                        key={profit._id}
                        className={
                            index % 2 === 0
                                ?
                                'even-row'
                                :
                                'odd-row'
                        }
                    >

                        <td>{new Date(profit.date).toLocaleString([], {
                            year: "numeric",
                            month: "numeric",
                            day: "numeric",
                            hour: '2-digit',
                            minute: '2-digit'
                        })}</td>
                        <td>{addDevidersToString(profit.amount.toFixed(2))}</td>
                        <td>
                            <button
                                className="copy-button"
                                onClick={() => handleCopy(profit.hash, index)}
                            >

                                {copiedIndex === index ? 'Copied' : 'Copy hash'}
                            </button>
                        </td>


                    </tr>
                );
            })}
            </tbody>
        </table>
    );
};

export default PayoffTableShort;