import React, {useEffect, useState} from 'react';
import $api from "../http";
import "../css/user-creator.css"
import ModalDialog from "./ModalDialog";
import {useNavigate} from "react-router-dom";
import {addDevidersToString} from "./helpers/helper-functions";

const TeamleadViewer = ({store}) => {


    const navigate = useNavigate()

    const [loading, setLoading] = useState(false)
    const [processing, setProcessing] = useState({status: false, index: null})

    const [teamleads, setTeamleads] = useState([])
    const [teamlead, setTeamlead] = useState([])
    const [transactionHash, setTransactionHash] = useState()

    const [activeModal, setActiveModal] = useState("no")
    const [modalMessage, setModalMessage] = useState("")


    const [activePayoffModal, setActivePayoffModal] = useState("no")
    const [activePayoffConfirmModal, setActivePayoffConfirmModal] = useState("no")
    const [activeInfoModal, setActiveInfoModal] = useState("no")

    const getTeamleads = async () => {
        setLoading(true)
        const response = await $api.get('/get_teamleads')
        console.log(response.data)
        setTeamleads(response.data)
        setLoading(false)
    }

    const makePayOff = async (transactionHash, teamleadId) => {

        try {


            console.log(transactionHash, teamleadId)




            const response = await $api.post('/make_payoff', {
                type: "Teamlead",
                transactionHash,
                agentId: teamleadId

            })

            if(response.data.result === "OK") {

                const freshTeamleadData = response.data.teamleadData


                console.log(freshTeamleadData)

                setTeamleads(prev => {
                    const newTeamleadState = prev.map(teramlead => {

                        if(teramlead._id === freshTeamleadData._id) {

                            teramlead.balance = freshTeamleadData.balance
                            return teramlead

                        } else {
                            return teramlead
                        }
                    })

                    return newTeamleadState


                })

                setModalMessage(`Выплата для ${teamlead.login} на сумму ${response.data.amount} USDT зафиксирована`)
                setActiveInfoModal("progress")
                setTimeout(() => {
                    setActiveInfoModal("active")
                }, 100)

            } else {
                setModalMessage(response.data.message)
                setActiveInfoModal("progress")
                setTimeout(() => {
                    setActiveInfoModal("active")
                }, 100)
            }

            console.log(response.data)

        } catch (e) {
            console.log(e)

            setModalMessage(e.message)
            setActiveInfoModal("progress")
            setTimeout(() => {
                setActiveInfoModal("active")
            }, 100)

        }



    }


    // Состояние для отслеживания нажатой кнопки копирования
    const [copiedIndex, setCopiedIndex] = useState(null);

    const handleCopy = (apiKey, index) => {
        navigator.clipboard.writeText(apiKey);
        setCopiedIndex(index);
        // setTimeout(() => setCopiedIndex(null), 2000); // Возвращаем текст обратно через 2 секунды
    };

    // const changeTraderStatus = async (userId, index) => {
    //
    //     setProcessing({status: true, index})
    //
    //     try {
    //         console.log(userId, index)
    //         const response = await $api.get(`/change_trader_status?userId=${userId}`)
    //         console.log(response.data)
    //
    //         // Получаем обновленный объект карты из ответа
    //         const updatedUser = response.data;
    //
    //         // Обновляем состояние карт
    //         setMerchants(prevUsers =>
    //             prevUsers.map(user =>
    //                 user.id === updatedUser.id
    //                     ? { ...user, active: updatedUser.active } // Копируем все поля и меняем только поле active
    //                     : user
    //             )
    //         );
    //
    //     } catch (e) {
    //         console.log(e)
    //     }
    //
    //
    //
    //
    //     setProcessing({status: false, index})
    //
    // }


    useEffect(() => {
        store.setPageTitle("Тимлиды")
        getTeamleads()
    }, []);

    return (
        <div className={"user-viewer-wrapper"}>
            <table className="transaction-table">
                <thead>
                <tr>
                    <th>login</th>
                    <th>Баланс [USDT]</th>
                    <th>Ставка [%]</th>
                    <th>Изменение</th>

                </tr>
                </thead>
                <tbody>
                {teamleads.map((teamlead, index) => {
                    // const formattedDate = new Date(card.block_timestamp)
                    //     .toLocaleString('ru-RU', options);

                    return (
                        <tr key={teamlead.id} className={index % 2 === 0 ? 'even-row' : 'odd-row'}>
                            <td>{teamlead.login}</td>
                            <td>
                                <div className={"flex-table-cell-column"}>
                                    <h4 className={"main-admin-data-h"}>{addDevidersToString(teamlead.balance?.toFixed(2))}</h4>
                                    <button
                                        onClick={() => {
                                            setActivePayoffModal("progress")
                                            setTimeout(() => {
                                                setActivePayoffModal("active")
                                            }, 100)
                                            setModalMessage(`Выплата для ${teamlead.login}`)
                                            setTeamlead(teamlead)
                                        }}
                                        className={"gradient-orange-button"}
                                    >Выплата
                                    </button>
                                </div>
                            </td>

                            <td>{teamlead.stake}</td>
                            {/*<td>*/}
                            {/*    <button*/}
                            {/*        className="copy-button"*/}
                            {/*        onClick={() => handleCopy(teamlead.apiKey, index)}*/}
                            {/*    >*/}
                            {/*        {copiedIndex === index ? 'Copied' : 'Copy API-key'}*/}
                            {/*    </button>*/}
                            {/*</td>*/}
                            <td>
                                <button
                                    className={"gradient-orange-button"}
                                    onClick={() => {
                                        store.setTeamleadToEditId(teamlead._id)
                                        navigate('/create_user')

                                    }}
                                >Редактировать
                                </button>
                            </td>
                        </tr>
                    );
                })}
                </tbody>
            </table>
            {loading
                ?
                <img src={require('../img/loading.gif')} height={"35px"} className={"margin-left-20"}/>
                :
                null
            }
            <ModalDialog
                active={activeModal}
                setActive={setActiveModal}
                // action={() => {
                //     setActiveModal("progress")
                //     setTimeout(() => {
                //         setActiveModal("no")
                //     }, 100)
                // }}
                message={modalMessage}
                purpose={"info"}
            />

            <ModalDialog
                active={activePayoffModal}
                setActive={setActivePayoffModal}
                purpose={"input"}
                inputPlaceHolder={"Хэш транзакции"}
                inputValue={transactionHash}
                setInputValue={setTransactionHash}

                message={modalMessage}
                action={() => {
                    setActivePayoffConfirmModal("progress")
                    setTimeout(() => {
                        setActivePayoffConfirmModal("active")
                    }, 100)
                    setModalMessage(`Подтверждаете выплату для ${teamlead.login}?`)
                }}

            />
            <ModalDialog
                active={activePayoffConfirmModal}
                setActive={setActivePayoffConfirmModal}
                purpose={"choice"}
                message={modalMessage}
                action={() => {
                    makePayOff(transactionHash, teamlead._id)
                }}
            />

            <ModalDialog
                purpose={"info"}
                message={modalMessage}
                active={activeInfoModal}
                setActive={setActiveInfoModal}
                buttonText2={"OK"}
            />
        </div>
    );
};

export default TeamleadViewer;