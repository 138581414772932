import React, {useRef, useState, useEffect} from 'react';
import $api from "../http";
import {throttle} from "./helpers/helper-functions";
import PayoffTableAdmin from "./helpers/PayoffTableAdmin";
import PayoffTableShort from "./helpers/PayoffTableShort";

const PayoffViewer = ({store, traders, merchants, teamleads}) => {

    const [payoffs, setPayoffs] = useState([])
    const [skipCounter, setSkipCounter] = useState(0)
    const [fetching, setFetching] = useState(true)
    const [stopFetching, setStopFetching] = useState(false)

    const [processing, setProcessing] = useState(true)

    const startSearchTimer = useRef()


    const [filter, setFilter] = useState({type: "Merchant"})



    const [activeModal, setActiveModal] = useState("no")
    const [modalMessage, setModalMessage] = useState("")


    const getPayoffs = async (filter, skip) => {

        setFetching(false)

        const response = await $api.get(`/get_payoffs?filter=${encodeURIComponent( JSON.stringify(filter) )}&skip=${skip}`)

        console.log(response.data)
        setProcessing(false)

        if(response.data.stopFetching) {
            setStopFetching(true)
            setSkipCounter(0)
        }
        setSkipCounter(prev => prev + response.data.payoffs.length)
        setPayoffs(prev => [...prev, ...response.data.payoffs])


    }

    useEffect(() => {

        store.setPageTitle("Выплаты")

        if(fetching && !stopFetching) {

            clearTimeout(startSearchTimer.current)

            startSearchTimer.current = setTimeout(() => {
                getPayoffs(filter, skipCounter)
            }, 1500)
        }


    }, [fetching, skipCounter, stopFetching])


    useEffect(() => {

        //слушатель события "scroll" повешенный на весь документ


        const throttledHandler = throttle(scrollHandler, 300);
        document.addEventListener('scroll', throttledHandler);

        return function cleanup() {
            console.log("DO REMOVE");
            document.removeEventListener('scroll', throttledHandler);
        };


    }, [])

    function scrollHandler(e) {

        console.log("SCROLLING")

        if (e.target.documentElement.scrollHeight - e.target.documentElement.scrollTop < window.innerHeight + 100) {
            setFetching(true)

        }

    }






    return (
        <div className={"profit-viewer-wrapper"}>
            <div className={"order-viewer-merchant-sort-wrapper"}>
                <label>C</label>
                <input
                    type="date"
                    max={filter.stopDate ? new Date(filter.stopDate).toISOString().slice(0, 10) : ""}
                    value={filter.startDate ? new Date(filter.startDate).toISOString().slice(0, 10) : ""}
                    onChange={(e) => {
                        setProcessing(true)
                        const date = e.target.value ? new Date(e.target.value) : null;
                        setFilter(prev => ({
                            ...prev,
                            startDate: date
                        }));

                        setPayoffs([]);
                        setSkipCounter(0);

                        setTimeout(() => {
                            setStopFetching(false);
                            setFetching(true);
                        }, 1000);
                    }}
                />
                <label>По</label>
                <input
                    type="date"
                    min={filter.startDate ? new Date(filter.startDate).toISOString().slice(0, 10) : ""}
                    value={filter.stopDate ? new Date(filter.stopDate).toISOString().slice(0, 10) : ""}
                    onChange={(e) => {
                        setProcessing(true)
                        const date = e.target.value ? new Date(e.target.value) : null;
                        setFilter(prev => ({
                            ...prev,
                            stopDate: date
                        }));

                        setPayoffs([]);
                        setSkipCounter(0);

                        setTimeout(() => {
                            setStopFetching(false);
                            setFetching(true);
                        }, 1000);
                    }}
                />
                {store.user.admin
                    ?
                    <select
                        defaultValue={"Merchant"}
                        value={filter.type}
                        onChange={(e) => {
                            setProcessing(true)
                            setFilter(prev => ({
                                ...prev,
                                type: e.target.value
                            }))

                            setPayoffs([])
                            setFilter(prev => ({
                                ...prev,
                                userId: ""
                            }))

                            setSkipCounter(0)

                            setTimeout(() => {
                                setStopFetching(false)
                                setFetching(true)
                            }, 1000)
                        }}
                    >
                        <option value={""}>Площадка</option>
                        <option value={"User"}>Трейдеры</option>
                        <option value={"Merchant"}>Мерчанты</option>
                        <option value={"Teamlead"}>Тимлиды</option>
                    </select>
                    :
                    null
                }
                {store.user.admin && filter.type
                    ?
                    <select
                        value={filter.userId}
                        onChange={(e) => {
                            setProcessing(true)
                            setFilter(prev => ({
                                ...prev,
                                userId: e.target.value
                            }))

                            setPayoffs([])

                            setSkipCounter(0)

                            setTimeout(() => {
                                setStopFetching(false)
                                setFetching(true)
                            }, 1000)
                        }}
                    >
                        <option value={""}>Все</option>
                        {filter.type === "User"
                            ?
                            traders.map(trader => <option id={trader.id} value={trader.id}>{trader.login}</option>)
                            :
                            filter.type === "Merchant"
                                ?
                                merchants.map(merchant => <option id={merchant._id}
                                                                  value={merchant._id}>{merchant.login}</option>)
                                :
                                teamleads.map(teamlead => <option id={teamlead._id}
                                                                  value={teamlead._id}>{teamlead.login}</option>)

                        }
                    </select>
                    :
                    null
                }
                {/*{store.user.admin && filter.type*/}
                {/*    ?*/}
                {/*    <select*/}
                {/*        value={filter.paid}*/}
                {/*        onChange={(e) => {*/}
                {/*            setProcessing(true)*/}
                {/*            setFilter(prev => ({*/}
                {/*                ...prev,*/}
                {/*                paid: e.target.value*/}
                {/*            }))*/}

                {/*            setPayoffs([])*/}

                {/*            setSkipCounter(0)*/}

                {/*            setTimeout(() => {*/}
                {/*                setStopFetching(false)*/}
                {/*                setFetching(true)*/}
                {/*            }, 1000)*/}
                {/*        }}*/}
                {/*    >*/}
                {/*        <option value={""}>Все</option>*/}
                {/*        <option value={true}>Выплаченные</option>*/}
                {/*        <option value={false}>Не выплаченные</option>*/}

                {/*    </select>*/}
                {/*    :*/}
                {/*    null*/}
                {/*}*/}
                <button
                    className={"gradient-dark-button"}
                    onClick={() => {
                        setProcessing(true)
                        setFilter({status: "", startDate: "", stopDate: ""})
                        setPayoffs([])

                        setSkipCounter(0)

                        setTimeout(() => {
                            setStopFetching(false)
                            setFetching(true)
                        }, 1000)
                    }}
                >Сброс
                </button>

            </div>


            {payoffs.length
                ?
                store.user.admin
                    ?
                    <PayoffTableAdmin
                        profits={payoffs}
                        filter={filter}
                        // payOff={payOff}
                        // setProfitToPay={setProfitToPay}
                        setModalMessage={setModalMessage}
                        setActiveModal={setActiveModal}

                    />
                    :
                    <PayoffTableShort profits={payoffs} store={store}/>
                :
                processing
                    ?
                    <img src={require('../img/loading.gif')} height={"40px"}
                         className={"margin-left-20"}/>
                    :
                    <h2>Нет ничего соответствующего вашему запросу</h2>
            }

        </div>
    );
};

export default PayoffViewer;