import React, {useEffect, useState} from 'react';
import $api from "../http";
import "../css/card-creator.css"
import "../css/styles.css"
import Select from "react-select";
import InputMask from 'react-input-mask';
import ModalDialog from "./ModalDialog";
import {useNavigate} from "react-router-dom";


const CardCreator = ({store}) => {

    const [selectedCountry, setSelectedCountry] = useState("RU");
    const [mask, setMask] = useState("+7 (\\999)-999-9999");

    // Список стран с масками
    const countryOptions = [
        { value: "RU", label: "Россия", mask: "+7 \\(999\\)-999-9999" },
        { value: "AZ", label: "Азербайджан", mask: "+\\9\\94 \\(99\\)-999-99-99" },
        { value: "TJ", label: "Таджикистан", mask: "+9\\9\\2 \\(99\\)-999-9999" },
    ];


    const maskOptions =
        {
            RU: "+7 \\(999\\)-999-9999" ,
            AZ: "+\\(\\9\\9\\4\\) 999-999-999",
            TJ: "+\\(\\9\\9\\2\\) 999-999-999"
        }
    ;



    const handleCountryChange = (selectedOption) => {
        setSelectedCountry(selectedOption.value);
        setMask(selectedOption.mask)
        setCardData((prev) => ({ ...prev, phone: "", country: selectedOption.value}));
    };

    const navigate = useNavigate()

    const [bankOptions, setBanksOptions] = useState([])

    useEffect(() => {

        getBanks()

        if(store.cardToEditId) {

            getCardToEdit()
        } else {
            store.setPageTitle("Создание карты")
        }


        return () => { store.setCardToEditId("") }

    }, [])


    const getBanks = async () => {
        const response = await $api.get('/get_banks')


        const optionsToRoll = response.data
            .map(bank => ({value: bank.code, label: bank.name, transgran: !!bank.transgran, geo: bank.geo}))
            .sort((a, b) => a.label.localeCompare(b.label))

        setBanksOptions(optionsToRoll)

    }


    const getCardToEdit = async (cardId) => {
        store.setPageTitle("Редактирование карты")
        const response = await $api.get(`/get_card_by_id?cardId=${store.cardToEditId}`)
        console.log(response.data)
        setCardData(response.data)
    }


    const [cardData, setCardData] = useState({isEditable: true, webHook: false})

    const ruissianNamesOfFields = {
        'bank': "Банк",
        'number': "Номер",
        // 'deviceId': "ID устройства",
        'cardholder': cardData.trafficType === "nspk"
            ?
            cardData.opf === "ooo"
                ?
                "ООО"
                :
                "ИП"
            :
            "ФИО",
        'min': "Минимальная сумма",
        'max': "Максимальная сумма",
        'dayLimit': "Дневной лимит",
        'phone': "Телефон для СБП",
        'account': "Счёт",
        'trafficType': "Тип трафика",
        'maxDailyOrders': "Заявок в сутки",
        'limitOfOpened': "Одновременно открытых",
        'intervalBetweenTransactions': "Минут между заявками",
        'qrcode': "QR-код",
        "opf": "ОПФ",
        "geo": "ГЕО",
        "bankFeeValue": "Комиссия банка"
    }


    const opfOptions = [
        {value: "ooo", label: "ООО"},
        {value: "ip", label: "ИП"},
    ]


    const trafficOptions = [

        {value: "any", label: "Любой"},
        {value: "sbp", label: "Только по СБП"},
        {value: "card_number", label: "Только по номеру карты"},
        {value: "account_number", label: "Только по номеру счёта"},
        {value: "nspk", label: "QR - НСПК"},


    ]


    // useEffect(() => {
    //
    //     const options = [
    //
    //         {value: "any", label: "Любой"},
    //         {value: "sbp", label: "Только по СБП"},
    //         {value: "card_number", label: "Только по номеру карты"},
    //         {value: "account_number", label: "Только по номеру счёта"},
    //         {value: "nspk", label: "QR - НСПК"},
    //
    //
    //     ]
    //
    //     // if(cardData.bankCode === "VTB") {
    //     //     options.push({value: "applink_vtb", label: "Оплата только с ВТБ"})
    //     // }
    //
    //     // if(cardData.bankCode === "Ozon") {
    //     //     options.push({value: "applink_ozon", label: "Оплата только с Озон"})
    //     // }
    //
    //     setCardData(prev => {
    //         return {
    //             ...prev,
    //             trafficType: null
    //         }
    //     })
    //
    //
    //     setTrafficOptions(options)
    //
    //
    //
    // }, [cardData.bankCode])


    // const trafficOptions = [
    //
    //     {value: "any", label: "Любой"},
    //     {value: "sbp", label: "Только по СБП"},
    //     {value: "card_number", label: "Только по номеру карты"},
    //     {value: "account_number", label: "Только по номеру счёта"},
    //     {value: "nspk", label: "QR - НСПК"},
    //     {value: "applink_vtb", label: "Оплата только с ВТБ"},
    //
    // ]

    const trafficOptionsTransgran = [

        {value: "any_transgran", label: "Любой трансгран"},
        {value: "sbp_transgran", label: "Трансгран по СБП"},
        {value: "card_number_transgran", label: "Трансгран по номеру карты"},

    ]




    function addDevidersToString(number) {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    }

    function formatPhoneNumber(phoneNumber, country) {
        // Проверим, что длина номера равна 11 символам
        if (phoneNumber.length !== 11) {
            // throw new Error("Неверный формат номера");
        }


        console.log(phoneNumber)

        if(country === "RU" || country === "ABH" || !country) {
            // Разделим номер на части
            const countryCode = phoneNumber.slice(0, 1);  // +7
            const areaCode = phoneNumber.slice(1, 4);     // 956
            const firstPart = phoneNumber.slice(4, 7);    // 188
            const secondPart = phoneNumber.slice(7, 9);   // 95
            const thirdPart = phoneNumber.slice(9);       // 18
            // Соберем форматированный номер
            return `+${countryCode} (${areaCode}) ${firstPart} ${secondPart} ${thirdPart}` //${country}`;
        }

        if(country === "AZ") {
            // Разделим номер на части
            const countryCode = phoneNumber.slice(0, 3);  // +994
            const areaCode = phoneNumber.slice(3, 5);     // 98
            const firstPart = phoneNumber.slice(5, 8);    // 198
            const secondPart = phoneNumber.slice(7, 9);   // 95
            const thirdPart = phoneNumber.slice(9);       // 18
            // Соберем форматированный номер
            return `+${countryCode} (${areaCode}) ${firstPart} ${secondPart} ${thirdPart} ${country}`;
        }

        if(country === "TJ") {
            // Разделим номер на части
            const countryCode = phoneNumber.slice(0, 3);  // +994
            const areaCode = phoneNumber.slice(3, 6);     // 98
            const firstPart = phoneNumber.slice(6, 9);    // 198
            const secondPart = phoneNumber.slice(9, 12);   // 95
            // const thirdPart = phoneNumber.slice(9);       // 18
            // Соберем форматированный номер
            return `+(${countryCode}) ${areaCode} ${firstPart} ${secondPart}`;
        }




    }




    const [activeModal, setActiveModal] = useState("no")
    const [modalMessage, setModalMessage] = useState("")
    const [dataToShow, setDataToShow] = useState({})
    const [dataToShowInModal, setDataToShowInModal] = useState(null)


    const [createError, setCreateError] = useState({show: false, message: ""})
    const [qrClick, setQrClick] = useState(false)
    const [qrVisible, setQrVisible] = useState(false);

    const [showOk, setShowOk] = useState(false)


    const createRequest = async (cardData) => {

        try {

            // cardData.deviceId = cardData.deviceId.replace(/-/g, "")
            cardData.number = cardData.number.replace(/-/g, "")

            if(store.cardToEditId) {
                cardData._id = store.cardToEditId
            }



            const response = await $api.post('/create_card', {cardData: JSON.stringify(cardData)})

            console.log(response.data)

            if(response.data === "OK") {
                setShowOk(true)
                setCardData({
                    'bank': "",
                    'bankCode': "",
                    'number': "",
                    // 'deviceId': "",
                    'cardholder': "",
                    'min': "",
                    'max': "",
                    'dayLimit': "",
                    'phone': "",
                    'account': "",
                    'trafficType': "",
                    "limitOfOpened": "",
                    "maxDailyOrders": "",
                    "intervalBetweenTransactions": "",
                    "opf": "",
                    "qrcode": "",
                    "transgran": "",
                    "geo": "",
                    "bankFeeValue": "",
                    "directBank": ""
                })

                if(store.cardToEditId) {
                    store.setCardToEditId("")
                    navigate('/card_viewer')
                }

                setTimeout(() => {
                    setShowOk(false)
                }, 5000)
            } else {
                setCreateError({show: true, message: response.data.message})
            }

        } catch (e) {
            console.log(e)
            setCreateError({show: true, message: "Ошибка создания карты"})
        }
    }


    const createCard = async (cardData, qrVisible, qrClick) => {

        setCreateError({show: false})

        if(cardData.qrcode && cardData.trafficType === "nspk" && !qrClick) {
            setCreateError({show: true, message: "Сначала проверьте ссылку на ваш QR-код"})
            return
        }

        if(cardData.qrcode && cardData.trafficType === "nspk" && !qrVisible && !cardData._id) {
            setCreateError({show: true, message: "Подтвердите что вы увидели свой QR-код"})
            return
        }

        // Список обязательных полей
        const requiredFields = [
            'bank',
            'number',
            'cardholder',
            'min',
            'max',
            'dayLimit',
            'phone',
            'account',
            'opf',
            'qrcode',
            'trafficType'
        ];

        // Проверка на заполнение всех полей
        const allFieldsFilled = requiredFields.every(field => {
            if(cardData.trafficType !== "nspk" && (field === "opf" || field === "qrcode")) {
                return true
            }
            return cardData[field] !== undefined && cardData[field] !== null && cardData[field] !== '';
        });

        if (allFieldsFilled) {
            // Если все поля заполнены

            if(cardData.number.includes("_")) {
                setCreateError({show: true, message: "Карта содержит неверное значение"})
                return
            }

            if(cardData.phone.includes("_")) {
                setCreateError({show: true, message: "Телефон содержит неверное значение"})
                return
            }

            // if(cardData.deviceId.includes("_") || cardData.deviceId.replace(/-/g, "").length !== 15) {
            //     setCreateError({show: true, message: "ID устройства содержит неверное значение"})
            //     return
            // }


            setModalMessage("Подтвердите данные карточки")

            console.log(cardData)

            const divElement = (
                <div className={"data-to-show-in-modal"}>

                    {Object.entries(cardData).map(([key, value]) => {
                    if(key === "min" || key === "max" || key === "dayLimit") {
                        value = addDevidersToString(value)
                    } else if (key === "phone") {
                        value = formatPhoneNumber(value, cardData.geo)
                    } else if (
                        key === "deviceId" ||
                        key === "bankCode" ||
                        key === "isEditable" ||
                        key === "qrcode" ||
                        key === "webHook" ||
                        key === "transgran" ||
                        key === "directBank"
                    ) {
                        return
                    }


                        return (
                            <div
                                key={key}
                                className={"data-to-show-in-modal-piece"}
                            >
                                <strong
                                    style={{"marginRight": "5px"}}
                                >{ruissianNamesOfFields[key]}: </strong>
                            {value !== "any"
                                ?
                                value || "не указано"
                                :
                                trafficOptions.find(item => item.value === value).label
                            }
                        </div>
                    )})}
                </div>
            );


            setDataToShowInModal(divElement)

            setActiveModal("progress")
            setTimeout(() => {
                setActiveModal("active")
            }, 100)


        } else {
            // Если какие-то поля не заполнены, выполняем другое действие
            // console.log(cardData.deviceId)
            // console.log(cardData.deviceId.length)
            setCreateError({show: true, message: "Заполнены не все поля"})
        }
    };



    return (
        <div className={"card-creator-wrapper"}>

            <Select

                isDisabled={
                    store.user.roles.includes('support')
                        ?
                        false
                        :
                        cardData.bank
                            ?
                            !cardData.isEditable
                            :
                            false
                }
                placeholder={"Банк"}
                className={"card-creator-selector"}
                value={
                    cardData.bankCode
                        ? {
                            value: cardData.bankCode,
                            label: bankOptions.find(option => option.value === cardData.bankCode)?.label,
                        }
                        : null // null лучше для пустого значения
                }
                options={bankOptions}
                onChange={(selected) => {
                    console.log(selected); // Убедись, что `selected` содержит правильный объект


                    setCardData(prev => ({
                        ...prev,
                        bankCode: selected.value, // Храни `value`, а не `label`
                        bank: selected.label, // Храни `value`, а не `label`
                        transgran: selected.transgran,
                        geo: selected.geo,
                        trafficType: ""
                    }));
                }}
            />


            <Select
                placeholder={"Тип трафика"}
                className={"card-creator-selector"}
                isDisabled={!cardData.bank}
                value={cardData.trafficType ?
                    {
                        value: cardData.trafficType,
                        label: (cardData.transgran ? trafficOptionsTransgran : trafficOptions).find(option => option.value === cardData.trafficType)?.label
                    } : ""
                }
                options={
                    cardData.transgran
                        ?
                        trafficOptionsTransgran
                        :
                        trafficOptions
                }
                onChange={(selected) => {
                    console.log(selected)
                    setCreateError({show: false})
                    setCardData(prev => {
                        return {
                            ...prev,
                            trafficType: selected.value
                        }
                    })

                }}
            />


            {cardData.trafficType === "nspk"
                ?
                <Select
                    value={cardData.opf ?
                        {
                            value: cardData.opf,
                            label: opfOptions.find(option => option.value === cardData.opf)?.label
                        } : ""
                    }
                    placeholder={"Организационно правовая форма"}
                    className={"card-creator-selector"}
                    options={opfOptions}
                    onChange={(selected) => {

                        setCreateError({show: false})
                        setCardData(prev => {
                            return {
                                ...prev,
                                opf: selected.value
                            }
                        })

                    }}
                />
                :
                null
            }

            <InputMask
                isDisabled={
                    store.user.roles.includes('support')
                        ?
                        false
                        :
                        cardData.bank
                            ?
                            !cardData.isEditable
                            :
                            false
                }
                className={"card-creator-input"}
                type={"text"}
                // alwaysShowMask={true}
                mask="9999-9999-9999-9999"
                maskChar="_"
                placeholder={"Номер карты"}
                value={cardData.number}
                onChange={(e) => {
                    setCardData(prev => {
                        setCreateError({show: false})
                        const value = e.target.value
                        return {
                            ...prev,
                            number: value
                        }
                    })
                }}
            />

            <input
                isDisabled={
                    store.user.roles.includes('support')
                        ?
                        false
                        :
                        cardData.bank
                            ?
                            !cardData.isEditable
                            :
                            false
                }
                className={"card-creator-input"}
                type={"text"}
                placeholder={cardData.trafficType === "nspk"
                    ?
                    cardData.opf === "ooo"
                        ?
                        "Наименование организации"
                        :
                        "Ф.И.О. индивидуального предпринимателя"
                    :
                    "Ф.И.О."
                }
                value={cardData.cardholder}
                onChange={(e) => {
                    setCreateError({show: false})
                    setCardData(prev => {
                        return {
                            ...prev,
                            cardholder: e.target.value
                        }
                    })
                }}
            />
            <input
                className={"card-creator-input"}
                type={"number"}
                placeholder={"Минимальная сумма транзакции"}
                value={cardData.min}
                onWheel={(e) => {
                    e.target.blur(); // убираем фокус, чтобы scroll wheel не меняло значение
                }}
                onChange={(e) => {
                    setCreateError({show: false})
                    setCardData(prev => {
                        return {
                            ...prev,
                            min: parseFloat(e.target.value)
                        }
                    })
                }}
            />
            <input
                className={"card-creator-input"}
                type={"number"}
                placeholder={"Максимальная сумма транзакции"}
                value={cardData.max}
                onWheel={(e) => {
                    e.target.blur(); // убираем фокус, чтобы scroll wheel не меняло значение
                }}
                onChange={(e) => {
                    setCreateError({show: false})
                    setCardData(prev => {
                        return {
                            ...prev,
                            max: parseFloat(e.target.value)
                        }
                    })
                }}
            />
            <input
                // disabled={!cardData.isEditable}
                className={"card-creator-input"}
                type={"number"}
                placeholder={"Дневной лимит (руб.)"}
                value={cardData.dayLimit}
                onWheel={(e) => {
                    e.target.blur(); // убираем фокус, чтобы scroll wheel не меняло значение
                }}
                onChange={(e) => {
                    setCreateError({show: false})
                    setCardData(prev => {
                        return {
                            ...prev,
                            dayLimit: e.target.value
                        }
                    })
                }}
            />


            <input
                disabled={!!cardData.intervalBetweenTransactions}
                className={"card-creator-input"}
                type={"number"}
                placeholder={
                    cardData.intervalBetweenTransactions
                        ?
                        "1"
                        :
                        "Максимальное количество одновременно открытых заявок"
                }
                value={cardData.intervalBetweenTransactions ? "" : cardData.limitOfOpened}
                onWheel={(e) => {
                    e.target.blur(); // убираем фокус, чтобы scroll wheel не меняло значение
                }}
                onChange={(e) => {
                    setCreateError({show: false})
                    setCardData(prev => {
                        return {
                            ...prev,
                            limitOfOpened: e.target.value
                        }
                    })
                }}
            />

            <input
                className={"card-creator-input"}
                type={"number"}
                placeholder={"Максимальное количество успешных заявок в сутки"}
                value={cardData.maxDailyOrders}
                onWheel={(e) => {
                    e.target.blur(); // убираем фокус, чтобы scroll wheel не меняло значение
                }}
                onChange={(e) => {
                    setCreateError({show: false})
                    setCardData(prev => {
                        return {
                            ...prev,
                            maxDailyOrders: e.target.value
                        }
                    })
                }}
            />

            <input

                className={"card-creator-input"}
                type={"number"}
                placeholder={"Интервал между транзакциями в минутах не менее..."}
                value={cardData.intervalBetweenTransactions}
                onWheel={(e) => {
                    e.target.blur(); // убираем фокус, чтобы scroll wheel не меняло значение
                }}
                onChange={(e) => {
                    setCreateError({show: false})
                    setCardData(prev => {
                        return {
                            ...prev,
                            intervalBetweenTransactions: e.target.value,
                            limitOfOpened: 1
                        }
                    })
                }}
            />

            {/*<Select*/}
            {/*    className={"card-creator-selector"}*/}
            {/*    options={countryOptions}*/}
            {/*    value={countryOptions.find((option) => option.value === selectedCountry)}*/}
            {/*    onChange={handleCountryChange}*/}
            {/*    placeholder="Выберите страну"*/}

            {/*/>*/}
            {cardData.bankCode === "Sber"
                ?
                <input
                    className={"card-creator-input"}
                    type={"number"}
                    placeholder={"Комиссия банка (%)"}
                    value={cardData.bankFeeValue}
                    onWheel={(e) => {
                        e.target.blur(); // убираем фокус, чтобы scroll wheel не меняло значение
                    }}
                    onChange={(e) => {
                        setCreateError({show: false})
                        setCardData(prev => {
                            return {
                                ...prev,
                                bankFeeValue: parseFloat(e.target.value)
                            }
                        })
                    }}
                />
                :
                null
            }


            <InputMask
                disabled={false} // Здесь вы можете настроить логику для блокировки
                className={"card-creator-input"}
                type={"text"}
                placeholder={"Телефон для СБП"}
                value={cardData.phone}
                onChange={(e) => {
                    setCreateError({show: false});
                    setCardData((prev) => {
                        const value = e.target.value.replace(/[-\s()+]/g, "");
                        return {
                            ...prev,
                            phone: value,
                        };
                    });
                }}
                // mask={mask}
                mask={maskOptions[cardData.geo || "RU"]}
                maskChar="_"
            />

            {/*<InputMask*/}
            {/*    disabled={store.user.roles.includes('support') ? false : !cardData.isEditable}*/}
            {/*    className={"card-creator-input"}*/}
            {/*    type={"text"}*/}
            {/*    placeholder={"Телефон для СБП"}*/}
            {/*    value={cardData.phone}*/}
            {/*    onChange={(e) => {*/}
            {/*        setCreateError({show: false})*/}
            {/*        setCardData(prev => {*/}
            {/*            const value = e.target.value.replace(/[-\s()+]/g, "");*/}
            {/*            return {*/}
            {/*                ...prev,*/}
            {/*                phone: value*/}
            {/*            }*/}
            {/*        })*/}
            {/*    }}*/}
            {/*    // alwaysShowMask={true}*/}
            {/*    mask="+7 (\999)-999-9999"*/}
            {/*    maskChar="_"*/}


            {/*/>*/}
            <input
                isDisabled={
                    store.user.roles.includes('support')
                        ?
                        false
                        :
                        cardData.bank
                            ?
                            !cardData.isEditable
                            :
                            false
                }
                className={"card-creator-input"}
                type={"text"}
                placeholder={"Счёт"}
                value={cardData.account}
                onChange={(e) => {
                    setCreateError({show: false})
                    setCardData(prev => {
                        return {
                            ...prev,
                            account: e.target.value
                        }
                    })
                }}

            />

            <div className={"user-creator-only-card-container"}>
                <input
                    checked={cardData.directBank}
                    type="checkbox"
                    id="direct-checkbox"
                    onClick={() => {
                        setCreateError({show: false})
                        setCardData(prev => {
                            return {
                                ...prev,
                                directBank: !prev.directBank
                            }
                        })
                    }}
                />
                <label htmlFor="direct-checkbox">Платежи только с банка этой карты (ставить только после консультации с саппортами)</label>
            </div>

            {store.user.allowedWebHook ? (
                <div className={"user-creator-only-card-container"}>
                    <input
                        checked={cardData.webHook}
                        type="checkbox"
                        id="qr-checkbox"
                        onClick={() => {
                            setCreateError({show: false})
                            setCardData(prev => {
                                return {
                                    ...prev,
                                    webHook: !prev.webHook
                                }
                            })
                        }}
                    />
                    <label htmlFor="qr-checkbox">Уведомления по WebHook</label>
                </div>
            ) : null}

            {cardData.trafficType === "nspk"
                ?
                <input
                    disabled={!cardData.isEditable}
                    value={cardData.qrcode}
                    placeholder={"Ссылка на QR-код"}
                    className={"card-creator-input"}
                    onChange={(e) => {
                        setCreateError({show: false})
                        setCardData(prev => {
                            return {
                                ...prev,
                                qrcode: e.target.value
                            }
                        })
                    }}
                />
                :
                null
            }

            {cardData.trafficType === "nspk"
                ?
                cardData.qrcode
                    ?
                    <div className={"card-creator-qr-link-container-wrapper"}>
                        <div className={"card-creator-qr-link-container"}>
                            <a
                                className={"gradient-orange-button"}
                                rel={"noopener noreferrer"}
                                href={cardData.qrcode}
                                target={"_blank"}
                                onClick={() => setQrClick(true)}
                            >Нажми меня</a>
                            <div>{`<<<  Попробуйте открыть ваш QR-код`}</div>
                        </div>
                        {qrClick ? (
                            <div>
                                <input
                                    type="checkbox"
                                    id="qr-checkbox-confirm"
                                    onClick={() => {
                                        setCreateError({show: false})
                                        setQrVisible(prev => !prev)
                                    }}
                                />
                                <label htmlFor="qr-checkbox-confirm">Я увидел свой QR-код</label>
                            </div>
                        ) : null}
                    </div>
                    :
                    null
                :
                null
            }

            <div className={"create-card-btn-container"}>


                {createError.show
                    ?
                    <h3 className={"create-card-error-message red-for-blue"}>{createError.message}</h3>
                    :
                    null
                }
                {showOk && !createError.show
                    ?
                    <h3 className={"create-card-success-message"}>Карта добавлена успешно!</h3>
                    :
                    null
                }

                <div className={"card-creator-btn-wrapper"}>
                    <button
                        className={"gradient-dark-button create-card-btn"}
                        onClick={() => createCard(cardData, qrVisible, qrClick)}
                    >{store.cardToEditId ? "Сохранить" : "Создать"}</button>

                    {store.cardToEditId
                        ?
                        <button
                            className={"gradient-red-button create-card-btn"}
                            onClick={() => {
                                store.setCardToEditId("")
                                navigate("/card_viewer")
                            }}
                        >Отмена</button>
                        :
                        null
                    }
                </div>


            </div>

            <ModalDialog
                active={activeModal}
                setActive={setActiveModal}
                action={() => {
                    createRequest(cardData)
                }}
                dataToShow={dataToShowInModal}
                message={modalMessage}
                purpose={"choice"}
            />
        </div>
    );
};

export default CardCreator;