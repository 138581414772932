import React, {useState} from 'react';
import {addDevidersToString} from "./helper-functions";

const PayoffTableAdmin = ({profits, filter, setProfitToPay, setModalMessage, setActiveModal}) => {



    // Состояние для отслеживания нажатой кнопки копирования
    const [copiedIndex, setCopiedIndex] = useState(null);

    const handleCopy = (item, index) => {
        navigator.clipboard.writeText(item);
        setCopiedIndex(index);
        // setTimeout(() => setCopiedIndex(null), 2000); // Возвращаем текст обратно через 2 секунды
    };


    return (
        <table className="transaction-table">
            <thead>
            <tr>
                <th>Дата</th>
                <th>Агент</th>
                {filter.type
                    ?
                    <th>NickName</th>
                    :
                    null
                }
                <th>Сумма [USDT]</th>
                <th>Хэш</th>
                {/*{filter.type*/}
                {/*    ?*/}
                {/*    <th>Статус</th>*/}
                {/*    :*/}
                {/*    null*/}
                {/*}*/}

                {/*{filter.type*/}
                {/*    ?*/}
                {/*    <th>Управление</th>*/}
                {/*    :*/}
                {/*    null*/}
                {/*}*/}


            </tr>
            </thead>
            <tbody>
            {profits?.map((profit, index) => {
                // const formattedDate = new Date(card.block_timestamp)
                //     .toLocaleString('ru-RU', options);

                const profitDate = new Date(profit.date).toLocaleString([], {
                    year: "numeric",
                    month: "numeric",
                    day: "numeric",
                    hour: '2-digit',
                    minute: '2-digit'
                })

                return (
                    <tr
                        key={profit._id}
                        className={
                            index % 2 === 0
                                ?
                                'even-row'
                                :
                                'odd-row'
                        }
                    >

                        <td>{profitDate}</td>
                        <td>{profit.userType === "User" ? "Trader" : profit.userType}</td>
                        {filter.type
                            ?
                            <td>{profit.user?.login}</td>
                            :
                            null
                        }
                        <td>{addDevidersToString(profit.amount.toFixed(2))}</td>
                        <td>
                            <button
                                className="copy-button"
                                onClick={() => handleCopy(profit.hash, index)}
                            >

                                {copiedIndex === index ? 'Copied' : 'Copy hash'}
                            </button>
                        </td>
                        {/*{filter.type*/}
                        {/*    ?*/}
                        {/*    <td>{profit.paid ? "Выплачен" : "Не выплачен"}</td>*/}
                        {/*    :*/}
                        {/*    null*/}
                        {/*}*/}
                        {/*{filter.type*/}
                        {/*    ?*/}
                        {/*    <td>*/}
                        {/*        {profit.paid*/}
                        {/*            ?*/}
                        {/*            null*/}
                        {/*            :*/}
                        {/*            <button*/}
                        {/*                className={"gradient-orange-button"}*/}
                        {/*                onClick={() => {*/}

                        {/*                    console.log("setting", profit._id)*/}
                        {/*                    setProfitToPay(profit._id)*/}
                        {/*                    setModalMessage(`Вы уверены что хотите погасить выплату для ${*/}
                        {/*                        filter.type === "User"*/}
                        {/*                            ?*/}
                        {/*                            "трейдера"*/}
                        {/*                            :*/}
                        {/*                            filter.type === "Merchant"*/}
                        {/*                                ?*/}
                        {/*                                "мерчанта"*/}
                        {/*                                :*/}
                        {/*                                "тимлида"*/}
                        {/*                            */}
                        {/*                    }\n${profit.user?.login}\nна сумму ${addDevidersToString(profit.amount.toFixed(2))} USDT\nза ${profitDate}`)*/}

                        {/*                    setActiveModal("progress")*/}
                        {/*                    setTimeout(() => {*/}
                        {/*                        setActiveModal("active")*/}
                        {/*                    }, 100)*/}
                        {/*                }}*/}
                        {/*            >Погасить</button>*/}
                        {/*        }*/}
                        {/*    </td>*/}
                        {/*    :*/}
                        {/*    null*/}
                        {/*}*/}


                    </tr>
                );
            })}
            </tbody>
        </table>
    );
};

export default PayoffTableAdmin;