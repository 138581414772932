import React, {useEffect, useState} from 'react';
import $api from "../http";
import "../css/user-creator.css"
import ModalDialog from "./ModalDialog";
import {useNavigate} from "react-router-dom";
import {toJS} from "mobx";
import {addDevidersToString} from "./helpers/helper-functions";

const TraderViewer = ({store}) => {


    const navigate = useNavigate()

    const [loading, setLoading] = useState(false)
    const [processing, setProcessing] = useState({status: false, index: null})

    const [users, setUsers] = useState([])

    const [activeModal, setActiveModal] = useState("no")
    const [modalMessage, setModalMessage] = useState("")

    const [activePayoffModal, setActivePayoffModal] = useState("no")
    const [activePayoffConfirmModal, setActivePayoffConfirmModal] = useState("no")
    const [activeInfoModal, setActiveInfoModal] = useState("no")

    const [trader, setTrader] = useState()
    const [transactionHash, setTransactionHash] = useState()



    // Состояние для отслеживания нажатой кнопки копирования
    const [copiedIndex, setCopiedIndex] = useState(null);

    const handleCopy = (traderId, index) => {
        navigator.clipboard.writeText(traderId);
        setCopiedIndex(index);
        // setTimeout(() => setCopiedIndex(null), 2000); // Возвращаем текст обратно через 2 секунды
    };

    const getUsers = async () => {
        setLoading(true)

        console.log(toJS(store.user.id))

        const response = await $api.get(`/get_users?requesterId=${store.user.id}`)
        console.log(response.data)


        // Считаем сумму всех приоритетов
        const totalPriority = response.data.reduce((sum, user) => sum + user.priority, 0);

// Добавляем каждому пользователю ключ percent
        const middleUserState = response.data.map(user => {
            return {
                ...user,
                percent: ((user.priority / totalPriority) * 100).toFixed(2) // Округляем до 2 знаков после запятой
            };
        });




        setUsers(middleUserState)
        setLoading(false)
    }

    const changeTraderStatus = async (userId, index) => {

        setProcessing({status: true, index})

        try {
            console.log(userId, index)
            const response = await $api.get(`/change_trader_status?userId=${userId}`)
            console.log(response.data)

            // Получаем обновленный объект карты из ответа
            const updatedUser = response.data;

            // Обновляем состояние карт
            setUsers(prevUsers =>
                prevUsers.map(user =>
                    user.id === updatedUser.id
                        ? { ...user, active: updatedUser.active } // Копируем все поля и меняем только поле active
                        : user
                )
            );

        } catch (e) {
            console.log(e)
        }




        setProcessing({status: false, index})

    }




    const makePayOff = async (transactionHash, traderId) => {

        try {



            const response = await $api.post('/make_payoff', {
                type: "User",
                transactionHash,
                agentId: traderId

            })

            if(response.data.result === "OK") {

                const freshTraderData = response.data.traderData


                console.log(freshTraderData)

                setUsers(prev => {
                    const newUsersState = prev.map(user => {

                        if(user.id === freshTraderData._id) {

                            user.profitBalance = freshTraderData.profitBalance
                            return user

                        } else {
                            return user
                        }
                    })

                    return newUsersState


                })

                setModalMessage(`Выплата для ${trader.login} на сумму ${response.data.amount} USDT зафиксирована`)
                setActiveInfoModal("progress")
                setTimeout(() => {
                    setActiveInfoModal("active")
                }, 100)

            } else {
                setModalMessage(response.data.message)
                setActiveInfoModal("progress")
                setTimeout(() => {
                    setActiveInfoModal("active")
                }, 100)
            }

            console.log(response.data)

        } catch (e) {
            console.log(e)

            setModalMessage(e.message)
            setActiveInfoModal("progress")
            setTimeout(() => {
                setActiveInfoModal("active")
            }, 100)

        }



    }




    useEffect(() => {
        store.setPageTitle("Трейдеры")
        getUsers()
    }, []);

    return (
        <div className={"user-viewer-wrapper"}>
            <table className="transaction-table">
                <thead>
                <tr>
                    <th>NickName</th>
                    <th>Баланс [₽]</th>
                    {/*<th>Страховой деп. [₽]</th>*/}
                    <th>Профит-баланс [USDT]</th>
                    <th>Сделки</th>
                    {/*<th>ID</th>*/}
                    <th>{`Ставка (%)`}</th>
                    {/*<th>{`Rus от 1000 (%)`}</th>*/}
                    {/*<th>{`Trans mini (%)`}</th>*/}
                    {/*<th>{`Trans от 1000 (%)`}</th>*/}
                    {/*<th>{`NSPK mini (%)`}</th>*/}
                    {/*<th>{`NSPK от 1000 (%)`}</th>*/}

                    <th>Приоритет</th>
                    <th>Получит заявок</th>
                    <th className={"fixed-table-width"}>Активность</th>
                    <th className={"fixed-table-width"}>Изменение</th>
                </tr>
                </thead>
                <tbody>
                {users
                    .slice() // Создаём копию массива, чтобы не мутировать оригинал
                    .sort((a, b) => a.login.localeCompare(b.login)) // Сортируем по user.login
                    .map((user, index) => {
                    // const formattedDate = new Date(card.block_timestamp)
                    //     .toLocaleString('ru-RU', options);

                    return (
                        <tr key={user.id} className={index % 2 === 0 ? 'even-row' : 'odd-row'}>
                            <td>
                                <h4 style={{margin: "3px"}}>{user.login}</h4>
                                <button
                                    className="copy-button"
                                    onClick={() => handleCopy(user.id, index)}
                                >

                                    {copiedIndex === index ? 'Copied' : 'Copy ID'}
                                </button>
                                <h5 className={"main-admin-data-h"}>Деп: {user.insurance?.toFixed(2) || 0} р.</h5>
                                <h5 className={"main-admin-data-h"}>Расчёты: {user.usdtSource === "garantex" ? "rapira" : user.usdtSource}</h5>
                                <h5 className={"main-admin-data-h"}>Крупные чеки: {user.grossCheckDeny ? "❌ " : "✔️"}</h5>
                            </td>
                            {/*<td>{user.balanceOnWallet?.usdt.toFixed(2) + " / " + user.balanceOnPlatform.toFixed(2)}</td>*/}
                            <td>{user.balance?.toFixed(2)}</td>
                            {/*<td>{user.insurance?.toFixed(2)}</td>*/}
                            <td>
                                <div className={"flex-table-cell-column"}>
                                    <h4 className={"main-admin-data-h"}>{addDevidersToString(user.profitBalance?.toFixed(2))}</h4>
                                    <button
                                        onClick={() => {
                                            setActivePayoffModal("progress")
                                            setTimeout(() => {
                                                setActivePayoffModal("active")
                                            }, 100)
                                            setModalMessage(`Выплата для ${user.login}`)
                                            setTrader(user)
                                        }}
                                        className={"gradient-orange-button"}
                                    >Выплата
                                    </button>
                                </div>

                            </td>
                            <td>{user.orderCounter + " / " + user.allOrdersCount}</td>
                            {/*<td>{user.id}</td>*/}
                            <td className={"fixed-table-width"}>
                                <h5 className={"main-admin-data-h"}>🡩 RUS | {user.stake}</h5>
                                <h5 className={"main-admin-data-h"}>🡫 RUS | {user.stakeMini}</h5>
                                <h5 className={"main-admin-data-h"}>🡩 TRANS | {user.stakeTransgran}</h5>
                                <h5 className={"main-admin-data-h"}>🡫 TRANS | {user.stakeTransgranMini}</h5>
                                <h5 className={"main-admin-data-h"}>🡩 NSPK | {user.stakeNspk}</h5>
                                <h5 className={"main-admin-data-h"}>🡫 NSPK | {user.stakeNspkMini}</h5>
                            </td>


                            <td>{user.priority}</td>
                            <td>{user.percent} %</td>
                            <td>
                                {user.active
                                    ?
                                    <button
                                        className={"gradient-red-button"}
                                        onClick={() => {

                                            changeTraderStatus(user.id, index)

                                        }}
                                    >Отключить
                                        {
                                            processing.status && processing.index === index
                                                ?
                                                <img src={require('../img/loading.gif')} height={"15px"}
                                                     className={"margin-left-20"}/>
                                                :
                                                null
                                        }
                                    </button>
                                    :
                                    <button
                                        className={"gradient-green-button card-status-switch-btn"}
                                        onClick={() => {

                                            changeTraderStatus(user.id, index)
                                        }}
                                    >Включить
                                        {processing.status && processing.index === index ?
                                            <img src={require('../img/loading.gif')} height={"15px"}
                                                 className={"margin-left-20"}/> : null}
                                    </button>
                                }
                            </td>

                            <td>
                                <button
                                    className={"gradient-orange-button"}
                                    onClick={() => {
                                        store.setUserToEditId(user.id)
                                        navigate('/create_user')

                                    }}
                                >Редактировать</button>
                            </td>
                        </tr>
                    );
                })}
                </tbody>
            </table>
            {loading
                ?
                <img src={require('../img/loading.gif')} height={"35px"} className={"margin-left-20"}/>
                :
                null
            }
            <ModalDialog
                active={activeModal}
                setActive={setActiveModal}
                // action={() => {
                //     setActiveModal("progress")
                //     setTimeout(() => {
                //         setActiveModal("no")
                //     }, 100)
                // }}
                message={modalMessage}
                purpose={"info"}
            />
            <ModalDialog
                active={activePayoffModal}
                setActive={setActivePayoffModal}
                purpose={"input"}
                inputPlaceHolder={"Хэш транзакции"}
                inputValue={transactionHash}
                setInputValue={setTransactionHash}

                message={modalMessage}
                action={() => {
                    setActivePayoffConfirmModal("progress")
                    setTimeout(() => {
                        setActivePayoffConfirmModal("active")
                    }, 100)
                    setModalMessage(`Подтверждаете выплату для ${trader.login}?`)
                }}

            />
            <ModalDialog
                active={activePayoffConfirmModal}
                setActive={setActivePayoffConfirmModal}
                purpose={"choice"}
                message={modalMessage}
                action={() => {
                    makePayOff(transactionHash, trader.id)
                }}
            />

            <ModalDialog
                purpose={"info"}
                message={modalMessage}
                active={activeInfoModal}
                setActive={setActiveInfoModal}
                buttonText2={"OK"}
            />
        </div>
    );
};

export default TraderViewer;