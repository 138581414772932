import React, {useEffect, useState} from 'react';
import "../css/personal-data.css";
import {QRCodeSVG} from 'qrcode.react';
import $api from "../http"
import {Link} from "react-router-dom";
import BatmanApkSvg from "../img/batman-seeklogo.svg"
import ModalDialog from "./ModalDialog";


const PersonalData = ({store}) => {


    const [loading, setLoading] = useState(false)

    const [activeModal, setActiveModal] = useState("no")
    const [activeInfoModal, setActiveInfoModal] = useState("no")
    const [modalMessage, setModalMessage] = useState("")


    const generateBasicToken = async () => {

        try {
            const response = await $api.get('/generate_basic_token')


            if(response.data?.status === "OK") {
                setModalMessage(`Ваш новый Basic-secret, скопируйте, больше мы его не покажем: ${response.data.basicSecret}`)
            } else {
                setModalMessage(response.data.message)
            }

            setActiveInfoModal("progress")
            setTimeout(() => {
                setActiveInfoModal("active")
            }, 100)

        } catch (e) {
            setModalMessage(e.message)

            setActiveInfoModal("progress")
            setTimeout(() => {
                setActiveInfoModal("active")
            }, 100)
        }







    }

    async function testLaunch() {
        const response = await $api.get('/test_launch')
        console.log(response.data)
    }

    async function resetAllValues() {
        const response = await $api.get('/total_reset')
        console.log(response.data)
    }

    const [securedData, setSecuredData] = useState("")
    const [mainWallet, setMainWallet] = useState("")

    const getSecuredData = async () => {
        const response = await $api.get(`/get_secured_data?userId=${store.user.id}`)
        console.log(response.data)
        setSecuredData(response.data.encrypted)
        setMainWallet(response.data.walletAddress)
    }

    const [progress, setProgress] = useState(0);

    async function downloadHandler(path) {

        console.log(path)


        setLoading(true)
        setProgress(0); // Сбрасываем прогресс

        // const response = await $api.get(`/download_file?path=${path}`, {responseType: 'arraybuffer'})


        const response = await $api.get(`/download_file?path=${encodeURIComponent(path)}`, {
            responseType: 'arraybuffer',
            onDownloadProgress: (progressEvent) => {
                const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                setProgress(percentCompleted);
            },
        });

        setLoading(false)
        console.log(response.data)

        //создаем блоб (Binary Large OBject) из ответа
        const blob = new Blob([response.data], { type: 'application/octet-stream' });
        //const blob = await response.blob()
        //из него генерируем ссылку на файл
        const downloadURL = URL.createObjectURL(blob)
        //создаем элемент ссылки
        const link = await document.createElement('a')
        //назначаем элементу собственно ссылку
        link.href = downloadURL

        //это тоже всё еще назначение ссылки (тут добавляется имя файла)
        const filenameWindows = path.substring(path.lastIndexOf("\\") + 1).replace(/_/g, " ");
        let filename;

        if (filenameWindows.includes('/')) {
            filename = filenameWindows.split('/').pop().replace(/_/g, " ");
        } else {
            filename = filenameWindows.replace(/_/g, " ");

        }

        link.download = filename

        //добавляем то что создали в ДОМ
        document.body.appendChild(link)
        //кликаем
        link.click()
        //и избавляемся от элемента
        link.remove()
        URL.revokeObjectURL(downloadURL)
    }



    useEffect(() => {
        store.setPageTitle("Мои данные")
        getSecuredData()
    }, []);


    return (
        <div className={"personal-data-wrapper"}>

            <div className={"personal-data-address-container"}>
                <h4 className={"personal-data-title"}>Адрес для пополнения баланса USDT (TRC-20):</h4>
                {mainWallet}
                <div className={"qr-code-wallet-wrapper"}>
                    <QRCodeSVG value={mainWallet}/>
                </div>
                <p>После перевода копируем хэш транзакции и вставляем его на странице <Link
                    to={'/top_ups'}>пополнений</Link></p>
            </div>
            {/*<div className={"personal-data-forwarder-download"}>*/}
            {/*    <div className={"personal-data-apk-logo-n-text-container"}>*/}
            {/*        <img src={BatmanApkSvg} width={"100px"}/>*/}
            {/*        <span><p>Форвардер push/sms</p></span>*/}
            {/*    </div>*/}
            {/*    <button*/}
            {/*        className={"gradient-orange-button"}*/}
            {/*        onClick={() => downloadHandler("public/download/gotham-trade.apk")}*/}
            {/*    >Скачать APK {loading*/}
            {/*        ? <img src={require('../img/loading.gif')} height={"15px"}*/}
            {/*                      className={"margin-left-20"}/>*/}
            {/*        :*/}
            {/*        null*/}
            {/*    }*/}
            {/*    </button>*/}
            {/*</div>*/}


            <div className="personal-data-forwarder-download">
                <div className="personal-data-apk-logo-n-text-container">
                    <img src={BatmanApkSvg} width="100px" alt="APK Logo"/>
                    <span>
                    <p>Форвардер push/sms</p>
                </span>
                </div>
                <button
                    className="gradient-orange-button"
                    onClick={() => downloadHandler("public/download/gotham-trade.apk")}
                    disabled={loading} // Блокируем кнопку во время загрузки
                >
                    Скачать APK
                    {loading ? (
                        <img
                            src={require('../img/loading.gif')}
                            height="15px"
                            className="margin-left-20"
                            alt="Loading"
                        />
                    ) : null}
                </button>
                {loading && (
                    <div className="progress-bar-container">
                        <div
                            className="progress-bar"
                            style={{width: `${progress}%`}}
                        >
                            {progress}%
                        </div>
                    </div>
                )}
            </div>
            <div className={"personal-data-address-container"}>
                {store.user.basicSecret
                    ?<h6 className={"h-reset"}>*****</h6>
                    : null
                }
                <button
                    className={"gradient-green-button"}
                    onClick={() => {
                        setActiveModal("progress")
                        setTimeout(() => {
                            setActiveModal("active")
                        }, 100)
                        if(store.user.basicSecret){
                            setModalMessage(`У вас уже есть токен для Basic авторизации, хотите его обновить? (после нажатия на кнопку "ДА", старый  токен сразу станет недействителен)`)
                        } else {
                            setModalMessage("Генерируем токен для Basic авторизации?")
                        }


                    }}
                >Basic secret</button>
            </div>


            {/*<button*/}
            {/*    onClick={() => testLaunch()}*/}
            {/*>Тест*/}
            {/*</button>*/}
            {/*<button*/}
            {/*    onClick={() => resetAllValues()}*/}
            {/*>Обнуление*/}
            {/*</button>*/}

            {/*<div className={"personal-data-qr-container"}>*/}
            {/*    <h4 className={"personal-data-title"}>Код для настройки мобильного приложения:</h4>*/}
            {/*    <div className={"qr-code-wallet-wrapper"}>*/}
            {/*        <QRCodeSVG value={securedData}/>*/}
            {/*    </div>*/}
            {/*</div>*/}
            <ModalDialog
                active={activeModal}
                setActive={setActiveModal}
                message={modalMessage}
                action={() => generateBasicToken()}
                purpose={'choice'}
            />
            <ModalDialog
                active={activeInfoModal}
                setActive={setActiveInfoModal}
                message={modalMessage}
                purpose={'info'}
                buttonText2={"OK"}
            />

        </div>
    );
};

export default PersonalData;