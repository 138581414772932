import React, {useEffect, useState, useRef} from 'react';
import $api from "../http";
import "../css/card-creator.css"
import ModalDialog from "./ModalDialog";
import {useNavigate} from "react-router-dom";
import OKSvg from '../img/ok-svgrepo-com.svg'
import BatmanLogoSvg from "../img/batman-logo-thin.svg";
import {addDevidersToString} from "./helpers/helper-functions";

const CardViewer = ({store, traders, updatedDevice, freshCard, completedOrder, updatedCards}) => {

    const navigate = useNavigate()

    const [activeModalInfo, setActiveModalInfo] = useState("no")
    const [activeModalConfirm, setActiveModalConfirm] = useState("no")
    const [activeModalQr, setActiveModalQr] = useState("no")
    const [qrData, setQrData] = useState("")
    const [qrName, setQrName] = useState("")

    const [activeModal, setActiveModal] = useState("no")
    const [modalMessage, setModalMessage] = useState("")

    const [processing, setProcessing] = useState({status: false, index: null})

    const [cardToDelete, setCardToDelete] = useState()
    const [cardToUnbind, setCardToUnbind] = useState()


    const [filter, setFilter] = useState({onlyActive: true})


    const startCardTest = async (cardId) => {
        console.log(cardId)

        const response = await $api.get(`/card_test?cardId=${cardId}`)

        console.log(response.data)

        // Получаем обновленный объект карты из ответа
        const updatedCard = response.data;

        // Обновляем состояние карт
        setCards(prevCards =>
            prevCards.map(card =>
                card._id === updatedCard._id ? updatedCard : card
            )
        );

    }


    const getCards = async (filter) => {


        console.log(filter)

        if(!filter?.onlyActive && !filter?.traderId && store.user.roles.includes('support')) {
            console.log('SETTING EMPTY ARRAY')
            setCards([])
            return
        }


        filter = filter ? encodeURIComponent(JSON.stringify(filter)) : ""

        const response = await $api.get(`/get_cards?filter=${filter}`)
        console.log(response.data)
        setCards(response.data)
    }

    const deleteCard = async (cardId) => {


        try {
            const response = await $api.delete(`/delete_card?cardId=${cardId}`)
            console.log(response.data)
            if(response.data === "OK") {

                setModalMessage(`Карта удалена!`)
                setActiveModalInfo("progress")
                setTimeout(() => {
                    setActiveModal("active")
                }, 100)

                setCards(prev => {
                    const newCardsState = prev.filter(card => card._id !== cardId)
                    return newCardsState
                })
            } else {
                setModalMessage(`Это не ваша карта, удалить ее нельзя!`)
                setActiveModalInfo("progress")
                setTimeout(() => {
                    setActiveModal("active")
                }, 100)
            }
        } catch (e) {
            setModalMessage(e.message)
            setActiveModalInfo("progress")
            setTimeout(() => {
                setActiveModal("active")
            }, 100)
        }



    }




    const unbindCard = async (cardId) => {


        try {
            const response = await $api.delete(`/unbind_card?cardId=${cardId}`)
            console.log(response.data)
            if(response.data._id) {

                setModalMessage(`Карта отвязана от устройства!`)
                setActiveModalInfo("progress")
                setTimeout(() => {
                    setActiveModal("active")
                }, 100)

                setCards(prev => {
                    const newCardsState = prev.map(card => card._id !== cardId ? card : response.data)
                    return newCardsState
                })
            } else {
                setModalMessage(`Это не ваша карта, отвязать ее нельзя!`)
                setActiveModalInfo("progress")
                setTimeout(() => {
                    setActiveModal("active")
                }, 100)
            }
        } catch (e) {
            setModalMessage(e.message)
            setActiveModalInfo("progress")
            setTimeout(() => {
                setActiveModal("active")
            }, 100)
        }



    }


    const activateCardWithoutTest = async (cardId, index) => {




        try {
            setProcessing({status: true, index})



            const response = await $api.get(`/activate_card_without_test?cardId=${cardId}`)
            console.log(response.data)


            // Получаем обновленный объект карты из ответа
            const updatedCard = response.data;

            // Обновляем состояние карт
            setCards(prevCards =>
                prevCards.map(card =>
                    card._id === updatedCard._id ? updatedCard : card
                )
            );
            setProcessing({status: false, index})

        } catch (e) {
            setModalMessage("Ошибка смены статуса карты\n" + e.message + "\n зовите админа")
            setActiveModal("progress")
            setTimeout(() => {
                setActiveModal("active")
            }, 100)
            setProcessing({status: false, index})
        }


    }



    const changeCardStatus = async (cardId, index) => {




        try {
            setProcessing({status: true, index})



            const response = await $api.get(`/change_card_status?cardId=${cardId}`)
            console.log(response.data)


            // Получаем обновленный объект карты из ответа
            const updatedCard = response.data;

            // Обновляем состояние карт
            setCards(prevCards =>
                prevCards.map(card =>
                    card._id === updatedCard._id ? updatedCard : card
                )
            );
            setProcessing({status: false, index})

        } catch (e) {
            setModalMessage("Ошибка смены статуса карты\n" + e.message + "\n зовите админа")
            setActiveModal("progress")
            setTimeout(() => {
                setActiveModal("active")
            }, 100)
            setProcessing({status: false, index})
        }


    }



    const [cards, setCards] = useState([])


    useEffect(() => {
        store.setPageTitle("Все карты")

        if(store.user.roles.includes("support") && Object.keys(filter).length) {
            getCards(filter)
        } else {
            getCards()
        }

    }, [filter]);


    useEffect(() => {


        if(freshCard && Object.keys(freshCard).length) {

            // Обновляем состояние карт
            setCards(prevCards =>
                prevCards.map(card =>
                    card._id === freshCard._id ? freshCard : card
                )
            );
        }


    }, [freshCard])


    useEffect(() => {

        if(completedOrder && Object.keys(completedOrder).length && completedOrder.status === "completed") {
            const { card, amount } = completedOrder;
            const cardId = card._id;

            // Используем setCards, чтобы обновить состояние
            setCards(prevCards => {
                return prevCards.map(c => {
                    // Если ID карты совпадает, обновляем reminderOfLimit
                    if (c._id === cardId) {
                        return {
                            ...c,
                            reminderOfLimit: c.reminderOfLimit - amount, // Отнимаем amount
                        };
                    }
                    return c; // Оставляем карту без изменений
                });
            });
        }

    }, [completedOrder])


    useEffect(() => {

        console.log(updatedDevice)
        if(store.user.roles.includes("support") && updatedDevice && Object.keys(updatedDevice).length) {

            console.log("THIIS IS SUPPORT EFFECT")

            if((updatedDevice?.user === filter.traderId || updatedDevice?.user?._id === filter.traderId || updatedDevice?.isCard) && !filter.onlyActive) {


                console.log("SUPPORT TRY TO SETTING CARDS")

                setCards(prev => {
                    const newCardsState = prev?.map(card => {
                        // console.log(card)
                        if(card.device?._id === updatedDevice?._id) {
                            card.device.online = updatedDevice.online
                            // card.device.workStatus = updatedDevice.workStatus
                        } else if (card._id === updatedDevice?.card?._id) {

                            card.device = updatedDevice

                        } else if(card._id === updatedDevice?._id) {

                            console.log("RETURNING UPDATED DEVICE")
                            return updatedDevice
                        }

                        return card

                    })

                    return newCardsState
                })

            } else if(filter.onlyActive) {

                const userFilter = filter.traderId
                    ?
                    updatedDevice.isCard
                        ?
                        updatedDevice.user === filter.traderId
                        :
                        updatedDevice.card?.user === filter.traderId
                    :
                    true

                const cardActivity = updatedDevice.isCard
                    ?
                    updatedDevice.active
                    && !updatedDevice.stopByFails
                    && !updatedDevice.stopByAdmin
                    && (updatedDevice.isOnline || (updatedDevice.webHook && updatedDevice.webHookTestPassed))
                    :
                    updatedDevice.card?.active
                    && (updatedDevice.online || (updatedDevice.card.webHook && updatedDevice.card.webHookTestPassed))
                    && !updatedDevice.card.stopByFails
                    && !updatedDevice.card.stopByAdmin
                    && userFilter


                // console.log(updatedDevice.card.user)
                // console.log(filter.traderId)
                //
                // console.log(updatedDevice.card.active)
                // console.log((updatedDevice.online || (updatedDevice.card.webHook && updatedDevice.card.webHookTestPassed)))
                // console.log(!updatedDevice.card.stopByFails)
                // console.log(!updatedDevice.card.stopByAdmin)
                // console.log(userFilter)

                console.log("CARD ACTIVITY IS", cardActivity)

                if(cards.some(card => card._id === updatedDevice.card?._id || card._id === updatedDevice._id) && !cardActivity) {

                    if(updatedDevice.card?._id) {
                        setCards(prev => prev.filter(card => card._id !== updatedDevice.card._id))
                    } else {
                        setCards(prev => prev.filter(card => card._id !== updatedDevice._id))
                    }



                    // setCards(prev => {
                    //     const newCardsState = prev?.map(card => {
                    //
                    //         if(card.device?._id === updatedDevice?._id) {
                    //             card.device.online = updatedDevice.online
                    //
                    //         } else if (card._id === updatedDevice?.card?._id) {
                    //
                    //             card.device = updatedDevice
                    //
                    //         } else if(card._id === updatedDevice?._id) {
                    //
                    //             console.log("RETURNING UPDATED DEVICE")
                    //             return updatedDevice
                    //         }
                    //
                    //         return card
                    //
                    //     })
                    //
                    //     return newCardsState
                    // })

                } else  {

                    if(cardActivity) {

                        if(updatedDevice.isCard) {

                            const freshCardObject = {...updatedDevice}

                            // freshCardObject.isOnline = updatedDevice.online
                            // freshCardObject.device = {_id: freshCardObject.device, online: true}
                            // freshCardObject.user = updatedDevice.user

                            console.log(freshCardObject)

                            setCards(prev => [freshCardObject, ...prev])

                        } else {

                            const freshCardObject = {...updatedDevice.card}

                            freshCardObject.isOnline = updatedDevice.online
                            freshCardObject.device = {_id: freshCardObject.device, online: true}
                            freshCardObject.user = updatedDevice.user

                            console.log(freshCardObject)

                            setCards(prev => [freshCardObject, ...prev])

                        }

                    }

                }

            } else {
                console.log("ТУТ ЧЕГО ТО НЕТ")
            }

        }

        if(store.user.roles.includes("trader")) {

            setCards(prev => {

                const newCardsState = prev?.map(card => {
                    // console.log(card)
                    //этот блок
                    if(card.device?._id === updatedDevice?._id || card.device?._id === updatedDevice?.card?._id) {

                        if(card.device) {
                            card.device.online = updatedDevice.online
                            card.isOnline = updatedDevice.online
                        }

                        //этот блок работает когда нужно обновить стейт вновь привязываемого устройства к карте
                    } else if (card._id === updatedDevice?.card?._id) {

                        card.device = updatedDevice

                    } else if(card._id === updatedDevice?._id) {
                        return updatedDevice
                    }

                            return card

                        })

                        return newCardsState
                    })
                }



            }, [updatedDevice, filter, store.user])


            useEffect(() => {

                if(updatedCards?.length) {
                    setCards(prevCards => {
                        // Обновляем существующие объекты
                        const updatedExisting = prevCards.map(card => {
                            const found = updatedCards.find(newCard => newCard._id === card._id);
                            return found ? found : card;
                        });
                        // Находим новые объекты, которых нет в предыдущем состоянии
                        const newCards = updatedCards.filter(newCard =>
                            !prevCards.some(card => card._id === newCard._id)
                        );
                        // Объединяем обновлённые существующие и новые объекты
                        return [...updatedExisting, ...newCards];
                    });
                }



            }, [updatedCards]);



            return (
                <div className={"card-viewer-wrapper"}>

                    {store.user.roles.includes("support")
                        ?
                        <div className={"order-viewer-merchant-sort-wrapper"}>

                            {/*<select*/}

                            {/*    value={filter?.status}*/}
                            {/*    onChange={(e) => {*/}

                            {/*        setAppeals([])*/}
                            {/*        setProcessing(true)*/}
                            {/*        setSkipCounter(0);*/}

                            {/*        setFilter(prev => ({*/}
                            {/*            ...prev,*/}
                            {/*            status: e.target.value*/}
                            {/*        }))*/}


                            {/*        setTimeout(() => {*/}
                            {/*            setStopFetching(false)*/}
                            {/*            setFetching(true)*/}
                            {/*        }, 1000)*/}
                            {/*    }}*/}
                            {/*>*/}
                            {/*    <option value={"pending"}>Открытые</option>*/}
                            {/*    <option value={"closed"}>Закрытые</option>*/}

                            {/*</select>*/}

                            <select
                                value={filter.traderId ?? ""}
                                onChange={(e) => {
                                    const selectedTrader = traders.find(trader => trader.id === e.target.value);

                                    setCards([])

                                    setFilter(prev => {

                                        const newState = {
                                            ...prev,
                                            traderId: e.target.value,
                                            traderLogin: selectedTrader?.login || "",
                                        }

                                        if(e.target.value === "") {
                                            newState.onlyActive = true
                                            delete prev.trafficType
                                        }

                                        return newState

                                    })


                                    setTimeout(() => {
                                        // setStopFetching(false);
                                        // setFetching(true);
                                    }, 1000);
                                }}
                            >
                                <option value="">
                                    Все активные
                                </option>
                                {Object.values(traders)
                                    .sort((a, b) => a.login.localeCompare(b.login))
                                    .map(trader => (
                                        <option key={trader.id} value={trader.id}>
                                            {trader.login}
                                        </option>
                                    ))}
                                {/*{traders.map(trader => (*/}
                                {/*    <option key={trader.id} value={trader.id}>*/}
                                {/*        {trader.login}*/}
                                {/*    </option>*/}
                                {/*))}*/}
                            </select>

                            <select
                                onChange={(e) => {
                                    setCards([])
                                    setFilter(prev => {

                                        const newState = {
                                            ...prev,
                                            trafficType: e.target.value,

                                        }
                                        return newState
                                    })
                                }}
                            >
                                <option value={""}>Все типы трафика</option>
                                <option value={"card_number"}>По номеру карты (РФ)</option>
                                <option value={"sbp"}>СБП (РФ)</option>
                                <option value={"card_number_transgran"}>По номеру карты (ТГ)</option>
                                <option value={"sbp_transgran"}>СБП (ТГ)</option>
                                <option value={"nspk"}>НСПК</option>
                            </select>

                            {filter.traderId
                                ?
                                <div>
                                    <input
                                        checked={filter.onlyActive}
                                        type={"checkbox"}
                                        id={"only-online-cards"}
                                        onClick={() => {
                                            setFilter(prev => ({
                                                ...prev,
                                                onlyActive: !prev.onlyActive

                                            }));
                                        }}
                                    />
                                    <label htmlFor={"only-online-cards"}>
                                        Только в работе
                                    </label>
                                </div>
                                :
                                <h3>{`Всего ${cards.length}`}</h3>
                            }


                            {/*<button*/}
                            {/*    className={"gradient-dark-button"}*/}
                            {/*    onClick={() => {*/}

                            {/*        setAppeals([])*/}
                            {/*        setProcessing(true)*/}
                            {/*        setSkipCounter(0);*/}

                            {/*        setFilter({status: "pending", onlyMine: store.user.id, startDate: "", stopDate: ""})*/}


                            {/*        setTimeout(() => {*/}
                            {/*            setStopFetching(false)*/}
                            {/*            setFetching(true)*/}
                            {/*        }, 1000)*/}
                            {/*    }}*/}
                            {/*>Сброс*/}
                            {/*</button>*/}


                        </div>
                        :
                        null
                    }


                    <table className="transaction-table">
                        <thead>
                        <tr>
                            {!filter.trader && store.user.roles.includes("support")
                                ?
                                <th>Трейдер</th>
                                :
                                null
                            }

                            <th>ФИО</th>
                            {store.user.roles.includes("trader")
                                ?
                                <th>Токен</th>
                                :
                                null
                            }

                            <th>Банк</th>
                            <th>Траффик</th>
                            <th>Номер</th>
                            {/*{store.user.roles.includes("support")*/}
                            {/*    ?*/}
                            <th>Устройство</th>
                            {/*    :*/}
                            {/*    null*/}
                            {/*}*/}
                            <th className={"fixed-table-width"}>Активность</th>
                            {/*{store.user.roles.includes("trader")*/}
                            {/*    ?*/}
                                <th className={"fixed-table-width"}>Статистика</th>
                            {/*    :*/}
                            {/*    null*/}
                            {/*}*/}

                            <th className={"fixed-table-width"}>Управление</th>



                        </tr>
                        </thead>
                        <tbody>
                        {cards.map((card, index) => {
                            // const formattedDate = new Date(card.block_timestamp)
                            //     .toLocaleString('ru-RU', options);

                            // console.log(card)
                            const percent = parseFloat(((card.reminderOfLimit / card.dayLimit) * 100).toFixed(1));





                            const getColor = (percent) => {
                                if (percent < 20) return '#F28B82'; // Нежный красный
                                if (percent < 50) return '#FDD663'; // Нежный жёлтый
                                return '#81C995'; // Нежный зелёный
                            };

                            return (
                                <tr key={card._id} className={index % 2 === 0 ? 'even-row' : 'odd-row'}>
                                    {!filter.trader && store.user.roles.includes("support")
                                        ?
                                        <td>{card.user?.login}</td>
                                        :
                                        null
                                    }
                                    <td>{card.cardholder}</td>
                                    {store.user.roles.includes("trader")
                                        ?
                                        <td>
                                            {card.token
                                                ?
                                                card.group
                                                    ?
                                                    "Карта прикреплена к группе"
                                                    :
                                                    <button
                                                        className="gradient-orange-button qr-token-btn"
                                                        onClick={() => {
                                                            setQrData(card.token?.token)
                                                            setQrName(card.cardholder + "\n" + card.token?.token)
                                                            setActiveModalQr("progress")
                                                            setTimeout(() => {
                                                                setActiveModalQr("active")
                                                            }, 100)
                                                        }}
                                                    >
                                                        Токен
                                                    </button>
                                                :
                                                null
                                            }
                                            {/*<span>{card.token?.token}</span>*/}

                                        </td>
                                        :
                                        null
                                    }
                                    <td>{card.bank}</td>
                                    <td>{card.trafficType}</td>
                                    <td title={card.number}>{"*" + card.number.slice(-4)}</td>
                                    {/*{store.user.roles.includes("support")*/}
                                    {/*    ?*/}
                                        <td>
                                            {card.device?.online || card.isOnline
                                                ?
                                                <div className={"green-bold"}>OnLine</div>
                                                :
                                                // !card.device?.workStatus
                                                //     ?
                                                    card.device && !card.isOnline && !card.webHook
                                                        ?
                                                        <div className={"red-bold"}>OffLine</div>
                                                        :
                                                        card.webHook
                                                            ?
                                                            <div className={"green-bold"}>WebHook</div>
                                                            :
                                                            <div className={"red-bold"}>Не привязано</div>


                                            }
                                        </td>
                                    {/*    :*/}
                                    {/*    null*/}
                                    {/*}*/}
                                    <td>
                                        {store.user.roles.includes("trader")
                                            ?
                                            card.stopByFails
                                                ?
                                                card.test
                                                    ?
                                                    "⚠️ На тесте"
                                                    :
                                                    "⚠️ Остановлена системой"
                                                :
                                                card.stopByAdmin
                                                    ?
                                                    "⚠️ Остановлена админом"
                                                    :
                                                    card.active
                                                        ?
                                                        card.device?.online
                                                            ?
                                                            card.reminderOfLimit < card.min
                                                                ?
                                                                <span
                                                                    title={"Эта карта больше не получит заявок, так как ее минимальный разрешенный платеж превышает ее суточный остаток"}>
                                                                    ⚠️ - не работает</span>
                                                                :
                                                                <div className={"green-bold"}>В работе</div>
                                                            :
                                                            card.webHook
                                                                ?
                                                                <div className={"green-bold"}>В работе</div>
                                                                :
                                                                <div className={"red-bold"}>Ожидание соединения</div>
                                                        :
                                                        !card.activeGroup
                                                            ?
                                                            <div className={"red-bold"}>Остановлена группа</div>
                                                            :
                                                            <div className={"red-bold"}>Остановлена</div>
                                            :
                                            card.active && !card.stopByFails
                                                ?
                                                card.stopByAdmin
                                                    ?
                                                    "⚠️ Остановлена админом"
                                                    :
                                                    card.device?.online
                                                        ?
                                                        card.user?.active
                                                            ?
                                                            <div className={"green-bold"}>В работе</div>
                                                            :
                                                            <div className={"red-bold"}>Трейдер отключён</div>
                                                        :
                                                        card.webHook
                                                            ?
                                                            card.user?.active
                                                                ?
                                                                <div className={"green-bold"}>В работе</div>
                                                                :
                                                                <div className={"red-bold"}>Трейдер отключён</div>
                                                            :
                                                            <div className={"red-bold"}>Ожидание соединения</div>

                                                :
                                                card.stopByFails
                                                    ?
                                                    card.test
                                                        ?
                                                        <div>
                                                            ⚠️ На тесте
                                                            <button
                                                                onClick={() => activateCardWithoutTest(card._id, index)}
                                                                className={"gradient-green-button"}>Вкл.</button>
                                                        </div>
                                                        :
                                                        <div>
                                                            ⚠️ Остановлена системой
                                                            <button
                                                                onClick={() => activateCardWithoutTest(card._id, index)}
                                                                className={"gradient-green-button"}>Вкл.</button>
                                                        </div>

                                                    :
                                                    "⚠️ Остановлена трейдером"

                                        }

                                    </td>

                                    {/*{store.user.roles.includes("trader")*/}
                                    {/*    ?*/}
                                        <td>
                                            {card.stopByFails
                                                ?
                                                card.test
                                                    ?
                                                    card.test.numberAmount > 1
                                                        ?
                                                        `Ждем перевод ${card.test.numberAmount}р. по номеру карты`
                                                        :
                                                        card.test.sbpAmount
                                                            ?
                                                            <>
                                                                {card.test.numberAmount === 1 ?
                                                                    <span>По номеру ОК<img height={"15px"}
                                                                                           src={OKSvg}/><br/><br/></span> : ""}
                                                                Ждем перевод {card.test.sbpAmount}р. {
                                                                    card.trafficType === "nspk"
                                                                        ?
                                                                        "по QR-коду"
                                                                        :
                                                                        card.trafficType === "applink_vtb"
                                                                            ?
                                                                            ""
                                                                            :
                                                                            "по СБП"
                                                                }
                                                            </>
                                                            :
                                                            null
                                                    :
                                                    store.user.roles.includes('trader')
                                                        ?
                                                        card.device || card.webHook
                                                            ?
                                                            <button
                                                                className={"gradient-green-button"}
                                                                onClick={() => startCardTest(card._id)}
                                                            >Тестировать</button>
                                                            :
                                                            "⚠️ Сначала привяжите устройство"
                                                        :
                                                        card.test
                                                            ?
                                                            "⚠️ На тесте"
                                                            :
                                                            "⚠️ Ожидает тестирования"
                                                :
                                                <div>
                                                    <div className="limit-bar">
                                                        <div
                                                            className="limit-fill"
                                                            style={{
                                                                width: `${percent}%`,
                                                                backgroundColor: getColor(percent),
                                                            }}
                                                        ></div>
                                                        <div className="limit-text">
                                                            {addDevidersToString(card.reminderOfLimit?.toFixed(2))}
                                                        </div>
                                                    </div>
                                                    <div className={"card-info-mini"}>В день: {card.todaysOrdersCount}/{!card.maxDailyOrders || card.maxDailyOrders === 0 ? "∞" : card.maxDailyOrders}</div>
                                                    <div className={"card-info-mini"}>Интервал: {card.intervalBetweenTransactions ? card.intervalBetweenTransactions + " м." : "нет"}</div>
                                                    <div className={"card-info-mini"}>Одновременно: {card.limitOfOpened ? card.limitOfOpened : "∞"}</div>
                                                    <div className={"card-info-mini"}>Чеки от {card.min} до {card.max} р.</div>

                                                </div>

                                                // `${card.reminderOfLimit}/${card.dayLimit}`
                                                // <img height={"30px"} src={OKSvg}/>
                                            }

                                        </td>
                                    {/*//     :*/}
                                    {/*//     null*/}
                                    {/*// }*/}


                                    <td>
                                        <div className={"flex-table-cell-column"}>
                                            <div className={"flex-table-cell"}>
                                                {/*{card.isEditable || store.user.roles.includes('support')*/}
                                                {/*    ?*/}
                                                <button
                                                    className={"gradient-orange-button"}
                                                        onClick={() => {
                                                            store.setCardToEditId(card._id)
                                                            navigate('/create_card')

                                                        }}
                                                    >Изменить {
                                                        processing.status && processing.index === index
                                                            ?
                                                            <img src={require('../img/loading.gif')} height={"15px"}
                                                                 className={"margin-left-20"}/>
                                                            :
                                                            null
                                                    }
                                                    </button>
                                                {/*    :*/}
                                                {/*    null*/}
                                                {/*}*/}

                                                {store.user.roles.includes("support")
                                                    ?
                                                    !card.stopByAdmin
                                                        ?
                                                        <button
                                                            className={"gradient-red-button"}
                                                            onClick={() => {

                                                                changeCardStatus(card._id, index)

                                                            }}
                                                        >Отключить {
                                                            processing.status && processing.index === index
                                                                ?
                                                                <img src={require('../img/loading.gif')} height={"15px"}
                                                                     className={"margin-left-20"}/>
                                                                :
                                                                null
                                                        }
                                                        </button>
                                                        :
                                                        <button
                                                            className={"gradient-green-button card-status-switch-btn"}
                                                            onClick={() => {

                                                                changeCardStatus(card._id, index)
                                                            }}
                                                        >Включить {processing.status && processing.index === index ?
                                                            <img src={require('../img/loading.gif')} height={"15px"}
                                                                 className={"margin-left-20"}/> : null}</button>

                                                    :
                                                    card.active
                                                        ?
                                                        <button
                                                            className={"gradient-red-button"}
                                                            onClick={() => {

                                                                changeCardStatus(card._id, index)

                                                            }}
                                                        >Отключить{
                                                            processing.status && processing.index === index
                                                                ?
                                                                <img src={require('../img/loading.gif')} height={"15px"}
                                                                     className={"margin-left-20"}/>
                                                                :
                                                                null
                                                        }
                                                        </button>
                                                        :
                                                        card.activeGroup
                                                            ?
                                                            <button
                                                                className={"gradient-green-button card-status-switch-btn"}
                                                                onClick={() => {

                                                                    changeCardStatus(card._id, index)
                                                                }}
                                                            >Включить {processing.status && processing.index === index ?
                                                                <img src={require('../img/loading.gif')} height={"15px"}
                                                                     className={"margin-left-20"}/> : null}</button>
                                                            :
                                                            null
                                                }



                                            </div>
                                            <div className={"flex-table-cell"}>
                                                {card.device && store.user.roles.includes("trader")
                                                    ?
                                                    <button
                                                        className={"gradient-orange-button"}
                                                        onClick={() => {

                                                            if(card.group) {
                                                                setModalMessage(`Вы действительно хотите отвязать карту ${card.number}\n${card.cardholder} от устройства?\nКарта также будет исключена из группы`)
                                                            } else {
                                                                setModalMessage(`Вы действительно хотите отвязать карту ${card.number}\n${card.cardholder} от устройства?`)
                                                            }

                                                            setCardToDelete()
                                                            setCardToUnbind(card._id)
                                                            setActiveModalConfirm("progress")
                                                            setTimeout(() => {
                                                                setActiveModalConfirm("active")
                                                            }, 100)
                                                        }}
                                                    >Отвязать
                                                    </button>
                                                    :
                                                    null
                                                }
                                                {store.user.roles.includes("trader")
                                                    ?
                                                    <button
                                                        className={"gradient-dark-button"}
                                                        onClick={() => {

                                                            setModalMessage(`Вы действительно хотите удалить карту ${card.number}\n${card.cardholder}`)
                                                            setCardToUnbind()
                                                            setCardToDelete(card._id)
                                                            setActiveModalConfirm("progress")
                                                            setTimeout(() => {
                                                                setActiveModalConfirm("active")
                                                            }, 100)
                                                        }}
                                                    >Удалить
                                                    </button>
                                                    :
                                                    null
                                                }

                                            </div>
                                        </div>

                                    </td>


                                </tr>
                            );
                        })}
                        </tbody>
                    </table>


                    <ModalDialog
                        active={activeModal}
                        setActive={setActiveModal}
                        // action={() => {
                        //     setActiveModal("progress")
                        //     setTimeout(() => {
                        //         setActiveModal("no")
                        //     }, 100)
                        // }}
                        message={modalMessage}
                        purpose={"info"}
                        buttonText2={"OK"}
                    />
                    <ModalDialog
                        active={activeModalQr}
                        setActive={setActiveModalQr}
                        qrcode={qrData}
                        message={qrName}
                        purpose={"info"}
                    />

                    <ModalDialog
                        active={activeModalConfirm}
                        setActive={setActiveModalConfirm}
                        message={modalMessage}
                        purpose={"choice"}
                        action={
                            cardToDelete
                                ? () => deleteCard(cardToDelete)
                                : () => unbindCard(cardToUnbind)
                        }

                    />

                    <ModalDialog
                        active={activeModalInfo}
                        setActive={setActiveModalInfo}
                        message={modalMessage}
                        purpose={"info"}
                        buttonText2={"OK"}
                    />
                </div>
            );
        };

        export default CardViewer;