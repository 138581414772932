import React, {useEffect, useState} from 'react';
import "../css/card-creator.css"
import GroupCardsModal from "./helpers/GroupCardsModal";
import $api from "../http";
import CardGroupTable from "./helpers/CardGroupTable";
import ModalDialog from "./ModalDialog";

const CardGroups = ({store, freshGroup}) => {


    const [activeCreateModal, setActiveCreateModal] = useState("no")
    const [activeModal, setActiveModal] = useState("no")


    const [cards, setCards] = useState([])
    const [cardsFilterOptions, setCardsFilterOptions] = useState([])
    const [modalMessage, setModalMessage] = useState("")
    const [errorMessage, setErrorMessage] = useState({show: false})
    const [newGroupData, setNewGroupData] = useState({})
    const [cardOptions, setCardOptions] = useState([])
    const [cardGroups, setCardGroups] = useState([])

    const [buttonText, setButtonText] = useState("Создать")

    const [processing, setProcessing] = useState({now: false})


    useEffect(() => {

        if(freshGroup && Object.keys(freshGroup).length) {

            setCardGroups(prev => {
                const index = prev.findIndex(item => item._id === freshGroup._id);

                console.log("index is", index)

                if (index !== -1) {
                    // Если элемент с таким _id уже существует, заменяем его
                    console.log("Элемент существует")
                    return prev.map(item =>
                        item._id === freshGroup._id ? freshGroup : item
                    );
                } else {
                    // Если элемента нет, добавляем новый объект в начало массива
                    return [freshGroup, ...prev];
                }
            });

            getCards()
        }



    }, [freshGroup]);


    const getCards = async () => {

        const response = await $api.get(`/get_cards`)
        console.log(response.data)

        setCards(response.data)

        const buildingOfCardFilterOptions = response.data.map(card => {

                return {
                    value: card._id,
                    label: `[${card.cardholder}] - [${card.bank}] - [*${card.number?.slice(-4)}]`,
                    bank: card.bank
                }


        })
        setCardsFilterOptions(buildingOfCardFilterOptions)


    }

    const getCardGroups = async () => {

        const response = await $api.get(`/get_card_groups`)
        console.log(response.data)

        setCardGroups(response.data)


    }


    const createGroup = async (groupData) => {


        try {

            const response = await $api.post(`/create_card_group`, groupData)

            if(response.data.result !== "FAIL") {

                setCardGroups(prev => {
                    const index = prev.findIndex(item => item._id === response.data._id);

                    console.log("index is", index)

                    if (index !== -1) {
                        // Если элемент с таким _id уже существует, заменяем его
                        console.log("Элемент существует")
                        return prev.map(item =>
                            item._id === response.data._id ? response.data : item
                        );
                    } else {
                        // Если элемента нет, добавляем новый объект в начало массива
                        return [response.data, ...prev];
                    }
                });

                setModalMessage(`Группа успешно сохранена`)

                getCards()



            } else {

                setModalMessage(response.data.message)


            }


            setActiveModal("progress")
            setTimeout(() => {
                setActiveModal("active")

            }, 300)



        } catch (e) {

            setModalMessage(e.message)

            setActiveModal("progress")
            setTimeout(() => {
                setActiveModal("active")

            }, 300)
        }


    }



    const changeGroupStatus = async (groupId, active) => {

        try {

            const response = await $api.get(`/change_group_status?groupId=${groupId}&active=${active}`)

            if(response.data.result === "OK") {
                setCardGroups(prev => {
                    const newStateOfGroups = prev.map(group => {
                        if(group._id === groupId) {
                            group.active = !group.active
                        }

                        return group
                    })

                    return newStateOfGroups
                })
            }




        } catch (e) {

            setModalMessage(e.message)
            setActiveModal("progress")
            setTimeout(() => {
                setActiveModal("active")

            }, 300)

        } finally {
            setProcessing({now: false})
        }



    }


    const unbindGroup = async (groupId) => {


        try {

            const response = await $api.delete(`/unbind_group?groupId=${groupId}`)

            console.log(response.data)
            setCardGroups(prev => {
                const index = prev.findIndex(item => item._id === response.data._id);

                console.log("index is", index)

                if (index !== -1) {
                    // Если элемент с таким _id уже существует, заменяем его
                    console.log("Элемент существует")
                    return prev.map(item =>
                        item._id === response.data._id ? response.data : item
                    );
                } else {
                    // Если элемента нет, добавляем новый объект в начало массива
                    return [response.data, ...prev];
                }
            });


            if(response.data.result !== "FAIL") {
                setModalMessage("Группа отвязана от устройства")
            } else {
                setModalMessage(response.data.message)
            }



        } catch (e) {

            setModalMessage(e.message)


        } finally {
            setActiveModal("progress")
            setTimeout(() => {
                setActiveModal("active")

            }, 300)
        }





    }



    const deleteGroup = async (groupId) => {


        try {

            const response = await $api.delete(`/delete_group?groupId=${groupId}`)

            console.log(response.data)



            if(response.data.result !== "FAIL") {
                setModalMessage("Группа удалена")


                setCardGroups(prev => {
                    const index = prev.findIndex(item => item._id === groupId);

                    if (index !== -1) {
                        // Если элемент с таким _id уже существует, заменяем его

                        return prev.map(item =>
                            item._id === groupId ? null : item
                        ).filter(item => item !== null)
                    }
                });

            } else {
                setModalMessage(response.data.message)
            }

            getCards()



        } catch (e) {

            setModalMessage(e.message)


        } finally {
            setActiveModal("progress")
            setTimeout(() => {
                setActiveModal("active")

            }, 300)
        }





    }


    useEffect(() => {


        if(newGroupData.type === "bank") {
            const buildingOfCardOptions = cards.map(card => {
                if(card.allowedSplit && !card.group && !newGroupData.cards?.includes(card._id)) {
                    return {
                        value: card._id,
                        label: `[${card.cardholder}] - [${card.bank}] - [*${card.number?.slice(-4)}]`,
                        bank: card.bank
                    }
                } else {
                    return
                }

            }).filter(item => item !== undefined)
            setCardOptions(buildingOfCardOptions)

        } else if(newGroupData.type === "farm") {

            console.log(cards)

            // Создаем массив bankCode для карт, уже добавленных в newGroupData.cards:
            const excludedBankCodes = cards
                .filter(card => newGroupData.cards?.includes(card._id))
                .map(card => card.bankCode);


            const buildingOfCardOptions = cards.map(card => {

                if(!card.group && !newGroupData.cards?.includes(card._id) && !excludedBankCodes.includes(card.bankCode)) {

                    return {
                        value: card._id,
                        label: `[${card.cardholder}] - [${card.bank}] - [*${card.number?.slice(-4)}]`,
                        bank: card.bank
                    }
                } else {

                    console.log(card)
                    return
                }

            }).filter(item => item !== undefined)

            setCardOptions(buildingOfCardOptions)

        }




    }, [newGroupData.type, cards, newGroupData.cards]);



    useEffect(() => {

        store.setPageTitle("Группы реквизитов")
        getCards()
        getCardGroups()

    }, []);


    const activateCreationModal = () => {
        setActiveCreateModal("progress")
        setTimeout(() => {
            setActiveCreateModal("active")

        }, 300)
    }


    return (
        <div className={"card-group-wrapper"}>
            <button
                className={"gradient-orange-button group-create-btn"}
                onClick={() => {
                    setNewGroupData({})
                    setButtonText("Создать")
                    activateCreationModal()
                }}
            >Создать</button>

            <CardGroupTable
                groups={cardGroups}
                cards={cards}
                setNewGroupData={setNewGroupData}
                activateCreationModal={activateCreationModal}
                changeGroupStatus={changeGroupStatus}
                setButtonText={setButtonText}
                setProcessing={setProcessing}
                processing={processing}
                unbindGroup={unbindGroup}
                deleteGroup={deleteGroup}
            />

            <GroupCardsModal
                active={activeCreateModal}
                setActive={setActiveCreateModal}
                value={newGroupData}
                setValue={setNewGroupData}
                errorMessage={errorMessage}
                cardOptions={cardOptions}
                cardsFilterOptions={cardsFilterOptions}
                action={() => {
                    createGroup(newGroupData)
                }}
                buttonText={buttonText}
            />
            <ModalDialog
                active={activeModal}
                setActive={setActiveModal}
                message={modalMessage}
                purpose={"info"}
                buttonText2={"OK"}
            />

        </div>
    );
};

export default CardGroups;